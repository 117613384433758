const F = {

    recursivePath: (object, path, fallback) => {

        if ( typeof fallback === 'undefined' ) {

            fallback = null;
        }

        if ( !path ) return fallback;

        if (path.indexOf('.') > -1) {
            let parts = path.split('.');
            let field = parts[0];
            parts.shift();
            parts = parts.join('.');
            return typeof object !== 'undefined' && object !== null ? F.recursivePath(object[field], parts, fallback) : fallback;
        } else {
            return typeof object !== 'undefined' && object !== null ? object[path] || fallback : fallback;
        }

    },

    ucFirst: (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    },

    lcFirst: (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toLowerCase() + s.slice(1);
    },

    findByValue: (list, value, output, column) => {
        let listItem = undefined;
        let index = undefined;
        column = column || 'value';
        list = list || [];
        for ( let i = 0; i < list.length; i++ ) {
            if ( value === list[i][column] ) {
                listItem = list[i];
                index = i;
            }
        }
        if ( output === 'index' ) return index;
        if ( output === 'object' ) return listItem;
        return listItem.label;
    },

    friendlyCase: (string) => {
        if ( string === undefined || string === null ) {
            return '';
        }
        return window.friendlyCase(string);
    },

    slugify: (string) => {
        if ( string === undefined || string === null ) {
            return '';
        }
        return F.lcFirst(string.replace(' ', '').replace(' ', '').replace(' ', ''));
    },

    moneyValue: (value) => {
        if ( typeof value === 'undefined' ) return 0;
        return parseFloat(parseFloat(value.toString().replace(/,/g, '').replace(/J/g, '').replace(/\s/g, '').replace(/\$/g, '')).toFixed(2));
    },

    numericValue: (value) => {
        return F.numericValueExt(value, 2);
    },

    numericValueExt: (value, decimalScale) => {
        decimalScale = decimalScale !== undefined ? decimalScale : 2;
        if ( typeof value === 'undefined' ) return 0;
        return parseFloat(parseFloat(value.toString().replace(/,/g, '').replace(/%/g, '').replace(/\s/g, '').replace(/\$/g, '')).toFixed(decimalScale));
    },

    reduceList: (object) => {
        let list = [];
        for ( let key in object ) {
            if ( object.hasOwnProperty(key) ) {
                list.push(object[key]);
            }
        }
        return list;
    },

    randomString: length => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },

    convertUsersToList: users => {
        return (users || []).map(item => {
            let userName = [item.firstName, item.lastName].filter(item => item).join(' ');
            let userId = item._id;
            return {
                label: userName,
                value: userId,
                userName,
                userId,
                ...item,
            }
        });
    },

    convertRecordsToList: (records, fields) => {
        let valueField = fields.value || '_id';
        let labelField = fields.label || 'name';
        return records.map(item => {
            return {
                value: item[valueField],
                label: item[labelField],
                ...item
            };
        })
    },

    listItemsFromObject: args => {
        let {object, fields} = args;
        let stringList = [];
        for ( let f = 0; f < fields.length; f++ ) {
            stringList.push(object[fields[f]]);
        }
        return stringList.filter(item => item).join(', ');
    }
};

export default F;