import {all, call, fork, put, takeEvery} from "redux-saga/effects";
/*import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../firebase/firebase";*/
import {
  COMPLETE_PASSWORD_RESET,
  RESET_USER_PASSWORD,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess, tokenRequestSuccess, passwordResetSuccess} from "actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";
import axios from "axios";
import F from '../util/Functions';

const createUserWithEmailPasswordRequest = async (email, password) => {
  // await auth.createUserWithEmailAndPassword(email, password)
  //     .then(authUser => authUser)
  //     .catch(error => error);
}

const signInUserWithEmailPasswordRequest = async (email, password) =>
    // await  auth.signInWithEmailAndPassword(email, password)
    await axios.post(window.endpoint('admin', '/auth/login'), {email: email, password: password})
        .then(authUser => authUser)
        .catch(error => error);

const requestResetToken = async (email, phoneNumber, resetType) =>
    await axios.post(window.endpoint('admin', '/auth/reset/start'), {email, phoneNumber, resetType})
        .then(result => result)
        .catch(error => error);

const resetPassword = async (email, newPassword, resetCode) =>
    await axios.post(window.endpoint('admin', '/auth/reset/complete'), {email: email, newPassword: newPassword, resetCode: resetCode})
        .then(result => result)
        .catch(error => error);

const signOutRequest = async () => {
  // await auth.signOut()
  //     .then(authUser => authUser)
  //     .catch(error => error);
}

const signInUserWithGoogleRequest = async () => {
  // await  auth.signInWithPopup(googleAuthProvider)
  //     .then(authUser => authUser)
  //     .catch(error => error);
}

const signInUserWithFacebookRequest = async () => {
  // await auth.signInWithPopup(facebookAuthProvider)
  //     .then(authUser => authUser)
  //     .catch(error => error);
}

const signInUserWithGithubRequest = async () => {
  // await auth.signInWithPopup(githubAuthProvider)
  //     .then(authUser => authUser)
  //     .catch(error => error);
}

const signInUserWithTwitterRequest = async () => {
  // await  auth.signInWithPopup(twitterAuthProvider)
  //     .then(authUser => authUser)
  //     .catch(error => error);
}

function* createUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.data.message) {
      yield put(showAuthMessage(signInUser.data.message));
    } else if (signInUser.data.user) {
      localStorage.setItem('userId', signInUser.data.user._id);
      localStorage.setItem('accessToken', signInUser.data.user.accessToken);
      localStorage.setItem('userName', signInUser.data.user.firstName);
      localStorage.setItem('userEmail', signInUser.data.user.email);
      localStorage.setItem('userPermissions', F.recursivePath(signInUser.data.user, 'permissions.permissionsList'));
      yield put(userSignInSuccess(signInUser.data.user._id));
    } else {
      yield put(showAuthMessage("Sorry, we were unable to sign you in at the moment. Please try again or contact tech Support."));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resetUserPassword({payload}) {
  const {email, phoneNumber, resetType} = payload;
  yield put(tokenRequestSuccess(null));
  try {
    const token = yield call(requestResetToken, email, phoneNumber, resetType);
    if (token.data.message) {
      yield put(showAuthMessage(token.data.message));
    } else if (token.data.success) {
      yield put(tokenRequestSuccess(token.data.tokenId));
    } else {
      yield put(showAuthMessage(`Sorry, we were unable to verify your identity. Please contact an admin.`));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* completeResetUserPassword({payload}) {
  const {email, newPassword, resetCode} = payload;
  try {
    const token = yield call(resetPassword, email, newPassword, resetCode);
    if (token.data.message) {
      yield put(showAuthMessage(token.data.message));
    } else if (token.data.success) {
      yield put(passwordResetSuccess(token.data.tokenId));
    } else {
      yield put(showAuthMessage(`Sorry, we were unable to reset your password. Please contact an admin.`));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    localStorage.removeItem('user_id');
    localStorage.removeItem('userId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPermissions');
    localStorage.removeItem('passwordExpired');
    if (signOutUser === undefined) {
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* userResetPassword() {
  yield takeEvery(RESET_USER_PASSWORD, resetUserPassword);
}

export function* completeResetPassword() {
  yield takeEvery(COMPLETE_PASSWORD_RESET, completeResetUserPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
    fork(userResetPassword),
    fork(completeResetPassword),
  ]);
}