import React, {Fragment, useEffect, useState} from 'react';
import {Form, Modal, FormControl, FormCheck, Row, Col, FormLabel, Table, Container} from "reactstrap";
import F from "./Functions";
import {Button as MaterialButton} from "@material-ui/core";
import {CurrencyText, GenericField} from "./FieldTypes";
import axios from "axios";
import moment from "moment";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import A from "../util/Arithmetic";
import Lists from "./Lists";

const currencyFields = ['amount', 'penaltyWrittenOff'];

const LoanPayment = props => {

    const {loanId, show, handleClose} = props;
    const [loan, setLoanState] = useState({client: {personal: {}}, transactions: [], unset: true});
    const [penaltyTransactionsForReverse, setPenaltyTransactionsForReverse] = useState([]);

    const paymentMethods = Lists.paymentMethods;

    const defaultState = {
        data: {},
        showModalWindow: true,
    };

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        const loadState = async () => {
            setState({...state, showModalWindow: true});
            if ( loan.unset ) await loadLoan();
        };
        loadState();
    }, [props]);

    const onChange = async (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if ( currencyFields.indexOf(name) > -1 ) value = F.moneyValue(A.f(value));
        if ( name === 'penaltyWrittenOff' ) {
            value = Math.max(0, Math.min(F.recursivePath(loan, 'due.penalty', 0), value) );
        }
        let data = {...state.data};
        data[name] = value;
        console.log(data, 'data after')
        await setState({...state, data});
    };

    const onChangeDate = (field, value) => {
        onChange({target: {name: field, value: value}});
    };

    const onChangeRadio = (e, field, value) => {
        onChange({target: {name: field, value}});
    };

    const closeModalWindow = () => {
        if (state.isPageDirty) {
            if ( window.confirm("Do you wish to discard your changes and close this screen?") ) {
                forceCloseModalWindow();
            }
        } else {
            forceCloseModalWindow();
        }
    };

    const forceCloseModalWindow = () => {
        setState({...state, showModalWindow: false, data: {}});
        handleClose();
    };

    const loadLoan = async () => {
        loading(true);
        let payload = {id: loanId};
        await axios.post(window.endpoint('loans', '/get-loan'), payload, window.getConfig()).then(result => {
            if ( result.data ) {
                loading(false);
                setLoanState(result.data.loan);
            }
        }).catch(
            error => {
                loading(false);
                window.Messenger.broadcast('DisplayNotification', {type: 'error'});
            }
        );
    };

    const postPayment = async () => {
        await postPenaltyReversals();
        await postPaymentAction();
    };

    const postPaymentAction = async () => {
        loading(true);
        let payload = {id: loanId, payload: state.data};
        let error = null;
        await axios.post(window.endpoint('loans', '/loan/pay'), payload, window.getConfig()).then(result => {
            if ( result.data ) {
                loading(false);
                if ( result.data.hasErrors === true ) {
                    error = {...result.data.notification, type: 'error'};
                }
            }
        }).catch(
            error => {
                loading(false);
                window.Messenger.broadcast('DisplayNotification', {type: 'error'});
            }
        );

        if ( error ) {
            window.Messenger.broadcast('DisplayNotification', error);
        } else {
            forceCloseModalWindow();
            window.Messenger.broadcast('PostStatusActionUpdate', {});
        }
    };

    const postPenaltyReversals = async () => {
        if ( !penaltyTransactionsForReverse.length ) return;
        if ( !window.confirm('Are you sure you want to reverse the selected transaction(s)?') ) return false;
        loading(true);
        let payload = {id: loanId, payload: {transactionIds: penaltyTransactionsForReverse}};
        let error = null;
        await axios.post(window.endpoint('loans', '/loan/reverse-transaction'), payload, window.getConfig()).then(result => {
            if ( result.data ) {
                loading(false);
                if ( result.data.hasErrors === true ) {
                    error = {...result.data.notification, type: 'error'};
                }
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', {type: 'error'});
            }
        );

        if ( error ) {
            window.Messenger.broadcast('DisplayNotification', error);
        }
    };

    const loading = (state) => {
        window.Messenger.broadcast("SetLoadingState", {isLoading: state});
    };

    const goToPage = (page) => {
        setState({...state, page: page});
    };

    const toggleSelectPenaltyTransaction = (id) => {
        let _penaltyTransactionsForReverse = [...penaltyTransactionsForReverse];
        let index = _penaltyTransactionsForReverse.indexOf(id);
        if ( index === -1 ) {
            _penaltyTransactionsForReverse.push(id);
        } else {
            _penaltyTransactionsForReverse.splice(index, 1);
        }
        setPenaltyTransactionsForReverse(_penaltyTransactionsForReverse);
    };

    const penaltyTransactions = loan.transactions.filter(item => {
        let penaltyBalance = A.sub(item.debit, item.penaltyWrittenOff);
        return item.type === 'penaltyCharged' && item.status === 'completed' && penaltyBalance > 0;
    });

    const penaltyReversalTable = <>
        <h4>Select Penalty Transactions to Reverse</h4>
        <Table className={""} striped={true} borderless={true}>
            <thead>
            <tr>
                <th>
                    {penaltyTransactionsForReverse.length === penaltyTransactions.length &&
                    <span className={"clickable"} onClick={() => setPenaltyTransactionsForReverse([])}>
                                                    <i className={"fa fa-check-double text-success"}></i>
                                                </span>
                    }
                    {penaltyTransactionsForReverse.length !== penaltyTransactions.length &&
                    <span className={"clickable"} onClick={() => setPenaltyTransactionsForReverse(penaltyTransactions.map(item => item._id))}>
                                                    <i className={"fa fa-check-double"}></i>
                                                </span>
                    }
                </th>
                <th>
                    Date
                </th>
                <th>
                    Amount
                </th>
            </tr>
            </thead>
            <tbody>
            {penaltyTransactions.map((transaction, key) => (
                <tr key={key} className={"clickable"} onClick={() => toggleSelectPenaltyTransaction(transaction._id)}>
                    <td>
                        {penaltyTransactionsForReverse.includes(transaction._id) &&
                        <>
                            <i className={"fa fa-check text-success"}></i>
                        </>
                        }
                    </td>
                    <td>
                        <strong>{moment(transaction.transactionDate).format("YYYY-MMM-DD")}</strong>
                    </td>
                    <td>
                        <CurrencyText
                            value={Number(transaction.credit || 0) + Number(transaction.debit || 0)} />
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
        <hr />
    </>;

    const totalFeeInstalments = A.add(F.recursivePath(loan, 'feeInstalments', []).filter(item => item.totalBalance > 0).map(item => item.totalBalance));

    const paymentModalWindow = <>
        <Modal
            isOpen={show}
            toggle={handleClose}
            modalTransition={{ timeout: 200 }}
            backdropTransition={{ timeout: 1300 }}
            size={"lg"}
        >
            <>
                <ModalHeader
                    toggle={handleClose}
                >
                    Post a Payment
                </ModalHeader>
                <ModalBody>
                    <Container>
                        {loanId &&
                        <Row className={""}>
                            <Col md={12}>
                                <div className={"bg-lightest-green p-20 radius-10"}>
                                    <h5>
                                        {F.recursivePath(loan, 'client.bio.fullName')}&nbsp;
                                        <i class={"fa fa-caret-right"}></i>&nbsp;
                                        <span class={"text-info"}>
                                            Loan #:&nbsp;
                                            {F.recursivePath(loan, 'loanNumber')}
                                        </span>
                                    </h5>
                                    <Row>
                                        <Col md={6}>
                                            <span className={"text-danger"}>Total Due:&nbsp;
                                                <CurrencyText
                                                    value={A.add([
                                                        F.recursivePath(loan, 'due.principalAllDue', 0),
                                                        F.recursivePath(loan, 'due.interestAllDue', 0),
                                                        F.recursivePath(loan, 'due.feeAllDue', 0),
                                                        totalFeeInstalments,
                                                        F.recursivePath(loan, 'due.penalty', 0)
                                                    ])} />
                                            </span>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <Row>
                                        <Col md={6}>
                                            <strong>Fee:</strong><br />
                                            <CurrencyText
                                                value={F.recursivePath(loan, 'due.feeAllDue', 0)} />
                                            {totalFeeInstalments > 0 &&
                                            <>&nbsp;<i className={"fa fa-plus"}></i>&nbsp;
                                                <CurrencyText value={totalFeeInstalments}/>
                                            </>
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <strong>Penalty:</strong><br />
                                            <CurrencyText
                                                value={F.recursivePath(loan, 'due.penalty', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Principal:</strong><br />
                                            <CurrencyText
                                                value={F.recursivePath(loan, 'due.principalAllDue', 0)} />
                                        </Col>
                                        <Col md={6}>
                                            <strong>Interest</strong><br />
                                            <CurrencyText
                                                value={F.recursivePath(loan, 'due.interestAllDue', 0)} />
                                        </Col>
                                    </Row>
                                </div>
                                <Row className={"push-down"}>
                                    <Col md={6}>
                                        <GenericField
                                            name={"method"}
                                            type={"dropdown"}
                                            list={paymentMethods}
                                            value={state.data.method}
                                            onChange={onChange}
                                            label={"Method of Payment"}
                                            required={true}
                                        />
                                    </Col>
                                    <Col md={6} className={"dropdown"}>
                                        <GenericField
                                            name={"amount"}
                                            type={"text"}
                                            format={"currency"}
                                            value={state.data.amount}
                                            onChange={onChange}
                                            label={"Amount"}
                                            required={true}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row className={"push-down"}>
                                    <Col md={6} className={"dropdown"}>
                                        <GenericField
                                            disabled={F.recursivePath(loan, 'due.penalty', 0) > 0}
                                            name={"penaltyWrittenOff"}
                                            type={"text"}
                                            format={"currency"}
                                            value={state.data.penaltyWrittenOff}
                                            onChange={onChange}
                                            label={"Penalty Write-off Amount"}
                                            required={false}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <GenericField
                                    name={"transactionDate"}
                                    value={state.data.transactionDate}
                                    type={"date"}
                                    list={state.transactionDate}
                                    onChangeDate={onChangeDate}
                                    label={"When was the payment made?"}
                                />
                                <br />
                                <GenericField
                                    name={"notes"}
                                    value={state.data.notes}
                                    type={"textarea"}
                                    onChange={onChange}
                                    label={"Notes"}
                                />

                                <hr/>

                                {penaltyTransactions.length > 0 &&
                                <div className={"scroll-h-300 push-down"}>
                                    {penaltyReversalTable}
                                </div>
                                }

                                <MaterialButton
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => postPayment()}
                                >
                                    Post Payment
                                </MaterialButton>
                                &nbsp;&nbsp;
                                <MaterialButton
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MaterialButton>
                            </Col>
                        </Row>
                        }
                    </Container>
                </ModalBody>
            </>
        </Modal>
    </>;

    return (
        <>
            {paymentModalWindow}
        </>
    );

}

export default LoanPayment;