import React, {useEffect, useState} from "react";
import F from "./Functions";

import UserAvatar from 'react-user-avatar';

const Avatar = props => {

    const allInitials = props.name.split(' ').map(item => item.charAt(0).toUpperCase());
    let initials = allInitials[0];
    if ( allInitials.length > 1 ) {
        initials = `${initials}${allInitials[allInitials.length-1]}`;
    }

    return (
        <>
            <span className={`avatar-${props.size || 32}`}>{initials}</span>&nbsp;
        </>
    );

};

export default Avatar;
