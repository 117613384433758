import React from 'react';
import {useDispatch} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import MenuItem from "@material-ui/core/MenuItem";

const UserInfoPopup = () => {

    const signOutUser = () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('userId');
        localStorage.removeItem('accessToken');
        window.top.location.replace('/signin');
    };

    const goTo = (url) => {
        window.location.assign(url)
    };

    const dispatch = useDispatch();

    return (
        <div>
            <div className="user-profile">
                <div className="user-detail ml-2">
                    <h4 className="user-name mb-0 text-white">{localStorage.getItem('userName')}</h4>
                    <small className={"text-white"}>{localStorage.getItem('userEmail')}</small>
                </div>
            </div>
            <span className="jr-link dropdown-item text-muted" onClick={() => goTo('/app/user/account')}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
          Manage Account
        </span>
            <span className="jr-link dropdown-item text-muted" onClick={() => {
                signOutUser()
            }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.logout"/>
        </span>
        </div>
    );
};

export default UserInfoPopup;


