import axios from "axios";

const post = async args => {
    let result = await request('POST', args);
    return result;
};

const get = async args => {
    let result = await request('GET', args);
    return result;
};

const request = async (method, args) => {
    let {url, absoluteUrl, service, body, headers, mode} = args;
    headers = headers || [];
    service = service || 'main';
    url = !absoluteUrl ? window.endpoint(service, url) : absoluteUrl;
    body = body || {};
    let userId = localStorage.getItem('userId');
    let accessToken = localStorage.getItem('accessToken');
    let timezoneOffset = new Date().getTimezoneOffset();

    headers = {...headers, userId, accessToken, timezoneOffset, x: 'y'};
    /*if ( mode === 'b' ) {
        body.append(key, value);
    } else {
        body[key] = value;
    }*/

    console.log(headers)

    let result = {data: {}};
    await axios.post(url, body, {headers})
        .then(response => {
            if ( typeof response.data !== 'undefined') {
                if ( response.data.notification ) {
                    window.Messenger.broadcast('DisplayNotification', response.data.notification);
                } else if ( response.data.hasErrors ) {
                    window.Messenger.broadcast('DisplayNotification', {type: 'error', message: response.data.errors || response.data.message});
                } else {
                    result = response.data;
                    if ( typeof result === 'object' ) {
                        result.success = true;
                    }
                    return result;
                }
            }
        })
        .catch(error => {
            console.log(error);
            return result;
        });
    return result;
};

const Http = {
    post,
    get
};

export default Http;