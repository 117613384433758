import React, {Component, useEffect, useState} from 'react';
import {DecimalInputField, GenericField, MoneyInputField} from "../util/FieldTypes";
import {Button} from "@material-ui/core";
import {Container, FormGroup, Row, Col} from "reactstrap";
import A from '../util/Arithmetic';
import Http from "../util/Http";
import moment from "moment";

const ReportFilterWidget = props => {

    let payloadCacheId = window.query('payloadCacheId');
    let query = JSON.parse(decodeURIComponent(window.query('query')));
    let filterTokenId = window.query('filterTokenId');
    let objectId = window.query('objectId');

    const [filterModel, setFilterModel] = useState({model: {}});
    const [payload, setPayload] = useState([]);

    useEffect(() => {
        loadModel();
    }, [props]);

    const loadModel = async () => {
        let body = {filterTokenId, objectId};
        let data = await Http.post({service: 'reports', url: '/reports/get-filter-model', body});
        if (!data.model) data.model = {};
        await setFilterModel(data);
        let payloadResponse = await Http.post({service: 'cache', url: `/get/${payloadCacheId}`, body: {}});
        let payloadData = JSON.parse(decodeURIComponent(payloadResponse));
        setPayload(payloadData);
    };

    const saveModel = async () => {
        let payload = {service: 'reports', body: {model: filterModel.model, objectId, filterTokenId}, url: '/reports/save-filter-model'};
        await Http.post(payload);
        window.parent.postMessage('CloseReportWidgetAction', '*');
    };

    const hideWidget = async () => {
        window.parent.postMessage('HideReportWidgetAction', '*');
    };

    const handleChange = async args => {
        let {name, value, path} = args;
        let newModel = {...filterModel};
        if ( !filterModel.model[path] ) {
            newModel.model[path] = {};
        }
        newModel.model[path][name] = value;
        await setFilterModel(model => ({...model, ...newModel}));
    };

    const handleChangeDate = args => {
        let {name, value, path} = args;
        value = moment(value).format('YYYY-MM-DD');
        console.log(args, 'DAAAAATE CHANGE');
        handleChange({name, value, path});
    };

    return (
        <div style={{overflowY: "scroll", overflowX: "hidden", width: "100%", padding: '20px'}}>
            {payload.map((reportField, key) => (
                <div key={key}>
                    {reportField.type === 'currency' &&
                    <>
                        <label>{reportField.label}</label>
                        <Row>
                            <Col md={4}>
                                <GenericField
                                    type={'number'}
                                    format={'currency'}
                                    label={'Minimum'}
                                    fieldName={'minimum'}
                                    value={(filterModel.model[reportField.path] || {}).minimum}
                                    onChange={(e) => handleChange({value: A.f(e.target.value), name: e.target.name, path: reportField.path})}
                                />
                            </Col>
                            <Col md={4}>
                                <GenericField
                                    type={'number'}
                                    format={'currency'}
                                    label={'Maximum'}
                                    fieldName={'maximum'}
                                    value={(filterModel.model[reportField.path] || {}).maximum}
                                    onChange={(e) => handleChange({value: A.f(e.target.value), name: e.target.name, path: reportField.path})}
                                />
                            </Col>
                        </Row>
                        <br />&nbsp;
                    </>
                    }
                    {reportField.type === 'number' &&
                    <>
                        <label>{reportField.label}</label>
                        <Row>
                            <Col md={4}>
                                <DecimalInputField
                                    label={'Minimum'}
                                    fieldName={'minimum'}
                                    value={(filterModel.model[reportField.path] || {}).minimum}
                                    onChange={(e) => handleChange({value: A.f(e.target.value), name: e.target.name, path: reportField.path})}
                                />
                            </Col>
                            <Col md={4}>
                                <DecimalInputField
                                    label={'Maximum'}
                                    fieldName={'maximum'}
                                    value={(filterModel.model[reportField.path] || {}).maximum}
                                    onChange={(e) => handleChange({value: A.f(e.target.value), name: e.target.name, path: reportField.path})}
                                />
                            </Col>
                        </Row><br />&nbsp;
                    </>
                    }
                    {reportField.type === 'date' &&
                    <>
                        <label>{reportField.label.replace('Transaction Date', 'Disbursement Date')}</label>
                        <Row>
                            <Col md={4}>
                                <GenericField
                                    type={'date'}
                                    label={'Minimum'}
                                    fieldName={'minimum'}
                                    value={(filterModel.model[reportField.path] || {}).minimum}
                                    onChangeDate={(field, date) => handleChangeDate({value: date, name: 'minimum', path: reportField.path})}
                                />
                            </Col>
                            <Col md={4}>
                                <GenericField
                                    type={'date'}
                                    label={'Maximum'}
                                    fieldName={'maximum'}
                                    value={(filterModel.model[reportField.path] || {}).maximum}
                                    onChangeDate={(field, date) => handleChangeDate({value: date, name: 'maximum', path: reportField.path})}
                                />
                            </Col>
                        </Row>
                    </>
                    }
                </div>
            ))}
            <hr />
            <Button onClick={() => saveModel()}>
                Apply Filters
            </Button> &nbsp; &nbsp;
            <Button onClick={() => hideWidget()}>
            Close Window
        </Button>
        </div>
    );
};

export default ReportFilterWidget;
