import React, {Fragment} from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setGlobalState} from "../../../../../actions";
import axios from "axios";
import {CellValue, Paging, ShortenText} from "../../../../../util/Template";
import {ButtonGroup, Col, Dropdown, Row, Table} from "react-bootstrap";
import F from "../../../../../util/Functions";
import {
    CurrencyText,
    DateText,
    GenericField,
    PercentageText,
    RelativeDate,
    StaticProgress
} from "../../../../../util/FieldTypes";
import {Paper} from "@material-ui/core";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import LoanPayment from "../../../../../util/LoanPayment";
import A from "../../../../../util/Arithmetic";
import AccessControl from "../../../../../util/AccessControl";
import Http from "../../../../../util/Http";
import Lists from "../../../../../util/Lists";
import * as XLSX from "xlsx";
// import "../../../../../App.css"
let pageTitle = '';

const contactResultTypes = [
    {label: 'Promise to Pay', value: 'promiseToPay'},
    {label: 'No promise', value: 'noPromise'},
];

const currencyFields = ['promiseAmount'];

const contactMethods = [
    {label: 'Phone Call', value: 'phone'},
    {label: 'Email', value: 'email'},
    {label: 'Text', value: 'text'},
    {label: 'WhatsApp', value: 'whatsapp'},
];

const messageChannels = [
    {label: 'Email', value: 'email'},
    {label: 'Text', value: 'text'},
];

class Batches extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            filter: {
            },
            query: {
                page: 1,
                totalRecords: 0,
                limit: 8
            },
            payments_query: {
                page: 1,
                totalRecords: 0,
                limit: 8
            },
            sorting: {
                field: 'createdAt',
                order: 1
            },
            loans: {
                records: [],
                columns: [],
            },
            batches: {
                records: [],
                columns: [],
            },
            payments: {
                records: [],
                columns: []
            },
            showCollectionsScreen: false,
            showBatchCreationWindow: false,
            showBatchPaymentsWindow: false,
            master: this.props.master,
            showPaymentWindow: false,
            loan: {due: {}},
            client: {bio: {}},
            collections: {
                contacts: [],
                followUps: [],
                promises: []
            },
            contactRecord: {
                reachable: false,
                setFollowUp: false,
            },
            showContactWindow: false,
            message: {},
            showMessageWindow: false,
            promises: [],
            comments: [],
            hasTemplates: false,
            templates: [],
            templatePreview: '',
            tag_name: '',
            searchResults: [],
            newBatches: [],
            files: [],
            search: {
                keywords: []
            },
            batchPayments: [],
            currentBatchIndex: null,
            search_query: '',
            payment_date: '',
            payment_amount: '',
            payment_method: '',
            paymentInfo: {},
            currentBatchId: '',
            currentBatchTag: '',
            newBatchTag: '',
            fileName: '',
            fileType: '',
            disableSubmit: false,
            start: '',
            end: '',
            total: '',
            sumOfPayments: '',
            previous: false,
            next: false,
            pageCount: '',
            currentBatchStatus: '',
            newBatchId: ''
        };

        this.state.standardFilters = {
            arrears: {
                all: {name: 'All', fields: [{fieldName: 'status', checkValue1: 'late'}]},
                oneToSevenDays: {name: '1-7 days late', fields: [{fieldName: 'lateDays', checkValue1: 1, checkValue2: 7, operator: 'between', type: 'number'}]},
                eightToFifteenDays: {name: '8-15 days late', fields: [{fieldName: 'lateDays', checkValue1: 8, checkValue2: 15, operator: 'between', type: 'number'}]},
                sixteenToThirtyDays: {name: '16-30 days late', fields: [{fieldName: 'lateDays', checkValue1: 16, checkValue2: 30, operator: 'between', type: 'number'}]},
                thirtyOneToSixtyDays: {name: '31-60 days late', fields: [{fieldName: 'lateDays', checkValue1: 31, checkValue2: 60, operator: 'between', type: 'number'}]},
                sixtyOneToNinetyDays: {name: '61-90 days late', fields: [{fieldName: 'lateDays', checkValue1: 61, checkValue2: 90, operator: 'between', type: 'number'}]},
                overNinetyDays: {name: '91+ days late', fields: [{fieldName: 'lateDays', checkValue1: 90, operator: 'gt', type: 'number'}]},
            },
            projection: {
                all: {name: 'All!', fields: [{fieldName: 'collections.matured', checkValue1: false}]},
                today: {name: 'Due today', fields: [{fieldName: 'collections.timeBeforeDueDate', checkValue1: 0, operator: 'eq', type: 'number'}]},
                oneToSevenDays: {name: 'Due in 1-7 days', fields: [{fieldName: 'collections.timeBeforeDueDate', checkValue1: 1, checkValue2: 7, operator: 'between', type: 'number'}]},
                eightToFifteenDays: {name: 'Due in 8-15 days', fields: [{fieldName: 'collections.timeBeforeDueDate', checkValue1: 8, checkValue2: 15, operator: 'between', type: 'number'}]},
                sixteenToThirtyDays: {name: 'Due in 16-30 days', fields: [{fieldName: 'collections.timeBeforeDueDate', checkValue1: 16, checkValue2: 30, operator: 'between', type: 'number'}]},
                // thirtyOneToSixtyDays: {name: 'Due in 31-60 days', fields: [{fieldName: 'collections.timeBeforeDueDate', checkValue1: 31, checkValue2: 60, operator: 'between', type: 'number'}]},
                // sixtyOneToNinetyDays: {name: 'Due in 61-90 days', fields: [{fieldName: 'collections.timeBeforeDueDate', checkValue1: 61, checkValue2: 90, operator: 'between', type: 'number'}]},
                // overNinetyDays: {name: 'Due in 91+ days', fields: [{fieldName: 'collections.timeBeforeDueDate', checkValue1: 90, operator: 'gt', type: 'number'}]},
            },
            batches: {
                all: {name: 'All!', fields: []},
                submitted: {name: 'Submitted', fields: [{fieldName: 'status', checkValue1: 'submitted', operator: 'eq', type: 'string'}]},
                queued: {name: 'Queued', fields: [{fieldName: 'status', checkValue1: 'queued', operator: 'eq', type: 'string'}]},
                inFlight: {name: 'In Flight', fields: [{fieldName: 'status', checkValue1: 'in_flight', operator: 'eq', type: 'string'}]},
                completed: {name: 'Completed', fields: [{fieldName: 'status', checkValue1: 'completed', operator: 'eq', type: 'string'}]},
                failed: {name: 'Failed', fields: [{fieldName: 'status', checkValue1: 'failed', operator: 'eq', type: 'string'}]},
                stopped: {name: 'Stopped', fields: [{fieldName: 'status', checkValue1: 'stopped', operator: 'eq', type: 'string'}]},
                deleted: {name: 'Deleted', fields: [{fieldName: 'status', checkValue1: 'deleted', operator: 'eq', type: 'string'}]},
                draft: {name: 'Draft', fields: [{fieldName: 'isDraft', checkValue1: true, operator: 'eq', type: 'boolean'}]},

            }
        };

        let standardFiltersList = {};
        for ( let a in this.state.standardFilters ) {
            if ( this.state.standardFilters.hasOwnProperty(a) ) {
                standardFiltersList[a] = standardFiltersList[a] || [];
                for (let i in this.state.standardFilters[a]) {
                    if (this.state.standardFilters[a].hasOwnProperty(i)) {
                        let filter = this.state.standardFilters[a][i];
                        if (a === 'arrears') {
                            filter.fields = filter.fields || [];
                            filter.fields.push({fieldName: 'status', checkValue1: 'late'});
                        } else if (a === 'projection') {
                            filter.fields = filter.fields || [];
                            filter.fields.push({fieldName: 'collections.matured', checkValue1: false});
                        } else if (a === 'batches') {
                            filter.fields = filter.fields || [];
                            // filter.fields.push({fieldName: 'batches.tag'});
                        }
                        // filter.fields.push({fieldName: 'active', checkValue1: true});
                        // filter.fields.push({fieldName: 'disbursed', checkValue1: true});
                        standardFiltersList[a].push({id: i, filter: filter});
                    }
                }
            }
        }

        this.state.standardFiltersList = standardFiltersList;

        this.onChangePaging = this.onChangePagingHandler.bind(this);
        this.setContactRecordDate =this.setContactRecordDateHandler.bind(this);

        this.changeHandler = (e) => {
            // console.log(e.currentTarget.value)
            this.setState({[e.currentTarget.name]: e.currentTarget.value})
            console.log(e.target.value)
            // this.setState({[e.currentTarget.name]: e.currentTarget.value})
            // this.setState({tag_name: e.currentTarget.value})

        }

        const fileCheck = document.getElementById('file-upload')


    };

    componentDidMount() {
        // let master = this.props.match.params.master;
        // console.log(this.props)
        let master = this.props.match.path.split('app/')[1];
        // console.log(this.props.match)
        this.init(master);
        pageTitle = master === 'arrears' ? 'Arrears Management' : 'Batches';
        window.Messenger.broadcast("SetPageTitle", {pageTitle: pageTitle});


        if (this.state.search.keywords === '') {
            this.setState({searchResults: []})
        }

        window.onclick = function(event) {
            // console.log(event.target)
            if (!event.target.matches('.dropbtn')) {
                // console.log('clicked')
                const dropdowns = document.getElementsByClassName("dropdown-content")
                let i;
                for (i = 0; i < dropdowns.length; i++) {
                    const openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('block')) {
                        openDropdown.classList.remove('block')
                        openDropdown.classList.add('hidden')
                    }
                }
            }
        }

    };

    myDropFunc(element) {
        const dropdowns = document.getElementsByClassName("dropdown-content");

        // element.nextSibling is the carriage return… The dropdown menu is the next next.
        const thisDropdown = element.nextSibling.nextSibling;

        if (!thisDropdown.classList.contains('block')) {  // Added to hide dropdown if clicking on the one already open
            let i;
            for (i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.remove('block');
                // dropdowns[i].classList.add('hidden');
            }
        }

        // Toggle the dropdown on the element clicked
        thisDropdown.classList.toggle("show");
    }


    fileUploadHandler (e) {
        if (e.target.files) {
            this.setState({files: e.target.files})
            this.setState({fileName: e.target.files[0].name})
            this.setState({fileType: e.target.files[0].type})
            const file = e.target.files[0]


            const spreadsheetFormats = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'text/tab-separated-values',
                'application/vnd.oasis.opendocument.spreadsheet',
                'application/vnd.ms-excel']

            if (!spreadsheetFormats.includes(`${e.target.files[0].type}`)) {
                this.setState({disableSubmit: true})

                window.Messenger.broadcast('DisplayNotification', {type: 'error', message: 'Incorrect file type! Please upload a spreadsheet document.'})
            }

            // console.log(file)
        }
    }

    async skipToPage (x) {
        // console.log(x)
        // console.log(this.state.payments_query.page)
            let page = this.state.payments_query.page + parseInt(x);

            if (x === 0) {
                page = 1
            }
            if (x === 999) {
                // console.log(this.state.pageCount)
                page = this.state.pageCount
            }

            await this.loadPayments(page)

        // };
    }


    if (fileCheck) {
        fileCheck.addEventListener("change", function (event) {
            // Main().then(r => {})

            console.log('yes, change')
            console.log(event)
            if (event && fileCheck.files.length > 0) {
                let fileList = fileCheck.files

                console.log(fileList)

                // const [file] = fileList;
                // const reader = new FileReader();
                //
                // reader.onload = (evt) => {
                //     const bstr = evt.target.result;
                //     const wb = XLSX.read(bstr, { type: "binary" });
                //     const wsname = wb.SheetNames[0];
                //     const ws = wb.Sheets[wsname];
                //     const data = XLSX.utils.sheet_to_json(ws, { header: 2 });
                //     // console.log(data.splice(1, 10));
                //     // let currentPayments = [...newBatches]
                //     // let newPayments = data.splice(1, 10)
                //     // newPayments.forEach((payment) => {
                //     //     currentPayments.push(payment)
                //     // })
                //     console.log(data)
                //
                //
                //     // setNewBatches(currentPayments)
                //     // console.log(newBatches)
                //
                // };
                // reader.readAsBinaryString(file);


                // setFiles(fileList)
                // setFileName(fileList[0].name)
            } else if (event && !fileCheck.files.length > 0) {
                // setFiles([])
                // setFileName('')
                console.log('No files')
            }
        }, false)
    }

    handleChange(e) {
        const { name, value } = e.target;

        // console.log(name, value)
        if (name === 'payment_amount') {
            this.setState({ [name]: Number(value)});
        } else {
            this.setState({[name]: value});
        }
        // console.log(this.state.payment_amount)
    }

    // onChange (e) {
    //     console.log(e)
    //     // const [file] = e.target.files;
    //     // console.log(file)
    //     return
    //     const reader = new FileReader();
    //
    //     reader.onload = (evt) => {
    //         const bstr = evt.target.result;
    //         const wb = XLSX.read(bstr, { type: "binary" });
    //         const wsname = wb.SheetNames[0];
    //         const ws = wb.Sheets[wsname];
    //         const data = XLSX.utils.sheet_to_json(ws, { header: 2 });
    //         // console.log(data.splice(1, 10));
    //         // let currentPayments = [...newBatches]
    //         // let newPayments = data.splice(1, 10)
    //         // newPayments.forEach((payment) => {
    //         //     currentPayments.push(payment)
    //         // })
    //         console.log(data)
    //
    //         // setNewBatches(currentPayments)
    //         // console.log(newBatches)
    //
    //     };
    //     // reader.readAsBinaryString(file);
    // };

    clicked (e) {

        const idNumber = e.target.id.split('-')[1]

        const dropdowns = document.getElementsByClassName("dropdown-content")
        const thisDropdown = document.getElementById("myDropdown"+idNumber)

        if (!thisDropdown.classList.contains('block')) {
            let i;
            for (i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.remove('block')
                    // dropdowns[i].classList.add('hidden');
            }
        }

        thisDropdown.classList.toggle("block")
    }

    async deleteBatch(e) {
        // console.log(e.target.id.split('-')[1])
        const index = e.target.id.split('-')[1] - 1
        // console.log(this.state.batches.records[index])
        // console.log(`delete: ${this.state.batches.records[index].tag}`)

        let batchId = this.state.batches.records[index]._id

        if (!window.confirm(`Are you sure you want to delete ${this.state.batches.records[index].tag}?`)) {
            return
        } else {
            this.loading(true)
            await Http.post({
                service: 'loans',
                url: '/batches/delete-batch',
                // headers: {batch: batchId}
                body: {batch: batchId}
            }).then((response) => {
                // console.log(response)
                this.loading(false)
                window.Messenger.broadcast('DisplayNotification', {type: 'success', message: 'Batch successfully deleted!'})

                this.loadBatches()
                // if (response.status === 200) {
                //     // alert(response.message)
                //     this.loading(false)
                //     window.location.reload()
                // }
            }).catch(e => {
                console.log(e)
                window.Messenger.broadcast('DisplayNotification', {type: 'error', message: 'Error deleting batch!'})
            })
        }
    }

    async deletePayment(e) {


        let payload = {}
        // console.log(this.state.batchPayments)
        // this.setState({showBatchPaymentsWindow: false})
        this.loading(true)
        // console.log(e.target.id.split('-')[1])
        let index = e.target.id.split('-')[1]
        // this.state.batchPayments.splice(index, 1)

        // let batchId = this.state.batches.records[this.state.currentBatchIndex]._id
        let batchId = this.state.currentBatchId
        let paymentId = this.state.batchPayments[index]._id

        payload = {...payload, batch: batchId, paymentId: paymentId}
        let result = await Http.post({
            service: 'loans',
            url: '/batches/delete-batch-payment',
            body: payload
        })

        // console.log(result)
        // this.loading(false)


        // this.setState({batchPayments: result.payments})
        // let reload = await Http.post({
        //     service: 'loans',
        //     url: '/batches/get-batch-payments',
        //     // headers: {batch: batchId},
        //     body: {batch: batchId}
        // })
        // console.log(reload)


        await this.loadPayments(1)
        // this.setState({batchPayments: reload.payments})

        await this.loadBatches()
        this.loading(false)
        window.Messenger.broadcast('DisplayNotification', {type: 'success', message: 'Payment succuessfully deleted!'})
        // this.setState({showBatchPaymentsWindow: true})
    }

    async viewBatch(e) {
        // console.log(e)
        this.state.currentBatchStatus = ''
        this.state.newBatchId = ''
        this.state.searchResults = []

        this.setState({files: [], fileType: '', fileName: '', payments_query: {...this.state.payments_query, page: 1}, previous: false, next: false})
        this.setState({newBatchId: '', currentBatchId: '', currentBatchTag: '', searchResults: [], search: {...this.state.search, keywords: ''}, payments_query: {...this.state.payments_query, page: 1}})
        // console.log(this.state.batches.records[e.target.id.split('-')[1] - 1]._id)
        this.setState({currentBatchId: this.state.batches.records[e.target.id.split('-')[1] - 1]._id, currentBatchTag: this.state.batches.records[e.target.id.split('-')[1] - 1].tag})

        this.setState({currentBatchIndex: null})
        this.setState({batchPayments: []})
        this.loading(true)
        const index = e.target.id.split('-')[1] - 1
        const status = this.state.batches.records[index].status
        // console.log(index)
        this.setState({currentBatchIndex: index, currentBatchStatus: status})
        let batchId = this.state.batches.records[index]._id
        // console.log(batchId)
        let query = {
            page: 1,
            // limit: this.state.query.limit
            // page: 1,
            limit: 50
        }

        // console.log(this.state.currentBatchIndex)
        // console.log(this.state.currentBatchId)
        // console.log(this.state.batches.records[index])
        // console.log(`view: ${this.state.batches.records[index].tag}`)

        let result = await Http.post({
            service: 'loans',
            url: '/batches/get-batch-payments',
            // headers: {batch: batchId},
            body: {batch: batchId, query: query}
        })

        // console.log(result)

        this.setState({start: result.start, end: result.end, total: result.total})

        if (result.results["next"]) {
            this.setState({next: true})
        }

        if (result.results["previous"]) {
            this.setState({previous: true})
        }



        // if (result.type !== undefined) {
        //     this.setState({batchPayments: result.payments})
        // }
        // this.setState({batchPayments: result.payments, start: result.start, end: result.end, total: result.total})



        this.setState({batchPayments: result.results.payments, pageCount: result.pageCount, sumOfPayments: result.sumOfPayments})

        this.loading(false)
        this.setState({showBatchPaymentsWindow: true})
    }

    async submitBatch () {
        this.loading(true)
        let payload = {}

        let batchId
        let batchIndex = this.state.currentBatchIndex

        if (this.state.newBatchId !== '') {
            batchId  = this.state.newBatchId
        } else {
            batchId = this.state.batches.records?.[batchIndex]?._id
        }

        // const batchId = this.state.batches.records?.[batchIndex]?._id

        if (window.confirm('Are you sure you want to submit this batch for processing?')) {

            payload = {...payload, update_event: 'submit', batch: batchId, payments: this.state.batchPayments}

            let result = await Http.post({
                service: 'loans',
                url: '/batches/submit-batch',
                // headers: {batch: batchId},
                body: payload
            })
            await this.loadBatches()

            result.status = (result || {}).status || ''
            // console.log(result.status)

            this.loading(false)
            this.setState({showBatchPaymentsWindow: false})
        }
    }

    async saveDraft () {
        this.loading(true)
        let payload = {}
        let batchIndex = this.state.currentBatchIndex
        console.log(this.state.currentBatchIndex)
        console.log(this.state.batches.records[batchIndex])
        const batchId = this.state.batches.records[batchIndex]._id

        if (window.confirm('Are you sure you want to save this batch as a draft?')) {

            payload = {...payload, batch: batchId, payments: this.state.batchPayments}

            let result = await Http.post({
                service: 'loans',
                url: '/batches/update-payments',
                // headers: {batch: batchId},
                body: payload
            })

            result.status = (result || {}).status || ''
            console.log(result.status)

            this.loading(false)
            this.setState({showBatchPaymentsWindow: false})
            await this.loadBatches()
        }
    }

    retryBatch (e) {
        // console.log(e.target.id.split('-')[1])
        const index = e.target.id.split('-')[1] - 1
        console.log(this.state.batches.records[index])
        console.log(`retry: ${this.state.batches.records[index].tag}`)
    }


    stopBatch (e) {
        // console.log(e.target.id.split('-')[1])
        const index = e.target.id.split('-')[1] - 1
        console.log(this.state.batches.records[index])
        console.log(`stop: ${this.state.batches.records[index].tag}`)
    }

    loadNewPayment (e) {
        let index = e.target.id.split('-')[1]
        // console.log(this.state.searchResults[index])
        this.setState({paymentInfo: this.state.searchResults[index]})

        // this.setState({showBatchPaymentsWindow: false})

        this.setState({showPaymentReviewWindow: true})
    }

    resetAmount () {
        let amountInput = document.getElementById('payment_amount')

        amountInput.value = ''
        amountInput.focus()
    }

    setTotalDue (e) {
        e.preventDefault()
        let amountInput = document.getElementById('payment_amount')

        amountInput.value = (this.state.paymentInfo.due || {}).total.toFixed(2)
        amountInput.focus()
    }

    resetScrollInsideTable() {
        let tableBody = document.getElementById('payments-tab');
        // tableBody.scrollTop = 0;
        tableBody.scrollIntoView({ behavior: 'smooth' });
    }

    async addPayment(e) {
        e.preventDefault()

        console.log(this.state.paymentInfo)

        if (this.state.payment_method === '') {
            alert('Method field should not be blank')
        }
        else {
            // console.log(document.getElementById('payment_amount').defaultValue)
            // console.log(document.getElementById('payment_amount').value)
            // console.log(document.getElementById('payment_date').defaultValue)
            // console.log(document.getElementById('payment_date').value)
            // console.log(this.state.payment_amount)
            // console.log(this.state.payment_date)

            this.loading(true)

            this.setState({showPaymentReviewWindow: false})
            let batchId

            if (this.state.newBatchId !== '') {
                batchId  = this.state.newBatchId
            } else {
                batchId  = this.state.currentBatchId
            }

            let newPaymentArray
            // if (this.state.payment_amount === '') this.setState({payment_amount: document.getElementById('payment_amount').defaultValue})
            // if (this.state.payment_date === '') this.setState({payment_date: document.getElementById('payment_date').defaultValue})
            let newPayment = {
                name: `${this.state.paymentInfo.client.bio.firstName} ${this.state.paymentInfo.client.bio.lastName}`,
                amount: document.getElementById('payment_amount').value,
                method: this.state.payment_method,
                loanNumber: this.state.paymentInfo.loanNumber,
                date: `${document.getElementById('payment_date').value.toString()}T05:00:00.000Z`
            }

            if (this.state.batchPayments === undefined) {
                this.setState({batchPayments: []})
                newPaymentArray = []
                newPaymentArray.push(newPayment)

                let result = await Http.post({
                    service: 'loans',
                    url: '/batches/update-payments',
                    // headers: {batch: batchId},
                    body: {batch: batchId, payments: newPaymentArray}
                })
                // console.log(result)

            } else {
                newPaymentArray = []
                newPaymentArray.push(newPayment)

                let result = await Http.post({
                    service: 'loans',
                    url: '/batches/update-payments',
                    // headers: {batch: batchId},
                    body: {batch: batchId, payments: newPaymentArray}
                })
                // console.log(result)
            }

            // let reload = await Http.post({
            //     service: 'loans',
            //     url: '/batches/get-batch-payments',
            //     // headers: {batch: batchId},
            //     body: {batch: batchId}
            // })
            // console.log(reload)
            // this.setState({batchPayments: reload.payments})
            //
            // await this.loadBatches()
            // this.loading(false)
            // this.setState({showBatchPaymentsWindow: true})

            await this.loadBatches()
            await this.loadPayments(1)
            // this.setState({batchPayments: reload.payments})

            // this.resetScrollInsideTable()
            // setTimeout(() => {
            //     this.loading(false)
            //
            // }, 250)
            this.setState({showBatchPaymentsWindow: true})
            // this.resetScrollInsideTable()
            this.loading(false)
            window.Messenger.broadcast('DisplayNotification', {type: 'success', message: 'Successfully added payment.'})
        }

    }

    async getSearchResults(e) {
        // let keywords = this.state.search.keywords || '';
        // let payload = {keywords: keywords}
        // this.loading(true)
        // console.log(this.state.search_query)

        let payload = {query: this.state.search_query}
        //axios call to fetch loans using search_query
        if (payload.query !== '') {
            let response = await Http.post({
                service: 'loans',
                url: '/search',
                body: payload
            })

            // console.log(response.results)

            response.results = (response || {}).results || []
            this.setState({searchResults: response.results})
        } else {
            this.setState({searchResults: []})
        }
    }

    async init(master) {
        let defaultFilter = master === 'batches' ? {name: 'Draft', fields: []} : {name: 'Draft', fields: [{fieldName: 'status', checkValue1: 'draft'}]};
        // defaultFilter.fields.push({fieldName: 'active', checkValue1: true});
        // defaultFilter.fields.push({fieldName: 'disbursed', checkValue1: true});
        await this.setState({...this.state, master: master, filter: defaultFilter});
        console.log(this.state)
        await this.loadFilters();
        await this.loadLoans();
        await this.getBatches();

        await this.loadBatches();

    };

    pay() {
        this.setState({...this.state, showPaymentWindow: true});
    };

    setKeywords = keywords => this.setState({...this.state, query: {...this.state.query, keywords}});

    setContactRecord(e) {
        let field = e.target.name;
        let value = e.target.value;
        if ( currencyFields.indexOf(field) > -1 ) value = F.moneyValue(A.f(value));
        this.setState({...this.state, contactRecord: {...this.state.contactRecord, [field]: value}})
    };

    setContactRecordDateHandler(field, value, x) {
        this.setContactRecord({target: {name: field, value: value}});
    };

    setBatchCreationWindow(show) {
        this.setState({...this.state, showBatchCreationWindow: show});
    };

    setBatchPaymentsWindow(show) {
        this.setState({...this.state, showBatchPaymentsWindow: show});
    };

    setPaymentReviewWindow(show) {
        this.setState({...this.state, showPaymentReviewWindow: show});
    };

    setMessage(e) {
        let field = e.target.name;
        let value = e.target.value;
        let state = {...this.state, message: {...this.state.message, [field]: value}};
        if ( field === 'channel' ) {
            if ( value === 'email' && !state.message.emailRecipients ) {
                state.message.emailRecipients = F.recursivePath(this.state.client, 'contact.emailAddresses', []).map(item => item.email).join();
            } else if ( value === 'text' && !state.message.smsRecipients ) {
                state.message.smsRecipients = F.recursivePath(this.state.client, 'contact.phoneNumbers', []).filter(item => item.type === 'mobile').map(item => item.phoneNumber).join();
            }
        }
        if ( field === 'templateId' ) {
            this.loadTemplatePreview(value);
        }
        this.setState(state);
    };

    onChangePagingHandler(query) {
        console.log(query)
        this.setState({...this.state, query});
    };


    loadFilters = async () => {
        this.loading(true);
        console.log(this.state.master)
        let payload = {query: {master: this.state.master}};
        await axios.post(window.endpoint('user', '/get-filters'), payload, window.getConfig()).then(result => {
            if ( result.data ) {
                this.loading(false);
                this.setState({...this.state, filters: result.data.filters});
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', {type: 'error'});
            }
        );
    };

    loadTemplates = async () => {
        this.loading(true);
        let body = {query: {type: {'$in': ['Email', 'Text']}}};
        let data = await Http.post({service: 'documents', url: '/get-templates', body});
        if ( data.success ) {
            this.setState({...this.state, templates: data.templates});
        }
        this.loading(false);
    };

    loadTemplatePreview = async (templateId) => {
        this.loading(true);
        let body = {loanId: this.state.loanId || null, mode: 'fetch', payload: {templateId}, source: 'template'};
        let url = '/create-document';
        let service = 'documents';
        let response = await Http.post({url, service, body});
        if ( response.success ) {
            this.setState({...this.state, templatePreview: response.document.preview});
        }
        this.loading(false);
    };

    loadFilter(filter) {
        this.setState({...this.state, filter: filter, query: {...this.state.query, page: 1}}, () => this.loadBatches());
    };

    createFilter() {
        window.Messenger.broadcast('CreateFilter', {master: this.state.master, filter: {fields: []}});
    };

    keywords(e) {
        // console.log(e.target)
        this.setState({...this.state, search: {...this.state.search, keywords: e.target.value}, search_query: e.target.value}, () => {
            if (e.target?.value === '') {
                this.setState({searchResults: []})

            } else {
                this.getSearchResults().then(r => {})
            }
        });
    };

    // withLoanStatus (client) {
    //     return (client.loanSummary.length > 0 || client.loanSummary.status === ("current" || "late"))
    // }

    async searchClients() {

        let keywords = this.state.search.keywords || '';
        let payload = {keywords: keywords};
        if ( keywords.length < 1 ) {
            payload.findAll = true;
        }
        await Http.post({
            service: 'clients',
            url: '/search',
            body: payload
        })
        // await axios.post(window.endpoint('clients', '/search'), payload, window.getConfig())
            .then(result => {
                // console.log(result)
                // let results = result.filter(this.withLoanStatus)
                // console.log(result.clients.slice(0, 3))
                this.setState({searchResults: result.clients.slice(0, 3)})
                if (typeof result.data !== 'undefined') {
                    this.setState({...this.state, search: {...this.state.search, results: result.clients}});
                }
            });

    };

    async getData(e) {
        e.preventDefault()
        this.setState({payments_query: {...this.state.payments_query, page: 1}})

        let formData = new FormData(document.getElementById('form-sheet'))
        let batchId

        if (this.state.newBatchId !== '') {
            batchId  = this.state.newBatchId
        } else {
            batchId  = this.state.currentBatchId
        }

        // for (const key of formData.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }

        if (window.confirm('Are you sure you want to submit this sheet? This action cannot be undone.')) {

            this.loading(true)

            await Http.post({
                service: 'loans',
                url: '/batches/extract-payments',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'batch': batchId
                },
                body: formData
            })
                .then(async (response) => {
                    let input = document.getElementById('file-upload')


                    input.value = ''
                    this.setState({files: [], fileName: ''})
                    await this.loadBatches()
                    await this.loadPayments(1)

                    // console.log(input.value)


                    // console.log(response)
                    // this.setState({batchPayments: response.payments, files: [], fileName: '', fileType: ''})
                    this.resetScrollInsideTable()
                    // setTimeout(() => {
                    // this.loading(false)
                    //
                    // }, 500)
                    window.Messenger.broadcast('DisplayNotification', {type: 'success', message: 'Successfully retrieved payments.'})

                })
                .catch(e => console.log(e))
        } else {
            return
        }
        // else {
        //     await axios.get('http://13.56.159.86:2157/api/batches/get-submitted').then((response) => {
        //         console.log(response)
        //     }).catch (e => console.log(e))
        // }

    }


    async loadLoans () {
        this.loading(true);
        let payload = {
            filter: this.state.filter,
            query: this.state.query,
            sorting: this.state.sorting,
            columnList: this.state.master
        };
        console.log(payload)
        if ( !payload.filter.master ) payload.filter.master = this.state.master;
        await axios.post(window.endpoint('loans', '/get-loans'), payload, window.getConfig()).then(result => {
            console.log(result)
            if ( result.data ) {
                let loans = result.data.loans;
                let meta = result.data.meta;
                let query = result.data.query;
                this.setState({...this.state, meta: meta, loans: loans, query: query});
                this.loading(false);
            }
        }).catch(
            error => {
                this.loading(false);
            }
        )
    };

    async loadBatches () {
        this.loading(true);
        this.setState({payments_query: {page: 1, limit: 50}})
        let payload = {
            filter: this.state.filter,
            query: this.state.query,
            sorting: this.state.sorting,
            columnList: []
        };
        if ( !payload.filter.master ) payload.filter.master = this.state.master;
        await axios.post(window.endpoint('loans', '/batches/get-batches'), payload, window.getConfig()).then(result => {
            console.log(result)
            if ( result.data ) {
                let batches = result.data.batches;
                let meta = result.data.meta;
                let query = result.data.query;
                this.setState({...this.state, meta: meta, batches: batches, query: query});
                this.loading(false);
            }
        }).catch(
            error => {
                this.loading(false);
            }
        )
    };

    // async getPage (page) {
    //
    // }

    async loadPayments (page) {
        // console.log(page)

        let batchId

        if (this.state.newBatchId !== '') {
            batchId  = this.state.newBatchId
        } else {
            batchId  = this.state.currentBatchId
        }

        this.setState({payments_query: {page: page}})
        // this.setState({showBatchPaymentsWindow: false})
        this.loading(true);
        let payload = {
            // filter: this.state.filter,
            // query: this.state.query,
            query: {page: page, limit: 50},
            sorting: this.state.sorting,
            columnList: [],
            batch: batchId
        };
        // if ( !payload.filter.master ) payload.filter.master = this.state.master;
        await axios.post(window.endpoint('loans', '/batches/get-batch-payments'), payload, window.getConfig()).then(result => {
            // console.log(result)
            console.log(result.data)
            this.setState({start: result.data.start, end: result.data.end, total: result.data.total, sumOfPayments: result.data.sumOfPayments, pageCount: result.data.pageCount})
            if (result.data.results['next']) {
                this.setState({next: true})
            } else {
                this.setState({next: false})
            }

            if (result.data.results.previous) {
                this.setState({previous: true})
            } else {
                this.setState({previous: false})
            }
            if ( result.data ) {
                let payments = result.data.results.payments || [];
                let meta = result.data.meta;
                // let query = result.data.query;
                this.setState({...this.state, meta: meta, batchPayments: payments});
                // this.setState({showBatchPaymentsWindow: true})
                this.loading(false);
            }
        }).catch(
            error => {
                this.loading(false);
            }
        )
    }

    async getBatches () {

        this.loading(true)

        let result = await Http.post({
            service: 'loans',
            url: '/batches/load-batches',
            body: {'test': 'jnheiur'}
        })

        console.log(result)

        if (result) {
            let batches = result.batches
            // let batchId = result.batchId
            // let tag = result.tag

            this.setState({...this.state, batches: batches})
        }

        console.log(result)

        this.loading(false)
    }

    async testConn () {
        this.loading(true)
        let result
        console.log(this.state.master)
        console.log('Clicked')
        console.log(window.getConfig())

        // await axios.post(window.endpoint('loans', '/loans/test-connection'), window.getConfig())
        //     .then(res => console.log(res))
        //     .catch(e => console.log(e))
        setTimeout(async () => {

            result = await Http.post({
                service: 'loans',
                url: '/test-connection',
                // headers: {Host: '13.56.159.86:2157', Origin: 'http://13.56.159.86:2100', Referer: 'http://13.56.159.86:2100'}
            })

            console.log(result)
            this.loading(false)

        }, 5000)




        // this.loading(true);
        // let payload = {};
        // if ( !payload.filter.master ) payload.filter.master = this.state.master;
        // await axios.post(window.endpoint('loans', '/test-connection'), payload, window.getConfig()).then(result => {
        //     console.log(result)
        //     if ( result.data ) {
        //         let loans = result.data.loans;
        //         let meta = result.data.meta;
        //         let query = result.data.query;
        //         this.setState({...this.state, meta: meta, loans: loans, query: query});
        //         this.loading(false);
        //     }
        // }).catch(
        //     error => {
        //         // this.loading(false);
        //         console.log(error)
        //     }
        // )


        }

    async createBatch (e) {
        e.preventDefault()
        this.state.currentBatchStatus = ''
        this.state.searchResults = []
        this.state.batchPayments = []

        this.setState({currentBatchId: '', currentBatchTag: '', searchResults: [], search: {...this.state.search, keywords: ''}, payments_query: {...this.state.payments_query, page: 1}})
        // console.log(this.state)
        // return
        this.setState({batchPayments: [], newBatchTag: '', currentBatchTag: '', start: 0, end: 0, total: 0, sumOfPayments: 0, next: false, previous: false})
        let payload = {
            tag: this.state.tag_name
        }

        this.loading(true)

        let result = await Http.post({
            service: 'loans',
            url: '/batches/create-batch',
            body: payload

        })

        if (result) {
            let status = result.status
            let batchId = result.batchId
            let tag = result.tag

            this.setState({...this.state, showBatchCreationWindow: false, newBatchId: batchId, currentBatchStatus: 'draft', newBatchTag: this.state.tag_name})
        }


        console.log(result)
        console.log('new')
        console.log(this.state.newBatchId)
        await this.loadBatches()
        this.setState({showBatchPaymentsWindow: true})

        // console.log(result)

        // this.loading(false)

    }

    async loadCollectionsData (id) {
        this.loading(true);
        let payload = {id};
        this.setState({...this.state, loanId: id});
        await axios.post(window.endpoint('loans', '/collections/get-account'), payload, window.getConfig()).then(result => {
            if ( result.data ) {
                let {loan, collections, client, promises, followUps, comments} = result.data;
                this.setState({...this.state, ...{client, loan, collections, promises, followUps, comments}});
                this.loading(false);
                this.setCollectionsScreen(true);
            }
        }).catch(
            error => {
                this.loading(false);
            }
        );
    };

    loading(state) {
        window.Messenger.broadcast("SetLoadingState", {isLoading: state});
    };

    sortLoans(field) {
        let currentSort = this.state.sorting;
        if ( currentSort.field === field ) {
            currentSort.order *= -1;
        } else {
            currentSort.field = field;
            currentSort.order = 1;
        }
        this.setState({...this.state, sorting: currentSort, query: {...this.state.query, page: 1}}, async () => {
            await this.loadLoans();
        })
    };

    sortBatches(field) {
        let currentSort = this.state.sorting;
        if ( currentSort.field === field ) {
            currentSort.order *= -1;
        } else {
            currentSort.field = field;
            currentSort.order = 1;
        }
        this.setState({...this.state, sorting: currentSort, query: {...this.state.query, page: 1}}, async () => {
            await this.loadBatches();
        })
    };

    viewLoan(id) {
        window.location.assign('/app/loans/' + id + '/!/summary');
    };

    loadStandardFilter(identifier) {
        let filter = this.state.standardFilters[this.state.master][identifier] || this.state.standardFilters[this.state.master].all;
        filter.master = this.state.master;
        this.loadFilter(filter);
    };

    /// collections screen
    setCollectionsScreen(show) {
        this.setState({...this.state, showCollectionsScreen: show});
    };
    //////////////////////
    setContactWindow(show) {
        this.setState({...this.state, showContactWindow: show});
    };

    setFollowUp(value) {
        this.setContactRecord({target: {name: 'setFollowUp', value: value}});
        if ( value && !this.state.contactRecord.followUpDate ) {
            this.setContactRecord({target: {name: 'followUpDate', value: this.state.contactRecord.promiseDate}});
        }
    };

    async recordContact() {
        this.loading(true);
        let payload = {id: this.state.loan._id, payload: this.state.contactRecord};
        let error = null;
        await axios.post(window.endpoint('loans', '/collections/create-contact'), payload, window.getConfig()).then(result => {
            if ( result.data ) {
                this.loading(false);
                if ( result.data.hasErrors === true ) {
                    error = {...result.data.notification, type: 'error'};
                }
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', {type: 'error'});
            }
        );

        if ( error ) {
            window.Messenger.broadcast('DisplayNotification', error);
        } else {
            this.setContactWindow(false);
            await this.loadCollectionsData(this.state.loan._id);
            this.setState({...this.state, contactRecord: {}});
        }
    };

    setMessageWindow(show) {
        if ( show ) {
            if ( !this.state.hasTemplates ) {
                this.loadTemplates();
            }
        }
        this.setState({...this.state, showMessageWindow: show});
    };

    async sendMessage() {
        this.loading(true);
        let payload = {id: this.state.loan._id, payload: {...this.state.message, body: this.state.templatePreview}, clientId: this.state.loan.clientId};
        let error = null;
        await axios.post(window.endpoint('loans', '/collections/send-message'), payload, window.getConfig()).then(result => {
            if ( result.data ) {
                this.loading(false);
                if ( result.data.hasErrors === true ) {
                    error = {...result.data.notification, type: 'error'};
                }
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', {type: 'error'});
            }
        );

        if ( error ) {
            window.Messenger.broadcast('DisplayNotification', error);
        } else {
            this.setMessageWindow(false);
            this.loadCollectionsData(this.state.loan._id)
        }
    };

    unloadPaymentWindow() {
        this.setState({...this.state, showPaymentWindow: false});
    };

    getStatusIcon = status => {
        let icons = {
            fulfilled: {icon: "check", colour: "success", title: "Fulfilled"},
            unfulfilled: {icon: "times", colour: "danger", title: "Unfulfilled"},
            pending: {icon: "clock", colour: "info", title: "Pending"}
        };
        let icon = icons[status];
        return <i className={"fa fa-" + icon.icon + " text-" + icon.colour} title={icon.title}></i>;
    };

    render () {

        const {tag_name, search_query, currentBatchStatus} = this.state
        const newBatches = []
        const files = []
        const paymentMethods = Lists.paymentMethods;
        const spreadsheetFormats = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/tab-separated-values', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.ms-excel']

        const emailAddresses = F.recursivePath(this.state.client, 'contact.emailAddresses', []).map(item => item.email);
        const phoneNumbers = F.recursivePath(this.state.client, 'contact.phoneNumbers', []).map(item => {
            let value = item.phoneNumber;
            if ( item.type ) {
                value += ' (' + item.type.substr(0, 1).toUpperCase() + ')';
            }
            return value;
        });

        const activePromise = F.recursivePath(this.state.collections, 'promise', {});

        const instalmentDue = F.recursivePath(this.state.loan, 'instalments', []).filter(item => item.status === 'due')[0] || undefined;

        const collectionsScreen = <>
            <Modal
                centered
                isOpen={this.state.showCollectionsScreen}
                fade={true}
                toggle={() => this.setCollectionsScreen(false)}
            >
                <ModalHeader toggle={() => this.setCollectionsScreen(false)}>
                    Collections
                </ModalHeader>
                <ModalBody>
                    <AccessControl required={"viewArrears"} displayError={true}>
                        <Row>
                            <Col md={6}>
                                <h3>{window.getFullName(this.state.client.bio)}</h3>
                            </Col>
                            <Col md={6}>
                                <strong className={"text-danger"}>{this.state.loan.lateDays} day(s) late &nbsp;</strong>
                                {this.state.master === 'projection' &&
                                <>
                                    &nbsp; <strong className={"text-info"}>Due in {F.recursivePath(this.state.loan, 'collections.timeBeforeDueDate', 0)} day(s)</strong>
                                </>
                                }
                                <span className={"pull-right m-t--10"}>
                                    <Dropdown as={ButtonGroup} className={"btn-sm"} noCaret>
                                        <Dropdown.Toggle variant="default"><i
                                            className={"fa fa-bars"}></i></Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <>
                                                <AccessControl required={"recordPayments"} fallback={<Dropdown.Item className={"btn-sm"} disabled>Record a Payment</Dropdown.Item>}>
                                                    <Dropdown.Item className={"btn-sm"}
                                                               onClick={() => this.pay()}>Record a Payment</Dropdown.Item>
                                                </AccessControl>
                                                <AccessControl required={"sendCollectionsMessage"} fallback={<Dropdown.Item className={"btn-sm"} disabled><i className={"fa fa-lock"}></i>&nbsp;Send Message</Dropdown.Item>}>
                                                    <Dropdown.Item className={"btn-sm"}
                                                                   onClick={() => this.setMessageWindow(true)}>Send Message</Dropdown.Item>
                                                </AccessControl>
                                                <AccessControl required={"recordContact"} fallback={<Dropdown.Item className={"btn-sm"} disabled><i className={"fa fa-lock"}></i>&nbsp;Record Contact</Dropdown.Item>}>
                                                <Dropdown.Item className={"btn-sm"}
                                                               onClick={() => this.setContactWindow(true)}>Record Contact</Dropdown.Item>
                                                </AccessControl>
                                                <Dropdown.Item className={"btn-sm"}
                                                               href={'/app/loans/' + this.state.loan._id + '/!/summary'} target={"_blank"}>View Loan</Dropdown.Item>
                                            </>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <span className={"clickable"} onClick={() => this.loadCollectionsData(this.state.loan._id)}><i className={"fa fa-sync-alt"}></i></span>
                                </span>
                            </Col>
                        </Row>
                        <div>
                            <strong>Phone Numbers:</strong>&nbsp;
                            <AccessControl required={"viewClientPhoneNumbers"} fallback={<>***-***-****</>}>{phoneNumbers.join(', ')}</AccessControl>
                                &nbsp;
                            <strong>Email Addresses:</strong>&nbsp;
                            <AccessControl required={"viewClientEmailAddresses"} fallback={<>***-***-****</>}>{emailAddresses.join(', ')}</AccessControl>
                            &nbsp;
                        </div>
                        {activePromise.status &&
                        <div className={"promise-to-pay-container " + activePromise.status}>
                            STATUS: Promised to pay: <CurrencyText value={activePromise.amount} /> on <DateText date={activePromise.promiseDate} /> (<CurrencyText value={activePromise.paid} /> paid, balance <CurrencyText value={activePromise.balance} />)
                        </div>
                        }
                        <hr />
                        {instalmentDue &&
                        <>
                            <h2 className={"text-center text-warning"}><CurrencyText value={instalmentDue.totalBalance} /> DUE today (...<strong>not late yet</strong>)</h2>
                        </>
                        }
                        <Row>
                            <Col md={6}>
                                <Paper className={"p-20 bg-lightblue"}>
                                    <h3 className={"bold"}>Statement</h3>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Principal Balance:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'balance.principal', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Interest Balance:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'balance.interest', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Fee Balance:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'balance.fee', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Penalty Balance:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'balance.penalty', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Total Balance:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'balance.total', 0)} />
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                            <Col md={6}>
                                <Paper className={"p-20 bg-lightpink"}>
                                    <h3 className={"bold"}>Arrears</h3>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Principal Due:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'due.principal', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Interest Due:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'due.interest', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Fee Due:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'due.fee', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Penalty Due:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={F.recursivePath(this.state.loan, 'due.penalty', 0)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Total Arrears:</strong>
                                        </Col>
                                        <Col md={6}>
                                            <CurrencyText value={A.add([this.state.loan.due.principal, this.state.loan.due.interest, this.state.loan.due.fee, this.state.loan.due.penalty])} />
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={6}>
                                <Paper className={"p-20"}>
                                    <h3>Comments</h3>
                                    {this.state.comments.length === 0 &&
                                    <>
                                        No comments posted as yet. Messages sent from Collections will be posted as
                                        comments.
                                    </>
                                    }
                                    {this.state.comments.length > 0 &&
                                    <>
                                        <div className={"p-r-20 scroll-h-400"}>
                                            {this.state.comments.map((comment, key) => (
                                                <div className={"p-10"} key={key}>
                                                    <div className={"update-stream-item"}>
                                                        <div className={"comment-text"}>
                                                            <ShortenText text={comment.message} max={100} toggle={true}/>
                                                        </div>
                                                        <div className={"update-stream-item-subheading"}>
                                                            <RelativeDate value={comment.createdAt}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    }
                                </Paper>
                            </Col>
                            <Col md={6}>
                                <Paper className={"p-20"}>
                                    <h3>Follow-up</h3>
                                    {!this.state.collections.lastContact &&
                                    <>
                                        This section will display information about the last contact made and follow-up
                                        action if there's any.
                                    </>
                                    }
                                    {this.state.collections.lastContact &&
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <strong>Last contacted:</strong>
                                            </Col>
                                            <Col md={6}>
                                                <RelativeDate
                                                    value={F.recursivePath(this.state.collections, 'lastContact.contactedAt')}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <strong>Method:</strong>
                                            </Col>
                                            <Col md={6}>
                                                {F.recursivePath(this.state.collections, 'lastContact.method', '').toFriendlyCase()}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <strong>Result:</strong>
                                            </Col>
                                            <Col md={6}>
                                                {F.recursivePath(this.state.collections, 'lastContact.result', '').toFriendlyCase()}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <strong>Next follow-up:</strong>
                                            </Col>
                                            <Col md={6}>
                                                <DateText
                                                    value={F.recursivePath(this.state.collections, 'nextFollowUp.followUpDate', null)}/>
                                            </Col>
                                        </Row>
                                    </>
                                    }
                                </Paper>
                                <br />
                                <Paper className={"p-20"}>
                                    <h3>Promises</h3>
                                    <strong>Fulfilment Rate: </strong> <PercentageText value={F.recursivePath(this.state.collections, 'fulfilmentRate', null)} />
                                    <div className={"push-down"}>
                                        {this.state.promises.length === 0 &&
                                        <>
                                            No promises have been made yet.
                                        </>
                                        }
                                        {this.state.promises.length > 0 &&
                                        <>
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Paid</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.promises.map((promise, key) => (
                                                    <tr key={key}>
                                                        <td><CurrencyText value={promise.amount}/></td>
                                                        <td><DateText value={promise.promiseDate}/></td>
                                                        <td><StaticProgress value={promise.percentagePaid}/></td>
                                                        <td>{this.getStatusIcon(promise.status)}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </>
                                        }
                                    </div>
                                </Paper>
                            </Col>
                        </Row>
                        <hr />
                        <div className={"text-center"}>
                            <Button centerRipple variant={"contained"} color={"default"} onClick={() => this.setCollectionsScreen(false)}>Close</Button>
                        </div>
                    </AccessControl>
                </ModalBody>
            </Modal>
        </>;

        const contactWindow = <>
            <Modal
                centered
                isOpen={this.state.showContactWindow}
                fade={true}
                toggle={() => this.setContactWindow(false)}
            >
                <ModalHeader toggle={() => this.setContactWindow(false)}>
                    Record a Contact
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={8}>
                            <h3>{window.getFullName(this.state.client.bio)}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <GenericField
                                name={"method"}
                                type={"dropdown"}
                                list={contactMethods}
                                value={this.state.contactRecord.method}
                                onChange={(e) => this.setContactRecord(e)}
                                label={"Channel"}
                                required={true}
                            />
                        </Col>
                        <Col md={6}>
                            <GenericField
                                name={"contactedAt"}
                                value={this.state.contactRecord.contactedAt}
                                type={"date"}
                                onChangeDate={this.setContactRecordDate}
                                label={"When was this contact made?"}
                            />
                        </Col>
                    </Row>
                    <div>
                        <h4>Were you able to reach the person?</h4>
                        <Button onClick={() => this.setContactRecord({target: {name: 'reachable', value: true}})} color={"primary"}>YES</Button> &nbsp; {this.state.contactRecord.reachable && <Button onClick={() => this.setContactRecord({target: {name: 'reachable', value: false}})} color={"secondary"}>NO</Button>}
                    </div>
                    {this.state.contactRecord.reachable &&
                    <>
                        <Row>
                            <Col md={6}>
                                <GenericField
                                    name={"result"}
                                    type={"dropdown"}
                                    list={contactResultTypes}
                                    value={this.state.contactRecord.result}
                                    onChange={(e) => this.setContactRecord(e)}
                                    label={"What was the result?"}
                                    required={true}
                                />
                            </Col>
                        </Row>
                        {this.state.contactRecord.result === 'promiseToPay' &&
                        <Row>
                            <Col md={6}>
                                <GenericField
                                    name={"promiseAmount"}
                                    type={"text"}
                                    format={"currency"}
                                    value={this.state.contactRecord.promiseAmount}
                                    onChange={(e) => this.setContactRecord(e)}
                                    label={"Promise Amount (minimum)"}
                                    required={true}
                                />
                            </Col>
                            <Col md={6}>
                                <GenericField
                                    name={"promiseDate"}
                                    value={this.state.contactRecord.promiseDate}
                                    type={"date"}
                                    onChangeDate={this.setContactRecordDate}
                                    label={"Promise Date"}
                                />
                            </Col>
                        </Row>
                        }
                        {this.state.contactRecord.result === 'promiseToPay' && this.state.contactRecord.promiseDate &&
                        <>
                            <div>
                                <h4>Would you like to schedule a follow-up regarding this promise?</h4>
                                <Button onClick={() => this.setFollowUp(true)}
                                        color={"primary"}>YES</Button> &nbsp; {this.state.contactRecord.setFollowUp &&
                            <Button onClick={() => this.setFollowUp(false)} color={"secondary"}>NO</Button>}
                            </div>
                            {this.state.contactRecord.result === 'promiseToPay' && this.state.contactRecord.setFollowUp && this.state.contactRecord.promiseDate &&
                            <Row>
                                <Col md={6}>
                                    <GenericField
                                        name={"followUpDate"}
                                        value={this.state.contactRecord.followUpDate}
                                        type={"date"}
                                        onChangeDate={this.setContactRecordDate}
                                        label={"When would you like to follow-up?"}
                                    />
                                </Col>
                            </Row>
                            }
                        </>
                        }
                    </>
                    }
                    <Row>
                        <Col md={12}>
                            <GenericField
                                type={"textarea"}
                                label={"Notes (will be added to comment)"}
                                name={"notes"}
                                value={this.state.contactRecord.notes}
                                onChange={(e) => this.setContactRecord(e)}
                                required={true}
                            />
                        </Col>
                    </Row>

                    <hr />
                    <div className={"text-center"}>
                        <Button centerRipple variant={"contained"} color={"primary"} onClick={() => this.recordContact()}>Record Contact</Button> &nbsp; <Button centerRipple color={"default"} onClick={() => this.setContactWindow(false)}>Close</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>;

        const templates = this.state.templates.filter(item => item.type.toLowerCase() === this.state.message.channel)
            .map(item => {return {...item, label: item.name, value: item._id}});

        const messageWindow = <>
            <Modal
                centered
                isOpen={this.state.showMessageWindow}
                fade={true}
                toggle={() => this.setMessageWindow(false)}
            >
                <ModalHeader toggle={() => this.setMessageWindow(false)}>
                    Send a Message
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={8}>
                            <h3>{window.getFullName(this.state.client.bio)}</h3>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6}>
                            <GenericField
                                name={"channel"}
                                type={"dropdown"}
                                list={messageChannels}
                                value={this.state.message.channel}
                                onChange={(e) => this.setMessage(e)}
                                label={"Channel"}
                                required={true}
                            />
                        </Col>
                    </Row>
                    <br />
                    {this.state.message.channel === 'email' &&
                    <>
                        <Row>
                            <Col md={6}>
                                <GenericField
                                    name={"emailRecipients"}
                                    type={"text"}
                                    value={this.state.message.emailRecipients}
                                    onChange={(e) => this.setMessage(e)}
                                    label={"Recipients"}
                                    required={true}
                                />
                            </Col>
                            <Col md={6}>
                                <GenericField
                                    name={"subject"}
                                    type={"text"}
                                    value={this.state.message.subject}
                                    onChange={(e) => this.setMessage(e)}
                                    label={"Subject"}
                                    required={true}
                                />
                            </Col>
                        </Row>
                    </>
                    }
                    {this.state.message.channel === 'text' &&
                    <>
                        <Row>
                            <Col md={6}>
                                <GenericField
                                    name={"smsRecipients"}
                                    type={"text"}
                                    value={this.state.message.smsRecipients}
                                    onChange={(e) => this.setMessage(e)}
                                    label={"Recipients"}
                                    required={true}
                                />
                            </Col>
                        </Row>
                    </>
                    }
                    <br />
                    {this.state.message.channel &&
                    <>
                        <GenericField
                            name={"templateId"}
                            type={"dropdown"}
                            list={templates}
                            value={this.state.message.templateId}
                            onChange={(e) => this.setMessage(e)}
                            label={"Select a Template"}
                            required={true}
                        />
                    </>
                    }

                    {/*<Row>
                        <Col md={12}>
                            <GenericField
                                name={"body"}
                                type={"textarea"}
                                value={this.state.message.body}
                                onChange={(e) => this.setMessage(e)}
                                label={"Message"}
                                required={true}
                            />
                        </Col>
                    </Row>*/
                    }

                    {this.state.templatePreview &&
                    <>
                        <Paper className={"document-template-preview p-20"}>
                            <div dangerouslySetInnerHTML={{__html: this.state.templatePreview}} className={"document-template-preview-container"}></div>
                        </Paper>
                    </>
                    }

                    <hr />
                    <div className={"text-center"}>
                        <Button centerRipple variant={"contained"} color={"primary"} onClick={() => this.sendMessage(false)}>Send</Button> &nbsp; <Button centerRipple color={"default"} onClick={() => this.setMessageWindow(false)}>Close</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>;

        const batchForm = <>

            <Modal
                centered
                isOpen={this.state.showBatchCreationWindow}
                fade={true}
                toggle={() => this.setBatchCreationWindow(false)}
            >
                <ModalHeader toggle={() => this.setBatchCreationWindow(false)}>
                    New Payment Batch
                </ModalHeader>
                <ModalBody>
                    {/*<br />*/}
                    <p>
                        You are about to create a new payment batch. After creating this batch, you will then add the payments.
                        If you do not have all the payments now, you can come back to this batch as it will be stored in a draft.
                    </p>
                    {/*<br />*/}
                    {/*<br />*/}
                    <p>
                        For ease of reference, we ask you to tag this batch with a label so you can easily identify it.
                    </p>

                    <div>
                        <form onSubmit={(e) => this.createBatch(e)}>


                            <div className="mt-2 mb-2">
                                <label htmlFor="tag_name" className="block text-sm font-medium text-gray-700">
                                    <span className="font-bold">Batch Tag</span> <span className="text-xs text-red-500 text-danger">(required)</span>
                                </label>
                                <div className="mt-1" style={{width: '300px'}}>
                                    <input
                                        id="tag_name"
                                        name="tag_name"
                                        type="text"
                                        // autoComplete="service_name"
                                        required={true}
                                        onChange={(e) => this.changeHandler(e)}
                                        placeholder="enter tag name"
                                        className="rounded-0 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            {/*<label htmlFor='tag'>Tag:</label>*/}

                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    name="tag_name"*/}
                            {/*    id="tag_name"*/}
                            {/*    // value={tag_name}*/}
                            {/*    onChange={(e) => this.changeHandler(e)}*/}
                            {/*    placeholder="enter tag name"*/}
                            {/*/>*/}
                            {/*<br />*/}
                            <br />

                            <div className={"text-left my-1"}>
                                <button
                                    className="btn btn-success rounded-lg"
                                    disabled={tag_name === ''}
                                >Create Batch</button>
                            </div>

                        </form>

                    </div>


                </ModalBody>
            </Modal>

        </>

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        const paymentBatch = <>

            <Modal
                centered
                isOpen={this.state.showBatchPaymentsWindow}
                onClose={(e) => console.log(e)}
                fade={true}
                toggle={() => this.setBatchPaymentsWindow(false)}
                size="xl"
                style={{maxWidth: '1100px', width: '100%'}}
            >
                <ModalHeader toggle={() => this.setBatchPaymentsWindow(false)} style={{padding: '12px 24px'}}>
                    <span className={'text-uppercase text-xl-left text-dark text-150'}>{this.state.currentBatchTag !== '' ? this.state.currentBatchTag : this.state.newBatchTag}</span>
                    {
                        this.state.total > 0 &&
                        <span className={'text-sm-right text-dark text-90'}>
                        Transaction Count: <strong>{this.state.total}</strong>&nbsp;&nbsp;
                        Transactions Total: <strong>{formatter.format(this.state.sumOfPayments)}</strong>
                        </span>
                    }
                </ModalHeader>
                {/*<hr />*/}


                <ModalBody>
                    {/*<br />*/}
                    <div id="payment-batch-header" className='d-inline-flex justify-content-between'>
                        <div className={'justify-content-start'}>
                            <p>
                                Use this screen to edit the payments to be posted in this batch.
                            </p>
                        </div>
                        {/*<div className={'justify-content-end'}>*/}
                        {/*    <span>Number of transactions: <strong>{this.state.total}</strong></span> &nbsp;*/}
                        {/*    <span>Sum of payments: <strong>${this.state.sumOfPayments}</strong></span>*/}
                        {/*</div>*/}

                    </div>
                    {/*<br />*/}
                    {/*<br />*/}


                    <div id="batch-display-container"
                         style={{
                             'display': 'flex',
                             'flexDirection': 'row',
                         }}
                    >


                        <div id="batch-payments" className="batch-payments" style={{overflow: 'scroll', width: '60%', height: '446px', 'border': 'solid', 'borderWidth': '1px', 'borderColor': '#111827'}}>
                            {/*// batch payments table goes here*/}
                            <Fragment>
                                <Table style={{'minWidth': '100%', 'borderTopWidth': '1px', 'borderColor': '#E5E7EB'}} id={'payments-tab'}>
                                    <thead style={{'backgroundColor': '#F9FAFB', 'fontWeight': '800' }}>
                                    <tr>
                                        {/*{this.state.loans.columns.map((column, key) => (*/}
                                        {/*    <th className={"clickable"}*/}
                                        {/*        onClick={() => this.sortLoans(column.fieldName)}>*/}
                                        {/*        {column.label}*/}
                                        {/*        &nbsp;{this.state.sorting.field === column.fieldName &&*/}
                                        {/*    <>*/}
                                        {/*        {this.state.sorting.order === -1 &&*/}
                                        {/*        <i className={"fa fa-caret-down"}></i>*/}
                                        {/*        }*/}
                                        {/*        {this.state.sorting.order === 1 &&*/}
                                        {/*        <i className={"fa fa-caret-up"}></i>*/}
                                        {/*        }*/}
                                        {/*    </>*/}
                                        {/*    }*/}
                                        {/*    </th>*/}
                                        {/*))}*/}
                                        {/*<th>*/}
                                        {/*    &nbsp;*/}
                                        {/*</th>*/}
                                        <th

                                            // scope='col'
                                            // style={{
                                            //     'paddingTop': '0.75rem',
                                            //     'paddingBottom': '0.75rem',
                                            //     'paddingLeft': '1.25rem',
                                            //     'paddingRight': '1.25rem',
                                            //     'color': '#374151',
                                            //     'fontSize': '0.75rem',
                                            //     'lineHeight': '1rem',
                                            //     // 'fontWeight': '500',
                                            //     'fontWeight': '800',
                                            //     'letterSpacing': '0.05em',
                                            //     'textAlign': 'left',
                                            //     'textTransform': 'uppercase'
                                            // }}
                                        >
                                            {/*Status*/}

                                        </th>
                                        <th scope='col'
                                            style={{
                                                'paddingTop': '0.75rem',
                                                'paddingBottom': '0.75rem',
                                                'paddingLeft': '1.25rem',
                                                'paddingRight': '1.25rem',
                                                'color': '#374151',
                                                'fontSize': '0.75rem',
                                                'lineHeight': '1rem',
                                                // 'fontWeight': '500',
                                                'fontWeight': '800',
                                                'letterSpacing': '0.05em',
                                                'textAlign': 'left',
                                                'textTransform': 'uppercase'
                                            }}
                                        >Name</th>

                                        <th scope='col'
                                            style={{
                                                'paddingTop': '0.75rem',
                                                'paddingBottom': '0.75rem',
                                                'paddingLeft': '1.25rem',
                                                'paddingRight': '1.25rem',
                                                'color': '#374151',
                                                'fontSize': '0.75rem',
                                                'lineHeight': '1rem',
                                                // 'fontWeight': '500',
                                                'fontWeight': '800',
                                                'letterSpacing': '0.05em',
                                                'textAlign': 'left',
                                                'textTransform': 'uppercase'
                                            }}
                                        >#</th>

                                        <th scope='col'
                                            style={{
                                                'paddingTop': '0.75rem',
                                                'paddingBottom': '0.75rem',
                                                'paddingLeft': '1.25rem',
                                                'paddingRight': '1.25rem',
                                                'color': '#374151',
                                                'fontSize': '0.75rem',
                                                'lineHeight': '1rem',
                                                // 'fontWeight': '500',
                                                'fontWeight': '800',
                                                'letterSpacing': '0.05em',
                                                'textAlign': 'left',
                                                'textTransform': 'uppercase'
                                            }}
                                        >Amount</th>

                                        <th scope='col'
                                            style={{
                                                'paddingTop': '0.75rem',
                                                'paddingBottom': '0.75rem',
                                                'paddingLeft': '1.25rem',
                                                'paddingRight': '1.25rem',
                                                'color': '#374151',
                                                'fontSize': '0.75rem',
                                                'lineHeight': '1rem',
                                                // 'fontWeight': '500',
                                                'fontWeight': '800',
                                                'letterSpacing': '0.05em',
                                                'textAlign': 'left',
                                                'textTransform': 'uppercase'
                                            }}
                                        >Method</th>

                                        <th scope='col'
                                            style={{
                                                'paddingTop': '0.75rem',
                                                'paddingBottom': '0.75rem',
                                                'paddingLeft': '1.25rem',
                                                'paddingRight': '1.25rem',
                                                'color': '#374151',
                                                'fontSize': '0.75rem',
                                                'lineHeight': '1rem',
                                                // 'fontWeight': '500',
                                                'fontWeight': '800',
                                                'letterSpacing': '0.05em',
                                                'textAlign': 'left',
                                                'textTransform': 'uppercase'
                                            }}
                                        >Date</th>

                                        {
                                            currentBatchStatus === ('draft' || '') &&
                                            <th scope='col'
                                                style={{
                                                    'paddingTop': '0.75rem',
                                                    'paddingBottom': '0.75rem',
                                                    'paddingLeft': '1.25rem',
                                                    'paddingRight': '1.25rem',
                                                    'color': '#374151',
                                                    'fontSize': '0.75rem',
                                                    'lineHeight': '1rem',
                                                    // 'fontWeight': '500',
                                                    'fontWeight': '800',
                                                    'letterSpacing': '0.05em',
                                                    'textAlign': 'left',
                                                    'textTransform': 'uppercase'
                                                }}
                                            >Actions</th>
                                        }

                                    </tr>
                                    </thead>
                                    <tbody id={'payments-tbody'}>

                                    {
                                        (this.state.batchPayments || []).map((payment) => {
                                            let index = this.state.batchPayments.indexOf(payment)

                                            const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
                                                year:  'numeric',
                                                month: 'long',
                                                day:   'numeric',
                                            });

                                            const creationDate = new Date(payment.date)
                                            // console.log(creationDate)
                                            const year = creationDate.getFullYear()
                                            const month = creationDate.getMonth()
                                            const day = creationDate.getDate()

                                            // console.log(year, month, day)

                                            if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {

                                            const date = new Date (year, month, day)
                                            payment.date = longEnUSFormatter.format(date)
                                            }

                                            return (
                                                <tr key={index} style={{'flexDirection': 'row'}}>
                                                    <td
                                                        className={'table-cell'}
                                                        style={{'paddingLeft': '0.25rem', 'paddingRight': '0.25rem', 'align': 'center' }}
                                                    >
                                                        &nbsp;
                                                        {/*<input type="checkbox"/>*/}
                                                    </td>
                                                    <td style={{
                                                        'paddingLeft': '0.25rem',
                                                        'paddingRight': '0.25rem',
                                                        'paddingTop': '1rem',
                                                        'paddingBottom': '1rem',
                                                        'color': '#374151',
                                                        'fontSize': '0.875rem',
                                                        'lineHeight': '1.25rem',
                                                        'fontWeight': '500',
                                                        'whiteSpace': 'nowrap'
                                                    }}
                                                    >{payment.name || 'Missing Name'}</td>
                                                    <td
                                                        style={{
                                                            'paddingLeft': '0.25rem',
                                                            'paddingRight': '0.25rem',
                                                            'paddingTop': '1rem',
                                                            'paddingBottom': '1rem',
                                                            'color': '#374151',
                                                            'fontSize': '0.875rem',
                                                            'lineHeight': '1.25rem',
                                                            'fontWeight': '500',
                                                            'whiteSpace': 'nowrap'
                                                        }}
                                                    >{payment.loanId}</td>
                                                    <td
                                                        style={{
                                                            'paddingLeft': '0.25rem',
                                                            'paddingRight': '0.25rem',
                                                            'paddingTop': '1rem',
                                                            'paddingBottom': '1rem',
                                                            'color': '#374151',
                                                            'fontSize': '0.875rem',
                                                            'lineHeight': '1.25rem',
                                                            'fontWeight': '500',
                                                            'whiteSpace': 'nowrap'
                                                        }}
                                                    >{formatter.format(payment.amount)}</td>
                                                    <td
                                                        style={{
                                                            'paddingLeft': '0.25rem',
                                                            'paddingRight': '0.25rem',
                                                            'paddingTop': '1rem',
                                                            'paddingBottom': '1rem',
                                                            'color': '#374151',
                                                            'fontSize': '0.875rem',
                                                            'lineHeight': '1.25rem',
                                                            'fontWeight': '500',
                                                            'whiteSpace': 'nowrap'
                                                        }}
                                                    >{payment.method}</td>
                                                    <td
                                                        style={{
                                                            'paddingLeft': '0.25rem',
                                                            'paddingRight': '0.25rem',
                                                            'paddingTop': '1rem',
                                                            'paddingBottom': '1rem',
                                                            'color': '#374151',
                                                            'fontSize': '0.875rem',
                                                            'lineHeight': '1.25rem',
                                                            'fontWeight': '500',
                                                            'whiteSpace': 'nowrap'
                                                        }}
                                                    >{payment.date}</td>
                                                    {
                                                        currentBatchStatus === ('draft' || '') &&
                                                        <td
                                                            style={{
                                                                'paddingLeft': '0.25rem',
                                                                'paddingRight': '0.25rem',
                                                                'paddingTop': '1rem',
                                                                'paddingBottom': '1rem',
                                                                'color': '#374151',
                                                                'fontSize': '0.875rem',
                                                                'lineHeight': '1.25rem',
                                                                'fontWeight': '500',
                                                                'whiteSpace': 'nowrap'
                                                            }}
                                                        ><a
                                                            id={`delete-${index}`}
                                                            href="#"
                                                            style={{
                                                                color: '#1D4ED8',
                                                                textDecoration: 'underline'
                                                            }}
                                                            // className="underline text-blue-700"
                                                            onClick={(e) => this.deletePayment(e)}
                                                        >
                                                            delete
                                                        </a></td>
                                                    }

                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>

                                </Table>
                                {/*<hr/>*/}
                                {/*<div className={"text-center push-down block"}>*/}
                                {/*    <Paging*/}
                                {/*        query={this.state.query}*/}
                                {/*        onChange={() => this.loadLoans()}*/}
                                {/*        parentStateHandler={this.onChangePaging}*/}
                                {/*        parentState={this.state}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </Fragment>
                        </div>

                        <div id="batch-docs" style={{'display': 'flex', 'marginLeft': '0.5rem', 'flexDirection': 'column', 'width': '40%', 'height': '100%', 'minHeight': '100%'}}>

                            <div id="batch-spreadsheet-upload"
                                 style={{
                                     'display': 'block',
                                     'padding': '0.5rem',
                                     'justifyContent': 'flex-start',
                                     'height': '112.8px',

                                     'border': 'solid',
                                     'borderWidth': '1px',
                                     'borderColor': '#111827'
                                 }}
                            >
                                {/*    accept speadsheet upload here*/}
                                <p style={{'paddingTop': '0.25rem', 'paddingBottom': '0.25rem', 'color': '#374151', 'fontSize': '0.875rem', 'lineHeight': '1.25rem' }}>Upload a spreadsheet:</p>
                                {/*<br/>*/}

                                <div
                                    // className='inline-flex w-full'
                                    style={{'display': 'inline-flex', 'width': '100%' }}>
                                    <div className='w-2/3' style={{'width': '50%'}}>

                                        {
                                            this.state.files.length < 1 ?

                                                <div className='w-full' style={{'width': '100%'}}>
                                                    <button
                                                        // className="rounded-md border border-gray-300 shadow-sm m-2 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-400 active:bg-gray-300 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                                                        className={"btn btn-lg rounded rounded-lg btn-outline-primary"}
                                                        disabled={currentBatchStatus !== ('draft' || '')}
                                                        style={{
                                                            // display:'none',
                                                            'paddingTop': '0.5rem',
                                                            'paddingBottom': '0.5rem',
                                                            'paddingLeft': '1rem',
                                                            'paddingRight': '1rem',
                                                            'margin': '0.5rem',
                                                            // 'backgroundColor': '#ffffff',
                                                            // 'color': '#374151',
                                                            'fontSize': '1rem',
                                                            'lineHeight': '1.5rem',
                                                            'fontWeight': '500',
                                                            'borderRadius': '0.375rem',
                                                            'borderWidth': '1px',
                                                            // 'borderColor': '#D1D5DB',
                                                            'boxShadow': '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                                                        //     ':hover': {
                                                        //         'backgroundColor': '#9CA3AF'
                                                        // },
                                                        //     ':active': {
                                                        //         'backgroundColor': '#D1D5DB'
                                                        // },
                                                        //     '@media (min-width: 640px)': {
                                                        //     'marginTop': '0',
                                                        //     'fontSize': '0.875rem',
                                                        //     'lineHeight': '1.25rem',
                                                        //     'width': 'auto',
                                                        // }

                                                        }}
                                                        onClick={(e) => {
                                                            // console.log(e)
                                                            document.getElementById('file-upload').click()
                                                            // console.log(document.getElementById('file-upload'))
                                                        }}
                                                    >
                                                        Select file
                                                    </button>
                                                </div>
                                                : // else
                                                <div>
                                                    <div
                                                        // className='inline-flex h-10'
                                                         style={{display: 'inline-flex', height: '2.5rem'}}
                                                    >
                                                        <div id="sheet-submit" className='w-1/2 text-center' style={{width: '50%'}}>
                                                            <button
                                                                // className="btn btn-success rounded-md border border-green-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-green-400 active:bg-green-300 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                                                                className="btn btn-xl rounded rounded-lg btn-outline-success"
                                                                style={{
                                                                    paddingTop: '0.5rem',
                                                                    paddingBottom: '0.5rem',
                                                                    paddingLeft: '1rem',
                                                                    paddingRight: '1rem',
                                                                    // backgroundColor: '#ffffff',
                                                                    // color: '#374151',
                                                                    fontSize: '1rem',
                                                                    lineHeight: '1.5rem',
                                                                    fontWeight: '500',
                                                                    borderRadius: '0.375rem',
                                                                    borderWidth: '1px',
                                                                    // borderColor: '#6EE7B7',
                                                                    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                                                                }}
                                                                onClick={(e) => this.getData(e)}
                                                                disabled={!spreadsheetFormats.includes(`${this.state.fileType}`)}
                                                                // disabled={this.state.disableSubmit}

                                                            >
                                                                Submit
                                                            </button>
                                                        </div>&nbsp;

                                                        <div className='w-1/2 text-center' style={{width: '50%'}}>
                                                            <button
                                                                // className="btn btn-info rounded-md border border-blue-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-blue-400 active:bg-blue-300 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                                                                className="btn btn-xl rounded rounded-lg btn-outline-primary"
                                                                style={{
                                                                    marginLeft: '15px',
                                                                    paddingTop: '0.5rem',
                                                                    paddingBottom: '0.5rem',
                                                                    paddingLeft: '1rem',
                                                                    paddingRight: '1rem',
                                                                    // backgroundColor: '#ffffff',
                                                                    // color: '#374151',
                                                                    fontSize: '1rem',
                                                                    lineHeight: '1.5rem',
                                                                    fontWeight: '500',
                                                                    borderRadius: '0.375rem',
                                                                    borderWidth: '1px',
                                                                    // borderColor: '#93C5FD',
                                                                    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                                                                }}
                                                                onClick={() => {
                                                                    this.setState({files: [], fileName: '', fileType: ''})
                                                                    document.getElementById('file-upload').click()
                                                                }}
                                                            >
                                                                Change
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                        }


                                    </div>
                                    <div
                                        // className='w-1/3 overflow-hidden ml-1 text-xs text-gray-700 font-medium'
                                         style={{
                                             'wordWrap': 'break-word',
                                             'overflow': 'hidden',
                                             'marginLeft': '0.25rem',
                                             'color': '#374151',
                                             'fontSize': '0.75rem',
                                             'lineHeight': '1rem',
                                             'fontWeight': '500',
                                             'width': '50%',
                                             'align': 'center'
                                         }}>
                                        <div style={{wordWrap: 'break-word'}}>
                                        {/*{fileName ? fileName : ''}*/}
                                            <span className='inline-flex ml-1 text-xs text-gray-700 font-medium'>{this.state.fileName ? this.state.fileName : ''}</span>
                                        </div>
                                    </div>
                                </div>
                                {/*<label className="custom-file-upload">*/}
                                   <form id="form-sheet" encType={'multipart/form-data'}>
                                    <input id="file-upload" type="file" name="spreadsheet" className={"hidden"} onChange={(e) => this.fileUploadHandler(e)}

                                           // onChange={() => this.onChange()}
                                    />
                                   </form>
                                    {/*Select file*/}
                                {/*</label>*/}

                            </div>
                            <div id="batch-loans-search"
                                 style={{
                                     'overflow': 'scroll',
                                     'padding': '0.5rem',
                                     'marginTop': '2.5rem',
                                     // 'height': '60%',
                                     'height': '293.2px',
                                     'border': 'solid',
                                     'borderWidth': '1px',
                                     'borderColor': '#111827'

                                 }}
                            >

                                {/*    search component for loans go here*/}
                                <div>
                                    <p style={{
                                        paddingTop: '0.25rem',
                                        paddingBottom: '0.25rem',
                                        color: '#374151',
                                        fontSize: '0.875rem',
                                        lineHeight: '1.25rem',
                                    }}
                                    >Search loans to add:</p>
                                    <input
                                        type="text"
                                        style={{
                                            color: '#37415',
                                            fontSize: '0.875rem',
                                            lineHeight: '1.25rem',
                                            height: '1.5rem'

                                        }}
                                        id="search-field"
                                        name="search_query"
                                        value={this.state.search.keywords}
                                        disabled={currentBatchStatus !== ('draft' || '')}
                                        placeholder={"Search clients"}
                                        onChange={(e) => this.keywords(e)}
                                        // value={search_query}
                                        // onChange={(e) => this.getSearchResults(e)}
                                    />

                                </div>
                                <div className="mt-2">
                                    {
                                        this.state.searchResults.length < 1 &&
                                        <></>
                                    }

                                    {
                                        this.state.searchResults.length > 0 &&
                                        <>
                                            <div>
                                                <table>
                                                    <thead>
                                                    <tr
                                                        // className="h-0"
                                                        style={{height: '0'}}
                                                    >
                                                        <th
                                                            scope="col"
                                                            // className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
                                                            style={{
                                                                paddingTop: '0.25rem',
                                                                paddingBottom: '0.25rem',
                                                                paddingLeft: '0.25rem',
                                                                paddingRight: '0.25rem',
                                                                color: '#374151',
                                                                fontSize: '0.75rem',
                                                                lineHeight: '1rem',
                                                                // fontWeight: '500',
                                                                fontWeight: '800',
                                                                letterSpacing: '0.05em',
                                                                textAlign: 'left',
                                                                textTransform: 'uppercase',
                                                            }}
                                                        >&nbsp;</th>
                                                        <th
                                                            scope="col"
                                                            // className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
                                                            style={{
                                                                paddingTop: '0.25rem',
                                                                paddingBottom: '0.25rem',
                                                                paddingLeft: '0.75rem',
                                                                paddingRight: '0.75rem',
                                                                color: '#374151',
                                                                fontSize: '0.75rem',
                                                                lineHeight: '1rem',
                                                                // fontWeight: '500',
                                                                fontWeight: '800',
                                                                letterSpacing: '0.05em',
                                                                textAlign: 'center',
                                                                textTransform: 'uppercase',
                                                            }}
                                                        >Name <span style={{textTransform: 'lowercase'}}>(days late)</span></th>
                                                        <th
                                                            scope="col"
                                                            // className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
                                                            style={{
                                                                paddingTop: '0.25rem',
                                                                paddingBottom: '0.25rem',
                                                                paddingLeft: '0.75rem',
                                                                paddingRight: '0.75rem',
                                                                color: '#374151',
                                                                fontSize: '0.75rem',
                                                                lineHeight: '1rem',
                                                                // fontWeight: '500',
                                                                fontWeight: '800',
                                                                letterSpacing: '0.05em',
                                                                textAlign: 'center',
                                                                textTransform: 'uppercase',
                                                            }}
                                                        >Total Due</th>
                                                        {/*<th*/}
                                                        {/*    scope="col"*/}
                                                        {/*    className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"*/}
                                                        {/*>&nbsp;&nbsp;</th>*/}
                                                        <th
                                                            scope="col"
                                                            // className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
                                                            className="text-left"
                                                            style={{
                                                                paddingTop: '0.25rem',
                                                                paddingBottom: '0.25rem',
                                                                paddingLeft: '0.75rem',
                                                                paddingRight: '1rem',
                                                                color: '#374151',
                                                                fontSize: '0.75rem',
                                                                lineHeight: '1rem',
                                                                // fontWeight: '500',
                                                                fontWeight: '800',
                                                                letterSpacing: '0.05em',
                                                                textAlign: 'center',
                                                                textTransform: 'uppercase',
                                                            }}
                                                        >Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {
                                                        this.state.searchResults.map((entry) => {
                                                            let index = this.state.searchResults.indexOf(entry) + 1
                                                            let randomNumber = Math.floor(Math.random() * 10)

                                                            return (


                                                                <tr key={index}>
                                                                    <td
                                                                        // className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700"
                                                                        style={{
                                                                            paddingTop: '0.5rem',
                                                                            paddingBottom: '0.5rem',
                                                                            paddingLeft: '0.5rem',
                                                                            paddingRight: '0.5rem',
                                                                            color: '#374151',
                                                                            fontSize: '0.75rem',
                                                                            lineHeight: '1rem',
                                                                            fontWeight: '500',
                                                                            textAlign: 'center',
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >{entry.status === 'current' ?
                                                                        <i className="fa fa-circle" style={{color: 'green'}}></i> :
                                                                        <i className="fa fa-circle" style={{color: 'red'}}></i>}
                                                                    </td>
                                                                    <td
                                                                        // className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700"
                                                                        style={{
                                                                            paddingTop: '0.5rem',
                                                                            paddingBottom: '0.5rem',
                                                                            paddingLeft: '0.5rem',
                                                                            paddingRight: '0.5rem',
                                                                            color: '#374151',
                                                                            fontSize: '0.75rem',
                                                                            lineHeight: '1rem',
                                                                            fontWeight: '500',
                                                                            textAlign: 'left',
                                                                            whiteSpace: 'nowrap',
                                                                            textTransform: 'uppercase',
                                                                        }}
                                                                    >{entry.client.bio.firstName}&nbsp;{entry.client.bio.lastName}&nbsp;{entry.lateDays !== 0 ? `(${entry.lateDays})` : ''}</td>
                                                                    {/*<td className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700">{entry.lateDays}</td>*/}
                                                                    <td
                                                                        // className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700"
                                                                        style={{
                                                                            paddingTop: '0.5rem',
                                                                            paddingBottom: '0.5rem',
                                                                            paddingLeft: '0.5rem',
                                                                            paddingRight: '0.5rem',
                                                                            color: '#374151',
                                                                            fontSize: '0.75rem',
                                                                            lineHeight: '1rem',
                                                                            fontWeight: '500',
                                                                            textAlign: 'center',
                                                                            whiteSpace: 'nowrap',
                                                                            textTransform: 'uppercase',
                                                                        }}
                                                                    >{formatter.format(entry.due.total)}</td>
                                                                    <td><a href="#"
                                                                           id={`add-${index - 1}`}
                                                                           // className="px-2 py-2 whitespace-nowrap text-xs text-center font-medium underline text-blue-700"
                                                                           style={{
                                                                               paddingTop: '0.5rem',
                                                                               paddingBottom: '0.5rem',
                                                                               paddingLeft: '0.5rem',
                                                                               paddingRight: '0.5rem',
                                                                               color: 'blue',
                                                                               textDecoration: 'underline',
                                                                               fontSize: '0.75rem',
                                                                               lineHeight: '1rem',
                                                                               fontWeight: '500',
                                                                               textAlign: 'center',
                                                                               whiteSpace: 'nowrap',
                                                                           }}
                                                                           onClick={(e) => this.loadNewPayment(e)}
                                                                    >Add</a></td>
                                                                </tr>
                                                            )


                                                            //     {/*<span className="px-1 py-4 whitespace-nowrap text-sm font-medium text-gray-700"*/}
                                                            //     {/*      key={index}*/}
                                                            //     {/*      id={`add-loan${index}`}*/}
                                                            //     {/*>*/}
                                                            //     {/*    {entry.name}&nbsp;*/}
                                                            //     {/*    {entry.daysOverdue}&nbsp;*/}
                                                            //     {/*    {entry.amountDue}&nbsp;*/}
                                                            //     {/*    <a*/}
                                                            //     {/*        href="#"*/}
                                                            //     {/*        // onClick={addToBatch}*/}
                                                            //     {/*        className="underline text-blue-700"*/}
                                                            //     {/*    >*/}
                                                            //     {/*        Add*/}
                                                            //     {/*    </a>*/}
                                                            //     {/*</span>*/}
                                                            // )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </>


                                    }

                                    {/*{*/}
                                    {/*    this.state.searchResults.length > 0 &&*/}
                                    {/*    <>*/}
                                    {/*        <div>*/}
                                    {/*            {*/}
                                    {/*                this.state.searchResults.map((entry) => {*/}
                                    {/*                    let index = this.state.searchResults.indexOf(entry) + 1*/}

                                    {/*                    return (*/}
                                    {/*                        <span className={'clickable'}*/}
                                    {/*                              key={index}*/}
                                    {/*                              id={`add-loan${index}`}*/}
                                    {/*                        >*/}
                                    {/*                        {entry.bio.firstName} {entry.bio.lastName}&nbsp;*/}
                                    {/*                            {entry.status}&nbsp;*/}
                                    {/*                            /!*{entry.amountDue}&nbsp;*!/*/}
                                    {/*                            <Button*/}
                                    {/*                                // onClick={addLoan}*/}
                                    {/*                            >Add</Button>*/}
                                    {/*                    </span>*/}
                                    {/*                    )*/}
                                    {/*                })*/}
                                    {/*            }*/}
                                    {/*        </div>*/}

                                    {/*    </>*/}


                                    {/*}*/}
                                </div>

                            </div>

                        </div>
                    </div>
                    {/*<br />*/}

                    <div id="batch-pagination" className="mt-2 bb-3 text-center" style={{width: '60%', maxHeight: '21px',  marginRight: 'auto'}}>
                        {/*    pagination controls go here*/}
                        {/*<div className={"text-center push-down block"}>*/}
                        {/*    <Paging*/}
                        {/*        // query={this.state.payments_query}*/}
                        {/*        // onChange={() => this.loadPayments()}*/}
                        {/*        // parentStateHandler={this.onChangePaging}*/}
                        {/*        // parentState={this.state}*/}
                        {/*    />*/}
                        {/*</div>*/}


                        {/*<div className={"my-3"}>*/}
                        {/*    <span>***** Pagination Div *****</span>*/}


                        <span>


                            <button onClick={() => {
                                // this.setState({payments_query: {...this.state.payments_query, page: 1}})
                                this.skipToPage(0).then(r => {})
                                // this.loadPayments().then(r => {})
                            }} style={{backgroundColor: 'inherit', border: 'none'}} disabled={!this.state.previous}><i className="fa fa-angle-double-left"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={() => {
                                this.skipToPage(- 1).then(r => {})

                                // this.setState({payments_query: {page: this.state.payments_query.page - 1}})
                                // console.log(this.state.payments_query.page)
                                //
                                // this.loadPayments().then(r => {})
                            }} style={{backgroundColor: 'inherit', border: 'none'}} disabled={!this.state.previous}><i className="fa fa-angle-left"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <span> <strong>{this.state.start}</strong> to <strong>{this.state.end}</strong> of <strong>{this.state.total}</strong> </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <button onClick={() => {
                                this.skipToPage(1).then(r => {})
                            }} style={{backgroundColor: 'inherit', border: 'none'}} disabled={!this.state["next"]}><i className="fa fa-angle-right"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={() => {
                                // this.setState({payments_query: {...this.state.payments_query, page: this.state.pageCount}})
                                this.skipToPage(999).then(r => {})
                                // this.loadPayments().then(r => {})
                            }} style={{backgroundColor: 'inherit', border: 'none'}} disabled={!this.state["next"]}><i className="fa fa-angle-double-right"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        </span>

                        {/*</div>*/}
                    </div>

                    <div id="button-container" className="inline mt-3">
                        {
                            currentBatchStatus === ('draft' || '') &&
                            <button
                                onClick={() => this.submitBatch()}
                                className="btn btn-success rounded"
                                disabled={this.state.batchPayments.length < 1}
                            >Submit Batch</button>
                        }


                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className="btn btn-info rounded"*/}
                        {/*    onClick={() => this.saveDraft()}*/}
                        {/*>Save Draft</button>*/}

                        <button
                            className="btn btn-secondary rounded"
                            onClick={() => this.setState({batchPayments: [], showBatchPaymentsWindow: false})}
                        >Cancel</button>
                    </div>

                </ModalBody>
            </Modal>

        </>

        const paymentReview = <>

            <Modal
                centered
                isOpen={this.state.showPaymentReviewWindow}
                fade={true}
                toggle={() => this.setPaymentReviewWindow(false)}
                size="md"
                style={{maxWidth: '550px', width: '100%'}}
            >
                <ModalHeader toggle={() => this.setPaymentReviewWindow(false)}>
                    Review Payment
                </ModalHeader>
                <ModalBody>
                    {/*<br />*/}
                    <p>
                        Adding payment for {`${((this.state.paymentInfo.client || {}).bio || {}).firstName} ${((this.state.paymentInfo.client || {}).bio || {}).lastName} (${((this.state.paymentInfo.client || {}).bio || {}).trn})`}.<br /><br /> The total due is <strong>{formatter.format((this.state.paymentInfo.due || {}).total || 0)}</strong>, which you may add as the payment amount by selecting the 'Use Total Due' option beside the amount field.
                    </p>
                    {/*<br />*/}

                    <div>
                        <form style={{width: '100%'}}>
                            <div className="block"
                                 style={{
                                     'display': 'block',
                                     'padding': '0.5rem',
                                     // 'justifyContent': 'flex-start',
                                     // 'height': '40%',
                                     // 'border': 'solid',
                                     'borderWidth': '1px',
                                     'borderColor': '#111827',
                                     // 'width': '50%'
                                 }}
                            >
                                <div className="mt-2 mb-2">
                                    <label htmlFor="payment_amount" className="block text-sm font-medium text-gray-700">
                                        <span className="font-bold">Payment Amount</span> <span className="text-xs text-red-500 text-danger">(required)</span>
                                    </label>
                                    <div className="mt-3 flex inline-flex">
                                        {/*<div className="block" style={{width: '60%'}}>*/}
                                        <input
                                            id="payment_amount"
                                            name="payment_amount"
                                            type="number"
                                            required={true}
                                            min="1"
                                            step="any"
                                            style={{width: '358.06px'}}
                                            // value={this.state.payment_amount}
                                            // defaultValue={(this.state.paymentInfo.due || {}).total}
                                            // defaultValue={(this.state.paymentInfo.due || {}).total || ''}
                                            onChange={(e) => this.handleChange(e)}
                                            placeholder="enter payment amount"
                                            className="appearance-none w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                                        />
                                        {/*</div>*/}
                                        {/*<div className="block" style={{width: '40%'}}>*/}
                                        <button
                                                style={{margin: '0px 0px 0px 8px'}}
                                                className={'btn btn-sm btn-info rounded-3 ml-2'}
                                                onClick={(e) => this.setTotalDue(e)}
                                        >Use total due
                                        </button>
                                        {/*</div>*/}

                                    </div>
                                </div>

                                <div className="mt-3 mb-2">
                                    <label htmlFor="payment_method" className="block text-sm font-medium text-gray-700 mt-2">
                                        <span className="font-bold">Payment Method</span> <span className="text-xs text-red-500 text-danger">(required)</span>
                                    </label>
                                    <div style={{width: '358.06px', margin: '0'}}>
                                        <GenericField
                                            name={"payment_method"}
                                            type={"dropdown"}
                                            list={paymentMethods}
                                            value={this.state.payment_method}
                                            onChange={(e) => this.handleChange(e)}
                                            hideLabel
                                            required={false}
                                        />
                                    </div>

                                </div>

                                <div className="mt-3 mb-2">
                                    <label htmlFor="payment_date" className="block text-sm font-medium text-gray-700 mt-2">
                                        <span className="font-bold">Date of Payment</span>
                                        {/*<span className="text-xs text-red-500 text-danger">(required)</span>*/}
                                    </label>
                                    <div className="mt-3">
                                        <input
                                            id="payment_date"
                                            name="payment_date"
                                            type="date"
                                            // autoComplete="service_name"
                                            required={false}
                                            style={{width: '358.06px'}}
                                            // value={this.state.payment_date}
                                            defaultValue={"" + (new Date).getFullYear() + "-" + `${((new Date).getMonth() + 1) < 10 ? ("0" + ((new Date).getMonth() + 1)) : ((new Date).getMonth() + 1) }` + "-" +(new Date).getDate() + ""}
                                            // onChange={this.changeHandler}
                                            onChange={(e) => this.handleChange(e)}
                                            // placeholder="enter payment method"
                                            className="appearance-none w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                            </div>
                            <br />
                            <br />

                            <div className={"text-left"}>
                                {/*<Button*/}
                                {/*        disabled={!this.state.hasDateRange} centerRipple variant={"contained"} color={"primary"} onClick={() => {*/}
                                {/*        this.loadLoans();*/}
                                {/*        this.setDateSelectionWindow(false);*/}
                                {/*    }}*/}
                                {/*>Create Batch</Button>*/}
                                <button
                                    type={'submit'}
                                    className={'btn btn-lg btn-outline-success rounded-1'}
                                    onClick={(e) => this.addPayment(e)}
                                >Add Payment</button>
                                <button
                                    className={'btn btn-lg btn-outline-dark rounded-1'}
                                    onClick={(e) => this.resetAmount(e)}
                                    // onClick={resetAmount}

                                >Reset Amount</button>
                                <button
                                    className={'btn btn-lg btn-outline-danger rounded-1'}
                                    onClick={() => {
                                        this.setState({payment_amount: ''})
                                        this.setState({payment_date: ''})
                                        this.setState({showPaymentReviewWindow: false})
                                    }}
                                >Cancel</button>
                            </div>

                        </form>

                    </div>


                </ModalBody>
            </Modal>

        </>


        // const paymentsBatch = <Transition.Root show={this.state.showBatchPaymentsWindow} as={Fragment}>
        //     <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={null} onClose={() => this.setState ({showBatchPaymentsWindow: false})}>
        //         <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        //             <Transition.Child
        //                 as={Fragment}
        //                 enter="ease-out duration-300"
        //                 enterFrom="opacity-0"
        //                 enterTo="opacity-100"
        //                 leave="ease-in duration-200"
        //                 leaveFrom="opacity-100"
        //                 leaveTo="opacity-0"
        //             >
        //                 <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        //             </Transition.Child>
        //
        //             {/* This element is to trick the browser into centering the modal contents. */}
        //             <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        //     &#8203;
        //   </span>
        //             <Transition.Child
        //                 as={Fragment}
        //                 enter="ease-out duration-300"
        //                 enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        //                 enterTo="opacity-100 translate-y-0 sm:scale-100"
        //                 leave="ease-in duration-200"
        //                 leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        //                 leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        //             >

        //                 <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full lg:max-w-4xl lg:w-full sm:p-6">
        //                     <div id="payment-batch">
        //                         <>
        //                             <div>
        //                                 <h3 className="text-xl left p-0">Payment Batch</h3>
        //                             </div>
        //                             <hr />
        //                             {/*<ModalBody>*/}
        //                             <br />
        //                             <p>
        //                                 Use this screen to edit the payments to be posted in this batch
        //                             </p>
        //                             <br />
        //                             {/*<br />*/}
        //
        //
        //                             <div id="batch-display-container" className="flex flex-row">
        //
        //                                 <div id="batch-payments" className="batch-payments container h-3/5 w-2/3 overflow-scroll">
        //                                     {/*// batch payments table goes here*/}
        //                                     <Fragment>
        //                                         <table className="min-w-full divide-y divide-gray-200">
        //                                             <thead className="bg-gray-50 font-extrabold">
        //                                             <tr>
        //                                                 {/*{this.state.loans.columns.map((column, key) => (*/}
        //                                                 {/*    <th className={"clickable"}*/}
        //                                                 {/*        onClick={() => this.sortLoans(column.fieldName)}>*/}
        //                                                 {/*        {column.label}*/}
        //                                                 {/*        &nbsp;{this.state.sorting.field === column.fieldName &&*/}
        //                                                 {/*    <>*/}
        //                                                 {/*        {this.state.sorting.order === -1 &&*/}
        //                                                 {/*        <i className={"fa fa-caret-down"}></i>*/}
        //                                                 {/*        }*/}
        //                                                 {/*        {this.state.sorting.order === 1 &&*/}
        //                                                 {/*        <i className={"fa fa-caret-up"}></i>*/}
        //                                                 {/*        }*/}
        //                                                 {/*    </>*/}
        //                                                 {/*    }*/}
        //                                                 {/*    </th>*/}
        //                                                 {/*))}*/}
        //                                                 {/*<th>*/}
        //                                                 {/*    &nbsp;*/}
        //                                                 {/*</th>*/}
        //                                                 <th>
        //
        //                                                 </th>
        //                                                 <th
        //                                                     scope="col"
        //                                                     className="px-5 py-3 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                 >Name</th>
        //
        //                                                 <th
        //                                                     scope="col"
        //                                                     className="px-5 py-3 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                 >Amount</th>
        //
        //                                                 <th
        //                                                     scope="col"
        //                                                     className="px-5 py-3 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                 >Method</th>
        //
        //                                                 <th
        //                                                     scope="col"
        //                                                     className="px-5 py-3 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                 >Date</th>
        //
        //                                                 <th
        //                                                     scope="col"
        //                                                     className="px-5 py-3 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                 >Actions</th>
        //                                             </tr>
        //                                             </thead>
        //                                             <tbody>
        //                                             {/*{this.state.newBatches*/}
        //                                             {/*    .map((record, key) => (*/}
        //                                             {/*        <tr key={key}>*/}
        //                                             {/*            {this.state.loans.columns.map((column, key) => (*/}
        //                                             {/*                <td*/}
        //                                             {/*                    className={"clickable"}*/}
        //                                             {/*                    onClick={() => this.loadCollectionsData(record._id)}*/}
        //                                             {/*                >*/}
        //                                             {/*                    <CellValue record={record} column={column} />*/}
        //                                             {/*                </td>*/}
        //                                             {/*            ))}*/}
        //                                             {/*            <td className={"text-center"}>*/}
        //                                             {/*                <a href={`/app/loans/${record._id}/!/schedule`} target={'_blank'}>*/}
        //                                             {/*                    <i className={'fa fa-external-link-alt'} />*/}
        //                                             {/*                </a>&nbsp;&nbsp;*/}
        //                                             {/*            </td>*/}
        //                                             {/*        </tr>*/}
        //                                             {/*    ))}*/}
        //                                             {
        //                                                 this.state.newBatches.map((batch) => {
        //                                                     let index = this.state.newBatches.indexOf(batch)
        //
        //                                                     return (
        //                                                         <tr className="flex-row" key={index}>
        //                                                             <td className="px-1"><input type="checkbox"/></td>
        //                                                             <td className="px-1 py-4 whitespace-nowrap text-sm font-medium text-gray-700">{batch.tag_name}</td>
        //                                                             <td className="px-1 py-4 whitespace-nowrap text-sm text-center text-gray-500">{batch.amount}</td>
        //                                                             <td className="flex flex-row px-1 py-4 whitespace-nowrap text-sm text-gray-500">{batch.method}</td>
        //                                                             <td className="px-1 py-4 whitespace-nowrap text-sm text-center text-gray-500">{batch.date}</td>
        //                                                             {/*<td className="px-1 py-4 whitespace-nowrap text-sm text-center text-gray-500">{batch.status}</td>*/}
        //                                                             <td className="px-1 py-4 whitespace-nowrap text-sm text-center text-gray-500">
        //                                                                 <a
        //                                                                     id={`batch-${index}`}
        //                                                                     href="#"
        //                                                                     className="underline text-blue-700"
        //                                                                     // onClick={deletePayment}
        //                                                                 >
        //                                                                     delete
        //                                                                 </a>
        //                                                             </td>
        //                                                         </tr>
        //                                                     )
        //                                                 })
        //                                             }
        //                                             </tbody>
        //                                             {/*<TotalRows columns={this.state.loans.columns}*/}
        //                                             {/*           totalRows={this.state.totalRows} />*/}
        //                                         </table>
        //                                         <hr/>
        //                                         <div className={"text-center push-down block"}>
        //                                             {/*<Paging*/}
        //                                             {/*    query={this.state.query}*/}
        //                                             {/*    onChange={() => this.loadLoans()}*/}
        //                                             {/*    parentStateHandler={this.onChangePaging}*/}
        //                                             {/*    parentState={this.state}*/}
        //                                             {/*/>*/}
        //                                         </div>
        //                                     </Fragment>
        //                                 </div>

        //                                 <div id="batch-docs" className="flex flex-col h-full w-1/3 min-h-full ml-2">
        //
        //                                     <div id="batch-spreadsheet-upload" className="h-2/5 block justify-start border border-1 border-gray-900 p-2">
        //                                         {/*    accept speadsheet upload here*/}
        //                                         <div>
        //                                             <p className="text-sm text-gray-700 py-1">Upload a spreadsheet:</p>
        //                                             <br/>
        //                                         </div>
        //
        //                                         {/*<label className="custom-file-upload text-sm text-gray-700 overflow-hidden border-gray-100 rounded px-1">*/}
        //                                         {/*    <input type="file" id="file-upload" multiple className="w-full hidden"/>*/}
        //                                         {/*    Select your file*/}
        //                                         {/*</label>*/}
        //                                         <div className='inline-flex w-full'>
        //                                             <div className='w-2/3'>
        //
        //                                                 {
        //                                                     this.state.files.length < 1 ?
        //
        //                                                         <div className='w-full'>
        //                                                             <button
        //                                                                 className="rounded-md border border-gray-300 shadow-sm m-2 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-400 active:bg-gray-300 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
        //                                                                 onClick={() => {
        //                                                                     document.getElementById('file-upload').click()
        //                                                                     // onFileUpload().then(r => {})
        //                                                                 }}
        //                                                             >
        //                                                                 Select file
        //                                                             </button>
        //                                                         </div>
        //                                                         : // else
        //                                                         <div>
        //                                                             <div className='inline-flex h-10'>
        //                                                                 <div className='w-1/2'>
        //                                                                     <button
        //                                                                         className="rounded-md border border-green-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-green-400 active:bg-green-300 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
        //                                                                         // onClick={onFileUpload}
        //                                                                     >
        //                                                                         Submit
        //                                                                     </button>
        //                                                                 </div>&nbsp;
        //
        //                                                                 <div className='w-1/2'>
        //                                                                     <button
        //                                                                         className="rounded-md border border-blue-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-blue-400 active:bg-blue-300 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
        //                                                                         onClick={() => document.getElementById('file-upload').click()}
        //                                                                     >
        //                                                                         Change
        //                                                                     </button>
        //                                                                 </div>
        //                                                             </div>
        //
        //                                                         </div>
        //                                                 }
        //
        //
        //                                             </div>
        //                                             <div className='w-1/3 overflow-hidden ml-1 text-xs text-gray-700 font-medium' style={{wordWrap: 'break-word'}}>
        //                                                 {/*<div style={{wordWrap: 'break-word'}}>*/}
        //                                                 {fileName ? fileName : ''}
        //                                                 {/*    <span className='inline-flex ml-1 text-xs text-gray-700 font-medium'>{fileName ? fileName : ''}</span>*/}
        //                                                 {/*</div>*/}
        //                                             </div>
        //                                         </div>
        //
        //                                         {/*<div className="w-full">*/}
        //
        //
        //                                         {/* &nbsp;*/}
        //                                         {/*<label htmlFor="file-upload" className="custom-file-upload text-sm text-gray-700 overflow-hidden border border-1 border-gray-100 rounded px-1">Select file</label>*/}
        //                                         <input id="file-upload" className="hidden" type="file"
        //                                                // onChange={onChange}
        //                                         />
        //
        //                                         {/*</div>*/}
        //
        //                                     </div>
        //
        //                                     <br />

        //                                     <div id="batch-loans-search" className="mt-10 h-3/5 border border-1 border-gray-900 p-2 overflow-scroll">
        //                                         {/*    search component for loans go here*/}
        //                                         <div>
        //                                             <p className="text-sm text-gray-700 py-1">Search loans to add:</p>
        //                                             <input
        //                                                 type="text"
        //                                                 id="search-field"
        //                                                 className="h-6 text-gray-700 text-sm"
        //                                                 name="search_query"
        //                                                 // value={search_query}
        //                                                 // onKeyDown={() => clearTimeout(typingWaitTimer)}
        //                                                 // onChange={searchInputHandler}
        //                                             />
        //
        //                                         </div>
        //                                         <div>
        //                                             {
        //                                                 this.state.searchResults < 1 &&
        //                                                 <></>
        //                                             }
        //                                             {
        //                                                 this.state.searchResults.length > 0 &&
        //                                                 <>
        //                                                     <div>
        //                                                         <table>
        //                                                             <thead>
        //                                                             <tr className="h-0">
        //                                                                 <th
        //                                                                     scope="col"
        //                                                                     className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                                 >&nbsp;&nbsp;</th>
        //                                                                 <th
        //                                                                     scope="col"
        //                                                                     className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                                 >&nbsp;&nbsp;</th>
        //                                                                 <th
        //                                                                     scope="col"
        //                                                                     className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                                 >&nbsp;&nbsp;</th>
        //                                                                 <th
        //                                                                     scope="col"
        //                                                                     className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                                 >&nbsp;&nbsp;</th>
        //                                                                 <th
        //                                                                     scope="col"
        //                                                                     className="px-3 py-1 text-left text-xs font-medium font-extrabold text-gray-700 uppercase tracking-wider"
        //                                                                 >&nbsp;&nbsp;</th>
        //                                                             </tr>
        //                                                             </thead>
        //                                                             <tbody>
        //
        //                                                             {
        //                                                                 this.state.searchResults.map((entry) => {
        //                                                                     let index = this.state.searchResults.indexOf(entry) + 1
        //                                                                     let randomNumber = Math.floor(Math.random() * 10)
        //
        //                                                                     return (
        //
        //
        //                                                                         <tr key={index}>
        //                                                                             <td className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700">{randomNumber > 5 ? 'green' : 'red'}</td>
        //                                                                             <td className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700">{entry.name}</td>
        //                                                                             <td className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700">{entry.daysOverdue}</td>
        //                                                                             <td className="px-2 py-2 whitespace-nowrap text-xs font-medium text-center text-gray-700">{entry.amountDue}</td>
        //                                                                             <td><a href="#" className="px-2 py-2 whitespace-nowrap text-xs text-center font-medium underline text-blue-700">Add</a></td>
        //                                                                         </tr>
        //                                                                     )
        //
        //
        //                                                                     //     {/*<span className="px-1 py-4 whitespace-nowrap text-sm font-medium text-gray-700"*/}
        //                                                                     //     {/*      key={index}*/}
        //                                                                     //     {/*      id={`add-loan${index}`}*/}
        //                                                                     //     {/*>*/}
        //                                                                     //     {/*    {entry.name}&nbsp;*/}
        //                                                                     //     {/*    {entry.daysOverdue}&nbsp;*/}
        //                                                                     //     {/*    {entry.amountDue}&nbsp;*/}
        //                                                                     //     {/*    <a*/}
        //                                                                     //     {/*        href="#"*/}
        //                                                                     //     {/*        // onClick={addToBatch}*/}
        //                                                                     //     {/*        className="underline text-blue-700"*/}
        //                                                                     //     {/*    >*/}
        //                                                                     //     {/*        Add*/}
        //                                                                     //     {/*    </a>*/}
        //                                                                     //     {/*</span>*/}
        //                                                                     // )
        //                                                                 })
        //                                                             }
        //                                                             </tbody>
        //                                                         </table>
        //                                                     </div>
        //
        //                                                 </>
        //
        //
        //                                             }
        //                                         </div>
        //
        //                                     </div>

        //                                 </div>
        //                             </div>
        //                             <br />
        //
        //                             {/*<div id="batch-pagination" className="text-center">*/}
        //                             {/*    /!*    pagination controls go here*!/*/}
        //                             {/*    <>*/}
        //                             {/*        &nbsp;&nbsp;*/}
        //                             {/*        /!*{searchField}*!/*/}
        //                             {/*        <input type='text'/>*/}
        //                             {/*        &nbsp;&nbsp;<i className="fa fa-angle-double-left"> </i>{firstButton}{' '}{previousButton}{' '}<strong>{start}</strong>{' '}to{' '}<strong>{end}</strong>{' '}of{' '}<strong>{' '}{totalRecords}</strong>{' '}{nextButton}{' '}{lastButton}&nbsp;&nbsp;*/}
        //                             {/*    </>*/}
        //
        //                             {/*</div>*/}
        //
        //                             <div id="button-container" className="mt-5">
        //
        //
        //                                 <button
        //                                     // onClick={submitBatch}
        //                                     className=" inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-400 active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
        //                                 >Submit Batch</button>
        //                             </div>
        //
        //                             <br />
        //
        //
        //
        //                             {/*</ModalBody>*/}
        //                             {/*</Modal>*/}
        //
        //                         </>
        //                     </div>
        //                 </div>
        //             </Transition.Child>
        //         </div>
        //     </Dialog>
        // </Transition.Root>

        const browsePermission = this.state.master === 'arrears' ? 'viewArrears' : 'viewCollectionsProjection';


        return (
            <div className="animated slideInUpTiny animation-duration-3 push-down">

                <AccessControl required={browsePermission} displayError={true}>
                    {/*{collectionsScreen}*/}
                    {/*{contactWindow}*/}
                    {/*{messageWindow}*/}
                    {batchForm}
                    {paymentBatch}
                    {paymentReview}
                    {/*{paymentsBatch}*/}
                    {this.state.showPaymentWindow && <LoanPayment show={this.state.showPaymentWindow} loan={this.state.loan} loanId={this.state.loan._id} handleClose={() => this.unloadPaymentWindow()} />}
                    <>
                        <Row style={{padding: '0px 10px'}}>
                        <Col md={6}>
                            <>
                                <h1 className={"pull-left m-t-5"}>
                                    {pageTitle}&nbsp;&nbsp;&nbsp;
                                </h1>
                                <span className={"pull-left m-t--5 bg-lightest-blue radius-20"}>
                                    <Dropdown as={ButtonGroup} className={"btn-sm"} variant={"success"} noCaret>
                                        <Dropdown.Toggle variant="default">
                                            <span className={"text-125"}>{this.state.filter.name}</span> &nbsp;
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {F.recursivePath(this.state.standardFiltersList, this.state.master, []).map((filterItem, key) => (
                                                <Dropdown.Item
                                                    className={"btn-sm"}
                                                    onClick={() => this.loadFilter(filterItem.filter)}
                                                >
                                                    {filterItem.filter.name}
                                                </Dropdown.Item>
                                            ))}
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                                className={""}
                                                disabled
                                            >
                                                <span>Custom Filters:</span>
                                            </Dropdown.Item>
                                            {this.state.filters.map((filter, key) => (
                                                <Dropdown.Item
                                                    className={"btn-sm"}
                                                    onClick={() => this.loadFilter(filter)}
                                                >
                                                    {filter.name}
                                                </Dropdown.Item>
                                            ))}
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                                className={"btn-sm"}
                                                onClick={() => this.loadFilters()}
                                            >
                                                <i className={"fa fa-sync-alt"}></i>&nbsp;Refresh List
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className={"btn-sm"}
                                                onClick={() => this.createFilter()}
                                            >
                                                <i className={"fa fa-filter"}></i>&nbsp;More Filters
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </span>
                            </>
                        </Col>
                        <Col md={6}>
                            <div className={"text-right"}>
                                  <span class={"clickable link-text"} onClick={() => this.loadBatches()}>
                                      <i className={"fa fa-sync-alt text-primary"}></i>
                                  </span>
                                <Paging
                                    hideSearch={true}
                                    query={this.state.query}
                                    onChange={() => this.loadBatches()}
                                    onChangeKeyword={keywords => this.setKeywords(keywords)}
                                    parentStateHandler={this.onChangePaging}
                                    parentState={this.state}
                                />
                            </div>
                        </Col>
                    </Row>
                    </>

                    <>
                        <Row style={{padding: '0px 10px'}}>
                            <Col md={12}>

                                <div>

                                    <button className="my-3 btn btn-lg btn-geekblue rounded-pill"
                                        onClick={() => this.setState({showBatchCreationWindow: true})}
                                    >
                                        <i className={"fa fa-plus"}></i>&nbsp;&nbsp;New Batch
                                    </button>
                                    <button className="my-3 btn btn-lg btn-geekblue rounded-pill"
                                        onClick={() => {
                                            const url = 'https://foster-lending-static.s3.us-west-1.amazonaws.com/templates/FosterLendingBatchPaymentTemplateV1.0.xlsx'
                                            window.open(url, '_blank').focus()
                                        }}
                                    >
                                        <i className="fa fa-download"></i>&nbsp;&nbsp;Download Template
                                    </button>
                                </div>

                                {this.state.batches.records.length < 1 &&
                                <>
                                    <big>No matching data.</big>
                                </>
                                }
                                {/*{this.state.loans.records.length > 0 &&*/}
                                {this.state.batches.records.length > 0 &&
                                <Fragment>
                                    <Table striped className={"transactions-table"}>
                                        <thead>
                                        <tr>
                                            {this.state.batches.columns.map((column, key) => (
                                                <th className={"clickable"}
                                                    onClick={() => this.sortBatches(column.fieldName)}>
                                                    {column.label}
                                                    &nbsp;{this.state.sorting.field === column.fieldName &&
                                                <>
                                                    {this.state.sorting.order === -1 &&
                                                    <i className={"fa fa-caret-down"}></i>
                                                    }
                                                    {this.state.sorting.order === 1 &&
                                                    <i className={"fa fa-caret-up"}></i>
                                                    }
                                                </>
                                                }
                                                </th>
                                            ))}
                                            <th>
                                                Actions
                                            </th>
                                            {/*<th>*/}
                                            {/*    &nbsp;*/}
                                            {/*</th>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.batches.records
                                            .map((record, key) => {

                                                const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
                                                    year:  'numeric',
                                                    month: 'long',
                                                    day:   'numeric',
                                                });

                                                let index = this.state.batches.records.indexOf(record) + 1

                                                const creationDate = new Date(record.createdAt)
                                                const year = creationDate.getFullYear()
                                                const month = creationDate.getMonth()
                                                const day = creationDate.getDate()

                                                if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {

                                                    const date = new Date (year, month, day)
                                                    record.createdAt = longEnUSFormatter.format(date)
                                                }


                                                return (

                                                    <tr id={`tr-${index}`} key={key}>
                                                        {this.state.batches.columns.map((column, key) => (
                                                            <td
                                                                id={`td-${index}`}
                                                                className={"clickable"}
                                                                // onClick={() => this.loadCollectionsData(record._id)}
                                                                onClick={(e) => this.viewBatch(e)}
                                                            >
                                                                <CellValue record={record} column={column}/>
                                                            </td>
                                                        ))}
                                                        <td>
                                                            {/*<i className={"fa fa-bars"}></i>*/}
                                                            <div className="dropdown" style={{
                                                                position: 'page',
                                                                display: 'inline-block'
                                                            }}>
                                                                <button className="dropbtn"
                                                                        id={`btn-${index}`}
                                                                        onClick={(e) => this.clicked(e)}
                                                                        style={{
                                                                            'backgroundColor': 'inherit',
                                                                            // color: 'white',
                                                                            padding: '2px',
                                                                            'fontSize': '14px',
                                                                            'border': 'none',
                                                                            'cursor': 'pointer'
                                                                        }}
                                                                >
                                                                    <i id={`icon-${index}`} className={"fa fa-bars dropbtn"} style={{color: 'black'}}></i>
                                                                </button><div id={`myDropdown${index}`} className="dropdown-for-actions dropdown-content hidden"
                                                                     style={{
                                                                         display: 'none',
                                                                         position: 'absolute',
                                                                         'backgroundColor': '#f1f1f1',
                                                                         'minWidth': '160px',
                                                                         overflow: 'auto',
                                                                         'boxShadow': '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                                                         'zIndex': '1001'
                                                                     }}
                                                                >
                                                                    <a style={{display: 'block', color: 'black', padding: '12px 16px'}}
                                                                       href="#"
                                                                       id={`view-${index}`}
                                                                       onClick={(e) => this.viewBatch(e)}
                                                                    >View/Edit</a>
                                                                    {/*<a style={{display: 'block', color: 'black', padding: '12px 16px'}}*/}
                                                                    {/*   href="#"*/}
                                                                    {/*   id={`stop-${index}`}*/}
                                                                    {/*   onClick={(e) => this.stopBatch(e)}*/}
                                                                    {/*>Stop</a>*/}
                                                                    {/*<a style={{display: 'block', color: 'black', padding: '12px 16px'}}*/}
                                                                    {/*   href="#"*/}
                                                                    {/*   id={`retry-${index}`}*/}
                                                                    {/*   onClick={(e) => this.retryBatch(e)}*/}
                                                                    {/*>Retry</a>*/}
                                                                    <a style={{display: 'block', color: 'black', padding: '12px 16px'}}
                                                                       href="#"
                                                                       id={`delete-${index}`}
                                                                       onClick={(e) => this.deleteBatch(e)}
                                                                    >Delete</a></div></div>
                                                        </td>
                                                        {/*<td className={"text-center"}>*/}
                                                        {/*    <a href={`/app/loans/${record._id}/!/schedule`}*/}
                                                        {/*       target={'_blank'}>*/}
                                                        {/*        <i className={'fa fa-external-link-alt'}/>*/}
                                                        {/*    </a>&nbsp;&nbsp;*/}
                                                        {/*</td>*/}

                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    <hr/>
                                    <div className={"text-center push-down block"}>
                                        <Paging
                                            hideSearch={true}
                                            query={this.state.query}
                                            onChange={() => this.loadBatches()}
                                            parentStateHandler={this.onChangePaging}
                                            parentState={this.state}
                                        />
                                    </div>
                                    <div style={{height: '92px', clear: 'both'}}>

                                    </div>
                                </Fragment>
                                }
                            </Col>
                        </Row>
                        {/*<br />&nbsp;*/}
                    </>
                </AccessControl>

            </div>
        );
    }

}

Batches.propTypes = {
    setGlobalState: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    globalState: state.globalState,
});

export default connect(mapStateToProps, { setGlobalState })(Batches);