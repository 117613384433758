import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";


const User = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/account`}/>
            <Route path={`${match.url}/account`} component={asyncComponent(() => import("./routes/account"))}/>
        </Switch>
    </div>
);

export default User;
