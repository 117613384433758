import React, {useEffect, useState} from 'react';
import moment from "moment";
import {setGlobalState, setPermissions} from 'actions';
import {connect} from 'react-redux';

import { w3cwebsocket as W3CWebSocket } from "websocket";
import PropTypes from "prop-types";
import {Button} from "@material-ui/core";
import axios from "axios";

const AuthSocket = props => {

    const socket = new W3CWebSocket(window.socketEndpoint());
    const [cleared, setCleared] = useState(true);
    const [expiring, setExpiring] = useState(false);

    useEffect( () => {
        activateSessionTracker();
        socket.onopen = () => {
            let perms = props.globalState.permissions || [];
            let authTimestamp = localStorage.getItem('authTimestamp');
            let diff = moment().diff(authTimestamp || moment(), 'seconds');
            if ( diff > 30 || !authTimestamp ) {
                localStorage.setItem('authTimestamp', moment());
                console.log('Socket connection established with RFS Sockets.', diff, authTimestamp);
                let data = {
                    userId: localStorage.getItem('authUser'),
                    accessToken: localStorage.getItem('accessToken'),
                    type: "getPrivileges",
                    version: process.env.REACT_APP_BUILD_VERSION
                };
                socket.send(JSON.stringify(data));
            } else {
                // console.log(diff, 'else');
            }
        };
        socket.onmessage = (message) => {
            if ( message.data ) {
                // console.log(message.data, 'data!!');
                let messageData = {};
                try {
                    messageData = JSON.parse(message.data);
                } catch(error){}
                if ( messageData.passwordExpired ) {
                    localStorage.setItem('passwordExpired', true);
                    return;
                }
                if ( messageData.passwordNotExpired ) {
                    localStorage.setItem('passwordExpired', false);
                }
                if ( messageData.permissions ) {
                    localStorage.setItem('userPermissions', messageData.permissions);
                    props.setGlobalState({data: {permissions: messageData.permissions, authTimestamp: moment()}});
                }
                if ( messageData.userName ) {
                    localStorage.setItem('userName', messageData.userName);
                }
                if ( messageData.userEmail ) {
                    localStorage.setItem('userEmail', messageData.userEmail);
                }
                if ( messageData.updateAction === 'update' ) {
                    props.setGlobalState({data: {releaseNotice: messageData.releaseNotice}});
                    props.setGlobalState({data: {releaseLevel: messageData.releaseLevel}});
                }
                if ( messageData.updateAction === 'ignore' ) {
                    props.setGlobalState({data: {releaseNotice: null}});
                    props.setGlobalState({data: {releaseLevel: null}});
                }
            }
        };
    });

    const activateSessionTracker = () => {
        ///////////////////////////
        console.log('starting interval...')
        const timeoutWatcher = window.setInterval(async () => {
            if ( window.location.href.indexOf('/signin') > -1 ) return;
            let lastPulseCheck = localStorage.getItem('lastPulseCheck');
            let timeDiff;
            let forceLogout = false;
            if ( lastPulseCheck ) {
                let timeDiff = moment().diff(moment(lastPulseCheck), 'seconds');
                console.log(`Time diff is ${timeDiff} at ${moment()}`)
                if ( +(timeDiff) > 25*60 ) forceLogout = true;
                if ( +(timeDiff) < 10 && +(timeDiff) >= 0 ) {
                    return;
                } else {
                    // console.log('diff >= 20', timeDiff);
                    lastPulseCheck = moment().toDate();
                    await localStorage.setItem('lastPulseCheck', lastPulseCheck.toString());
                }
            } else {
                lastPulseCheck = moment().toDate();
                await localStorage.setItem('lastPulseCheck', lastPulseCheck.toString());
            }
            // console.log(lastPulseCheck)
            let userId = ( localStorage.getItem('userId') || '' ).toString();
            let accessToken = ( localStorage.getItem('accessToken') || '' ).toString();
            let accessKey = ( localStorage.getItem('accessKey') || '' ).toString();
            let timezoneOffset = new Date().getTimezoneOffset();
            let headers = {userId, accessToken, accessKey, timezoneOffset};
            let lastSeenData = await axios.post(window.endpoint('user', '/last-seen-pulse'), {}, {headers: {...headers, pulseCheck: true}});
            if ( lastSeenData.data.sessionStatus === 'alive' ) {
                localStorage.removeItem('sessionExpiring');
                setExpiring(false);
                setCleared(true);
            } else if ( lastSeenData.data.sessionStatus === 'sick' ) {
                localStorage.setItem('sessionExpiring', "true");
                setExpiring(true);
                setCleared(false);
            } else if ( lastSeenData.data.sessionStatus === 'polygamous' ) {
                window.logout();
                if ( window.location.href.indexOf('/signin') === -1 ) {
                    window.location.assign('/signin?.r=polygamous')
                }
            } else if ( forceLogout || lastSeenData.data.sessionStatus === 'dead' ) {
                window.logout();
                if ( window.location.href.indexOf('/signin') === -1 ) {
                    window.location.assign('/signin?.r=timeout')
                }
            } else {
                console.log('Undecided about the session status check!', {lastSeenData, timeDiff});
            }
        }, 10000);
        ///////////////////////////
    };

    const sessionExpiring = () => ( localStorage.getItem('sessionExpiring') == 'true' || expiring ) && window.location.href.indexOf('/signin') === -1;
    let passwordExpired = localStorage.getItem('passwordExpired') == 'true' && window.location.href.indexOf('/app/user/account') === -1;
    passwordExpired = false;

    const renewPulse = async () => {
        let userId = ( localStorage.getItem('userId') || '' ).toString();
        let accessToken = ( localStorage.getItem('accessToken') || '' ).toString();
        let accessKey = ( localStorage.getItem('accessKey') || '' ).toString();
        let timezoneOffset = new Date().getTimezoneOffset();
        let headers = {userId, accessToken, accessKey, timezoneOffset};
        await axios.post(window.endpoint('user', '/renew-pulse'), {}, window.getConfig());
        localStorage.removeItem('sessionExpiring');
        setCleared(true);
    };

    return (
        <>
            {passwordExpired && <>
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#f9f9f9', padding: '19%'}}>
                    <p className={"text-center text-150"}>
                        Your password has expired and must be changed now.&nbsp;
                        <Button variant={"contained"} size={"large"} color={"secondary"} onClick={() => window.location.assign('/app/user/account')}>Change Password</Button><br /><br />
                        <small>If you just changed your password. Please wait 30 seconds, or sign out and back in.</small>
                    </p>
                </div>
            </>}
            {!cleared && ((expiring || localStorage.getItem('sessionExpiring')) && window.location.href.indexOf('/signin') === -1) && <>
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#03141c', padding: '19%', filter: 'alpha(opacity=97)', opacity: '0.97'}}>
                    <p className={"text-center text-150 text-white"}>
                        <h1 style={{color: '#ff4800', fontSize: '150%'}}>Are you still there?</h1>
                        <br />
                        <p>For security reasons, we will end your session if you remain inactive for much longer.</p>
                        <br />
                        <Button variant={"contained"} size={"large"} color={"primary"} onClick={() => renewPulse()}>I'm Here</Button>
                    </p>
                </div>
            </>}
        </>
    );

};

AuthSocket.propTypes = {
    setGlobalState: PropTypes.func.isRequired,
    setPermissions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    globalState: state.globalState
});

export default connect(mapStateToProps, {setPermissions, setGlobalState})(AuthSocket);