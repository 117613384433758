import React, { Fragment, Component, useState } from 'react';
import { FormControl, FormCheck, Row, Col, FormLabel, Table, Container, Button, FormGroup } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Input from '@material-ui/core/Input';
import MenuItem from "@material-ui/core/MenuItem";
import { Button as MaterialButton } from "@material-ui/core";
import F from "./Functions";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from '@material-ui/lab/Alert';
import Slide from "@material-ui/core/Slide";
// import {SelectField, GenericField} from "./FieldTypes";
import Form from "react-bootstrap/Form";
// import Lists from "./Lists";
import Paper from "@material-ui/core/Paper";
// import FormElements from "./FormElements";
import Modal from "react-bootstrap/Modal";
import {
    CurrencyText, DateText,
    DecimalText,
    GenericField,
    IntegerText,
    PercentageText, RadioField,
    SelectField,
    TextInputField
} from "./FieldTypes";
import axios from "axios";
import moment from "moment";
import { Note } from "@material-ui/icons";
import Select from 'react-select';
import AccessControl from "./AccessControl";
import FormElements from "./FormElements";
import MaskedInput from "react-text-mask";
import emailMask from 'text-mask-addons/dist/emailMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Lists from "./Lists";
import { Badge, ModalBody, ModalHeader } from "reactstrap";
import Avatar from "./Avatar";
import { MdStar } from "react-icons/md";
import { VerificationStatus } from "cleared-verification-client";
import "cleared-verification-client/dist/styles/no-tailwind.css";

const masks = {
    ssn: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    medicaid: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    medicare: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/],
    phoneNumber: ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    zipCode: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
};

const stopFields = [
    '_id',
    'clientId',
    'createdAt',
    'updatedAt',
    'status',
    'fullName',
    'age',
    'hashId',
    '_v'
];

const trackedTypes = [
    'string',
    'number'
];

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

export class PageTitle extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            pageTitle: '',
        };

        window.Messenger.receive("SetPageTitle", this.onSetPageTitle, this);

    }

    onSetPageTitle(eventClass, token, data) {
        this.setState({ ...this.state, pageTitle: data.pageTitle });
        document.title = data.pageTitle + " - Regions Financial Services";
    }

    render() {
        return (
            <>
                <h3 className={"page-title"}>
                    {this.state.pageTitle}
                </h3>
            </>
        )

    }

}

export class Notification extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            data: { message: 'Testing', type: "success" },
            showNotification: false,
            vertical: null,
            horizontal: 'right',
        };

        this.state.Transition = Slide;

        window.Messenger.receive("DisplayNotification", this.onNotification, this);
        this.handleRequestClose = this.closeNotification.bind(this);

    }

    onNotification(eventClass, token, data) {
        this.setState({ ...this.state, data: data, showNotification: true });
    }

    closeNotification() {
        this.setState({ ...this.state, data: {}, showNotification: false });
    }

    render() {
        const duration = (this.state.data.message || '').split(' ').length * 1000;
        return (
            <>
                <Snackbar
                    open={this.state.showNotification}
                    onClose={this.handleRequestClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    TransitionComponent={this.state.Transition}
                    autoHideDuration={duration}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleRequestClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                >
                    <Alert
                        onClose={this.handleRequestClose}
                        variant={"filled"}
                        severity={this.state.data.type}>
                        {this.state.data.message}
                    </Alert>
                </Snackbar>
            </>
        )

    }

}

export class CreateClient extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            showCreateWindow: false,
            queueId: null,
            data: {
                personal: {},
                address: {
                    isMailingAddress: true,
                    isPrimary: true
                },
                contact: {}
            },
            errors: {},
            isPageDirty: false,
            dataSections: ['personal', 'address', 'contact']
        };

        this.onChange = this.onChangeHandler.bind(this);
        this.onChangeRadio = this.onChangeRadioHandler.bind(this);
        this.onChangeCheck = this.onChangeCheckHandler.bind(this);
        this.onChangeDate = this.onChangeDateHandler.bind(this);
        this.handleCloseCreateWindow = this.onCloseCreateWindow.bind(this);

        window.Messenger.receive("CreateClient", this.onCreateRequest, this);

        this.innerRef = React.createRef();

    };

    componentDidMount() {
        this.onChangeHandler({ target: { name: 'isMailingAddress', value: true } });
    }

    onCreateRequest(eventClass, token, data) {
        this.setState({ ...this.state, showCreateWindow: true, page: 1, queueId: data.queueId });
    };

    onChangeHandler = async (event) => {
        let section = this.state.dataSections[this.state.page - 1];
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.data;
        data[section] = { ...data[section], [name]: value };
        await this.setState({ ...this.state, isPageDirty: true, data: data });
        window.Messenger.broadcast('SetPageDirty', { isPageDirty: true });
    };

    onChangeDateHandler(field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onChangeRadioHandler(e, field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onChangeCheckHandler(e, field) {
        let section = this.state.dataSections[this.state.page - 1];
        let event = { target: {} };
        event.target = {};
        event.target.name = field;
        let value = this.state.data[section][field];
        event.target.value = !value;
        console.log('tracking check', event);
        return this.onChangeHandler(event);
    }

    onCloseCreateWindow() {
        if (this.state.isPageDirty) {
            if (window.confirm("You have unsaved changes. Still exit?")) {
                this.forceCloseEditWindow();
            }
        } else {
            this.forceCloseEditWindow();
        }
    }

    forceCloseEditWindow() {
        this.setState({ ...this.state, showCreateWindow: false, data: { personal: {}, address: {}, contact: {} } });
    };

    async saveClient() {
        let errored = false;
        this.loading(true);
        let data = { ...this.state.data };
        let address = data.address || {};
        let contact = data.contact || {};
        let phoneNumber = { phoneNumber: contact.phoneNumber, type: contact.phoneNumberType, isPrimary: true };
        let emailAddress = { email: contact.email };
        if (phoneNumber) delete address['phoneNumber'];
        if (emailAddress) delete address['emailAddress'];
        let payload = {
            bio: data.personal,
            contact: {
                addresses: [address],
                phoneNumbers: [phoneNumber],
                emailAddresses: [emailAddress],
            }
        };

        await axios.post(window.endpoint('clients', '/create-client'), { payload }, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                if (result.data) {
                    if (result.data.errors === undefined) {
                        if (this.state.closeEditWindow) {
                            window.Messenger.broadcast('CloseEditWindow', {});
                        }
                        if (this.state.queueId) {
                            window.Messenger.broadcast('CreatedNewClient', { queueId: this.state.queueId, clientId: result.data.client._id });
                        }
                    } else {
                        errored = true;
                        window.Messenger.broadcast('DisplayNotification', result.data.notification);
                    }
                }
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );
        if (!errored) {
            this.setState({ ...this.state, isPageDirty: false });
            window.Messenger.broadcast('SetPageDirty', { isPageDirty: false });
            this.forceCloseEditWindow();
        }
    };

    loading(state) {
        window.Messenger.broadcast("SetLoadingState", { isLoading: state });
    };

    goToPage(page) {
        this.setState({ ...this.state, page: page });
    };

    render() {

        const dataSections = this.state.dataSections;

        const fields = [
            [
                { label: "TRN", name: "trn", standardField: "trn", mask: "trn", required: true },
                { label: "First Name", name: "firstName", standardField: "firstName", required: true },
                { label: "Middle Name", name: "middleName", standardField: "middleName" },
                { label: "Last Name", name: "lastName", standardField: "lastName", required: true },
                { label: "Dependents", name: "dependents", standardField: "dependents", type: "number" },
                { label: "Date of Birth", type: "date", name: "dateOfBirth", standardField: "dateOfBirth", required: true },
                { label: "Gender", type: "radio", name: "gender", list: "genders", standardField: "gender", required: true },
                { label: "Marital Status", type: "dropdown", name: "maritalStatus", list: "maritalStatuses", required: true },
            ],
            [
                { label: "Address Line 1", name: "addressLine1", standardField: "addressLine1", required: true },
                { label: "Address Line 2", name: "addressLine2", standardField: "addressLine2" },
                { label: "Town", name: "town", standardField: "addressTown", required: true },
                { label: "Parish", type: "dropdown", name: "parish", required: true, list: "parishes", standardField: 'addressParish' },
                { label: "Use this as a mailing address", type: "checkbox", name: "isMailingAddress" },
                { label: "Ownership", type: "radio", name: "homeOwnershipType", required: true, list: "homeOwnershipTypes" },
                { label: "Years at Address", name: "yearsAtAddress", standardField: "yearsAtAddress", type: "number" },
                { label: "Make this the primary address", type: "checkbox", name: "isPrimary" },
            ],
            [
                { label: "Phone Number", name: "phoneNumber", required: true, standardField: 'phoneNumber', mask: 'phoneNumber' },
                { label: "Email Address", name: "email", required: true, standardField: 'email', mask: 'email' },
            ]
        ];

        const simpleNavMenu = <div className={"text-center"}>
            {this.state.page !== 1 && <span className={"clickable link-text"} onClick={() => this.goToPage(1)}>
                Personal Details
            </span>} {this.state.page === 1 && <strong>Personal Details</strong>} &nbsp; | &nbsp;
            {this.state.page !== 2 && <span className={"clickable link-text"} onClick={() => this.goToPage(2)}>
                Address
            </span>} {this.state.page === 2 && <strong>Address</strong>} &nbsp; | &nbsp;
            {this.state.page !== 3 && <span className={"clickable link-text"} onClick={() => this.goToPage(3)}>
                Contact
            </span>} {this.state.page === 3 && <strong>Contact</strong>}
        </div>;

        const createClientWindow = <>
            <Modal show={this.state.showCreateWindow} onHide={this.handleCloseCreateWindow} dialogClassName="x-modal-right" keyboard={true}>
                <div className={"modal-container"}>
                    <div className={"modal-titular"}>
                        <a className={"clickable pull-right"} onClick={this.handleCloseCreateWindow}>
                            <CloseIcon />
                        </a>
                        New Client
                    </div>
                    <div className={"modal-body"}>
                        <Container>

                            <AccessControl required={"createClients"} displayError={true}>

                                {simpleNavMenu}

                                <hr />

                                {fields.map((fieldGroup, fieldGroupkey) => (
                                    <Fragment key={fieldGroupkey}>
                                        {this.state.page === fieldGroupkey + 1 &&
                                            <>
                                                {fieldGroup.map((field, key) => (
                                                    <Fragment key={key}>
                                                        <Form.Group>
                                                            <GenericField
                                                                list={field.list}
                                                                type={field.type || 'text'}
                                                                label={field.label}
                                                                name={field.name}
                                                                value={F.recursivePath(this.state.data, dataSections[fieldGroupkey] + "." + field.name)}
                                                                checked={F.recursivePath(this.state.data, dataSections[fieldGroupkey] + "." + field.name)}
                                                                onChange={this.onChange}
                                                                onChangeRadio={this.onChangeRadio}
                                                                onChangeDate={this.onChangeDate}
                                                                onChangeCheck={(e) => this.onChangeCheck(e, field.name)}
                                                                errors={this.state.errors[field.name]}
                                                                mask={field.mask}
                                                                required={field.required}
                                                            />
                                                        </Form.Group>
                                                        {field.name === 'phoneNumber' &&
                                                            <Form.Group>
                                                                <GenericField
                                                                    label={"Type"}
                                                                    type={"radio"}
                                                                    name={"phoneNumberType"}
                                                                    list={"phoneNumberTypes"}
                                                                    value={F.recursivePath(this.state.data, dataSections[fieldGroupkey] + ".phoneNumberType")}
                                                                    onChangeRadio={this.onChangeRadio}
                                                                />
                                                            </Form.Group>
                                                        }
                                                    </Fragment>
                                                ))}
                                            </>
                                        }
                                    </Fragment>
                                ))}

                                <br />
                                {simpleNavMenu}

                                <hr />

                                {this.state.page === 1 &&
                                    <>
                                        <MaterialButton color={"primary"} variant={"contained"}
                                            onClick={() => this.goToPage(2)}>
                                            Next
                                        </MaterialButton>
                                        &nbsp;
                                    </>
                                }
                                {this.state.page === 2 &&
                                    <>
                                        &nbsp;
                                        <MaterialButton color={"default"} variant={"contained"} onClick={() => this.goToPage(1)}>
                                            Back
                                        </MaterialButton>
                                        &nbsp;
                                        <MaterialButton color={"primary"} variant={"contained"} onClick={() => this.goToPage(3)}>
                                            Next
                                        </MaterialButton>
                                    </>
                                }
                                {this.state.page === 3 &&
                                    <>
                                        &nbsp;
                                        <MaterialButton color={"default"} variant={"contained"} onClick={() => this.goToPage(2)}>
                                            Back
                                        </MaterialButton>
                                        &nbsp;
                                        <MaterialButton color={"primary"} variant={"contained"} onClick={() => this.saveClient()}>
                                            Save
                                        </MaterialButton>
                                    </>
                                }
                                &nbsp;

                            </AccessControl>
                            <MaterialButton color={"secondary"} onClick={this.handleCloseCreateWindow}>
                                Cancel
                            </MaterialButton>

                        </Container>
                    </div>
                </div>
            </Modal>
        </>;

        return (
            <>
                {createClientWindow}
            </>
        )

    }

}

export class CreateParty extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            showCreateWindow: false,
            queueId: null,
            data: {
                address: {
                },
                personal: {
                },
                id: null
            },
            errors: {

            },
            isPageDirty: false,
            page: 1,
            class: 'Third-party',
        };

        this.onChange = this.onChangeHandler.bind(this);
        this.onChangeRadio = this.onChangeRadioHandler.bind(this);
        this.onChangeDate = this.onChangeDateHandler.bind(this);
        this.handleCloseCreateWindow = this.onCloseCreateWindow.bind(this);

        window.Messenger.receive("CreateParty", this.onCreateRequest, this);

        this.innerRef = React.createRef();

    };

    onCreateRequest(eventClass, token, data) {
        let dataObject = { personal: {}, address: {} };
        this.setState({ ...this.state, showCreateWindow: true, page: 1, queueId: data.queueId, data: { ...dataObject, ...data.payload }, class: data.class });
    };

    onChangeHandler = async (event, section) => {
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.data;
        data[section] = { ...data[section], [name]: value };
        await this.setState({ ...this.state, isPageDirty: true, data: data });
        // window.Messenger.broadcast('SetPageDirty', {isPageDirty: true});
    };

    onChangeDateHandler(field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onChangeRadioHandler(e, field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onCloseCreateWindow() {
        if (this.state.isPageDirty) {
            if (window.confirm("You have unsaved changes. Still exit?")) {
                this.forceCloseEditWindow();
            }
        } else {
            this.forceCloseEditWindow();
        }
    }

    forceCloseEditWindow() {
        this.setState({ ...this.state, showCreateWindow: false, data: { address: {}, personal: {}, id: null } });
    };

    async saveParty() {
        let errored = false;
        this.loading(true);
        let data = { ...this.state.data };
        let payload = { payload: { ...data.personal, address: data.address, id: data.id }, category: data.category, clientId: data.clientId };
        await axios.post(window.endpoint('clients', '/create-party'), payload).then(result => {
            if (result.data) {
                this.loading(false);
                if (result.data) {
                    if (result.data.errors === undefined) {
                        if (this.state.closeEditWindow) {
                            window.Messenger.broadcast('CloseEditWindow', {});
                        }
                        if (this.state.queueId) {
                            window.Messenger.broadcast('CreatedNewParty', { queueId: this.state.queueId, partyId: result.data.party._id });
                        }
                    } else {
                        errored = true;
                        window.Messenger.broadcast('DisplayNotification', result.data.notification);
                    }
                }
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );
        if (!errored) {
            this.setState({ ...this.state, isPageDirty: false });
            window.Messenger.broadcast('SetPageDirty', { isPageDirty: false });
            this.forceCloseEditWindow();
        }
    };

    loading(state) {
        window.Messenger.broadcast("SetLoadingState", { isLoading: state });
    };

    goToPage(page) {
        this.setState({ ...this.state, page: page });
    };

    render() {

        const fields = [
            [
                { label: "First Name", name: "firstName", standardField: "firstName", required: true },
                { label: "Middle Name", name: "middleName", standardField: "middleName" },
                { label: "Last Name", name: "lastName", standardField: "lastName", required: true },
                { label: "Contact #", name: "phoneNumber", standardField: "phoneNumber", required: true, mask: 'phoneNumber' },
                { label: "Email Address", name: "emailAddress", standardField: "emailAddress", required: true, mask: 'email' },
            ],
            [
                { label: "Address Line 1", name: "addressLine1", standardField: "addressLine1", required: true },
                { label: "Address Line 2", name: "addressLine2", standardField: "addressLine2" },
                { label: "Town", name: "town", standardField: "addressTown", required: true },
                { label: "Parish", type: "dropdown", name: "parish", required: true, list: "parishes", standardField: 'addressParish' },
            ],
            [
                { label: "Job Title", name: "position", standardField: "position", required: true },
                { label: "Employer Name", name: "employerName", standardField: "employerName" },
                { label: "How many years has this party known the client for?", name: "knownForHowLong", standardField: "lengthOfRelationship", required: true },
                { label: "Relation", type: "dropdown", name: "relation", required: true, list: "relationTypes", standardField: 'relation' },
            ]
        ];

        const dataSections = ['personal', 'address', 'personal'];

        const createPartyWindow = <>
            <Modal show={this.state.showCreateWindow} onHide={this.handleCloseCreateWindow} dialogClassName="x-modal-right" keyboard={true}>
                <div className={"modal-container"}>
                    <div className={"modal-titular"}>
                        <a className={"clickable pull-right"} onClick={this.handleCloseCreateWindow}>
                            <CloseIcon />
                        </a>
                        {this.state.class}
                    </div>
                    <div className={"modal-body"}>
                        <Container>

                            {fields.map((fieldGroup, fieldGroupkey) => (
                                <Fragment key={fieldGroupkey}>
                                    {this.state.page === fieldGroupkey + 1 &&
                                        <>
                                            {fieldGroup.map((field, key) => (
                                                <Form.Group key={key}>
                                                    <GenericField
                                                        list={field.list}
                                                        type={field.type || 'text'}
                                                        label={field.label}
                                                        name={field.name}
                                                        value={F.recursivePath(this.state.data, dataSections[fieldGroupkey] + "." + field.name)}
                                                        onChange={(e) => this.onChange(e, dataSections[fieldGroupkey])}
                                                        onChangeRadio={this.onChangeRadio}
                                                        onChangeDate={this.onChangeDate}
                                                        errors={this.state.errors[field.name]}
                                                        mask={field.mask}
                                                        required={field.required}
                                                    />
                                                </Form.Group>
                                            ))}
                                        </>
                                    }
                                </Fragment>
                            ))}

                            <hr />

                            {this.state.page === 1 &&
                                <Button color={"primary"} variant={"contained"} onClick={() => this.goToPage(2)}>
                                    Next
                                </Button>
                            }
                            {this.state.page === 2 &&
                                <>
                                    &nbsp;
                                    <Button color={"default"} variant={"contained"} onClick={() => this.goToPage(1)}>
                                        Back
                                    </Button>
                                    &nbsp;
                                    <Button color={"primary"} variant={"contained"} onClick={() => this.goToPage(3)}>
                                        Next
                                    </Button>
                                </>
                            }
                            {this.state.page === 3 &&
                                <>
                                    &nbsp;
                                    <Button color={"default"} variant={"contained"} onClick={() => this.goToPage(2)}>
                                        Back
                                    </Button>
                                    &nbsp;
                                    <Button color={"primary"} variant={"contained"} onClick={() => this.saveParty()}>
                                        Save
                                    </Button>
                                </>
                            }
                            &nbsp;
                            <Button color={"secondary"} variant={"contained"} onClick={this.handleCloseCreateWindow}>
                                Cancel
                            </Button>

                        </Container>
                    </div>
                </div>
            </Modal>
        </>;

        return (
            <>
                {createPartyWindow}
            </>
        )

    }

}

////////////////////////////////////////////////////////////////

export default class SingleSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <Fragment>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={null}
                    isClearable={true}
                    isSearchable={true}
                    onChange={this.props.onChange}
                    name={this.props.name}
                    options={this.props.options || []}
                />
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////////////

export class CreateFilter extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            showCreateWindow: false,
            filters: [],
            master: '',
            filter: {
                fields: [],
                name: '',
                id: null,
            },
            editKey: null,
            editField: {
                field: {},
            },
            page: 1,
            fieldList: [
                { label: 'Principal', value: 'principal', type: 'number', format: 'currency' },
                { label: 'Interest Rate', value: 'interestRate', type: 'number', format: 'percentage' },
                { label: 'Status', value: 'status', type: 'dropdown', list: 'loanStatuses' },
            ],
            operators: {
                number: [
                    { label: 'is equal to', value: 'eq' },
                    { label: 'is NOT equal to', value: 'ne' },
                    { label: 'is less than', value: 'lt' },
                    { label: 'is less than or equal to', value: 'lte' },
                    { label: 'is greater than', value: 'gt' },
                    { label: 'is greater than or equal to', value: 'gte' },
                    { label: 'is between', value: 'between' },
                ],
                any: [
                    { label: 'is', value: 'eq' },
                    { label: 'is NOT', value: 'ne' },
                ]
            }
        };

        this.onChange = this.onChangeHandler.bind(this);
        this.onChangeRadio = this.onChangeRadioHandler.bind(this);
        this.onChangeDate = this.onChangeDateHandler.bind(this);
        this.onChangeField = this.onChangeFieldHandler.bind(this);
        this.handleCloseCreateWindow = this.onCloseCreateWindow.bind(this);

        window.Messenger.receive("CreateFilter", this.onCreateRequest, this);

        this.innerRef = React.createRef();

    };

    async onCreateRequest(eventClass, token, data) {
        await this.setState({ ...this.state, showCreateWindow: true, master: data.master, filter: data.filter, class: data.class });
        this.loadFilters();
    };

    onChangeHandler = async (event, section) => {
        let name = event.target.name;
        let value = event.target.value;
        let filter = this.state.filter;
        filter[name] = value;
        await this.setState({ ...this.state, filter: filter });
        // window.Messenger.broadcast('SetPageDirty', {isPageDirty: true});
    };

    onChangeFieldHandler = async (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let state = { ...this.state, editField: { ...this.state.editField, [name]: value } };

        await this.setState(state);
        // window.Messenger.broadcast('SetPageDirty', {isPageDirty: true});
    };

    onChangeDateHandler(field, value) {
        this.onChangeFieldHandler({ target: { name: field, value: value } });
    };

    onChangeRadioHandler(e, field, value) {
        this.onChangeFieldHandler({ target: { name: field, value: value } });
    };

    onCloseCreateWindow() {
        if (this.state.isPageDirty) {
            if (window.confirm("You have unsaved changes. Still exit?")) {
                this.forceCloseEditWindow();
            }
        } else {
            this.forceCloseEditWindow();
        }
    }

    forceCloseEditWindow() {
        this.setState({ ...this.state, showCreateWindow: false, data: { address: {}, personal: {}, id: null } });
    };

    editFilter(filter) {
        this.setState({ ...this.state, filter: filter });
    };

    async loadFilters() {
        this.loading(true);
        let payload = { query: { master: this.state.master } };
        await axios.post(window.endpoint('user', '/get-filters'), payload, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                this.setState({ ...this.state, filters: result.data.filters });
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );
    };

    async saveFilter() {
        this.loading(true);
        let filter = this.state.filter;
        filter.master = this.state.master;
        let payload = { payload: filter };
        let verb = 'create';
        if (this.state.filter._id) {
            verb = 'save';
            payload.id = this.state.filter._id;
        }
        await axios.post(window.endpoint('user', '/' + verb + '-filter'), payload, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                let state = { ...this.state, filter: { name: '', fields: [] } };
                this.setState(state);
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );
        this.loadFilters();
    };

    async deleteFilter(id) {
        this.loading(true);
        let payload = { id: id };
        await axios.post(window.endpoint('user', '/delete-filter'), payload, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                this.loadFilters();
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );
    };

    loading(state) {
        window.Messenger.broadcast("SetLoadingState", { isLoading: state });
    };

    goToPage(page) {
        this.setState({ ...this.state, page: page });
    };

    newField(field) {
        let editField = this.state.editField;
        editField.targetField = field;
        editField.fieldName = field.value;
        editField.type = field.type;
        editField.label = field.label;
        editField.format = field.format;
        this.setState({ ...this.state, editField: editField, page: 3 });
    };

    addField() {
        this.setState({ ...this.state, editKey: null, page: 2, editField: {} });
    };

    editField(key) {
        let fields = this.state.filter.fields || [];
        let field = fields[key];
        field.targetField = this.state.fieldList.cherryPick(field.fieldName);
        console.log(field);
        this.setState({ ...this.state, editKey: key, page: 3, editField: field });
    };

    deleteField(key) {
        let fields = this.state.filter.fields || [];
        fields.splice(key, 1);
        this.setState({ ...this.state, filter: { ...this.state.filter, fields: fields } });
    };

    cancelEditField() {
        this.setState({ ...this.state, editKey: null, page: 1, editField: {} });
    };

    async saveField() {
        let fields = this.state.filter.fields || [];
        let field = this.state.editField;
        field.summary = [field.checkValue1, field.checkValue2].filter(item => { return item; }).join(' - ');
        if (this.state.editKey !== null) {
            fields[this.state.editKey] = field;
        } else {
            fields.push(field);
        }
        await this.setState({ ...this.state, filter: { ...this.state.filter, fields: fields }, page: 1 });
        this.cancelEditField();
        console.log(this.state.filter);
    };

    render() {

        const createFilterWindow = <>
            <Modal show={this.state.showCreateWindow} onHide={this.handleCloseCreateWindow} dialogClassName="x-modal-right" keyboard={true}>
                <div className={"modal-container"}>
                    <div className={"modal-titular"}>
                        <a className={"clickable pull-right"} onClick={this.handleCloseCreateWindow}>
                            <CloseIcon />
                        </a>
                        Manage Filters
                    </div>
                    <div className={"modal-body"}>
                        <Container>
                            <Row className={""}>
                                <Col md={4} className={"border-light p-10"}>
                                    {this.state.filters.map((filter, key) => (
                                        <Fragment key={key}>
                                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                                                <span className={"clickable"} onClick={() => this.editFilter(filter)}><strong>{filter.name}</strong></span> &nbsp;
                                                <span className={"clickable link-text text-danger pull-right"} onClick={() => this.deleteFilter(filter._id)}><i className={"fa fa-times"}></i></span>
                                            </div>
                                        </Fragment>
                                    ))}
                                </Col>
                                <Col md={8}>
                                    <div className={"m-t--20"}>

                                        <GenericField
                                            placeholder={"Filter Name"}
                                            hideLabel={true}
                                            type={"text"}
                                            name={"name"}
                                            value={this.state.filter.name}
                                            onChange={this.onChange}
                                        />
                                        <MaterialButton
                                            onClick={() => this.saveFilter()}
                                            szie={"small"}
                                            color={"primary"}
                                        >
                                            Save
                                        </MaterialButton> &nbsp;
                                        <MaterialButton
                                            onClick={() => this.deleteFilter()}
                                            szie={"small"}
                                            color={"secondary"}
                                        >
                                            Delete
                                        </MaterialButton>

                                    </div>
                                    <hr />
                                    {this.state.page === 1 &&
                                        <>
                                            <p>
                                                This filter has {F.recursivePath(this.state.filter, 'fields', []).length} field(s).
                                                &nbsp; <span
                                                    className={"clickable text-125"}
                                                    onClick={() => this.addField()}
                                                >
                                                    <i className={"fa fa-plus-circle text-primary"}></i>
                                                </span>
                                            </p>
                                            <div>
                                                {F.recursivePath(this.state.filter, 'fields', []).map((field, fieldKey) => (
                                                    <div className={"m-t-5 bg-lightest-green p-10"} key={fieldKey}>
                                                        <span className={"pull-right clickable"} onClick={() => this.deleteField(fieldKey)}>
                                                            <small><i className={"fa fa-times"}></i></small>
                                                        </span>
                                                        <span className={"clickable"} onClick={() => this.editField(fieldKey)}><strong>{field.label}</strong></span>&nbsp;&nbsp;
                                                        {field.summary &&
                                                            <>
                                                                <span>
                                                                    <small>{field.summary}</small>
                                                                </span>
                                                                <br />
                                                            </>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }
                                    {this.state.page === 2 &&
                                        <>
                                            <SingleSelect
                                                name={"targetField"}
                                                options={this.state.fieldList}
                                                onChange={(e) => this.newField(e)}
                                            />
                                        </>
                                    }
                                    {this.state.page === 3 &&
                                        <>

                                            <h4>{this.state.editField.label}</h4>

                                            {('text number').indexOf(this.state.editField.type) > -1 &&
                                                <>
                                                    <GenericField
                                                        label={"Lowest:"}
                                                        format={this.state.editField.format}
                                                        type={"text"}
                                                        name={"checkValue1"}
                                                        value={this.state.editField.checkValue1}
                                                        onChange={this.onChangeField}
                                                    />
                                                    <GenericField
                                                        label={"Highest:"}
                                                        type={"text"}
                                                        format={this.state.editField.format}
                                                        name={"checkValue2"}
                                                        value={this.state.editField.checkValue2}
                                                        onChange={this.onChangeField}
                                                    />
                                                </>
                                            }
                                            <br />
                                            <Button variant={"success"} onClick={() => this.saveField()}>Save</Button> &nbsp;
                                            <Button variant={"warning"} onClick={() => this.cancelEditField()}>Cancel</Button>
                                        </>
                                    }

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Modal>
        </>;

        return (
            <>
                {createFilterWindow}
            </>
        )

    }

}

export class Loader extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            showLoader: false
        };

        window.Messenger.receive("SetLoadingState", this.onLoader, this);

    }

    onLoader(eventClass, token, data) {
        this.setState({ ...this.state, showLoader: data.isLoading });
    }

    render() {
        return (
            <>
                {this.state.showLoader &&
                    <div className={"loader-overlay"}>
                        <div className="loader">
                            <svg className="circular" viewBox="25 25 50 50">
                                <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2"
                                    strokeMiterlimit="10" />
                            </svg>
                        </div>
                    </div>
                }
            </>
        )

    }

}

export class ClientAvatarDeprecated extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        let clientId = this.props.clientId;
        let source = '/user-icon.png';

        return (
            <>
                <img src={source} className={"search-result-avatar"} />
            </>
        )

    }

}

export class ClientAvatar extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        let source = (!this.props.src || this.props.src === 'empty') ? '/user-icon.png' : window.s3Host('/') + this.props.src;

        return (
            <>
                <img src={source} className={this.props.className} />
            </>
        )

    }

}

export class ChangeLog extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        let properties = [];
        let newData = F.recursivePath(this.props, "data.new", {});
        let oldData = F.recursivePath(this.props, "data.old", {});

        for (let p in newData) {
            if (properties.indexOf(p) === -1 && stopFields.indexOf(p) === -1) {
                properties.push(p);
            }
        }

        let oldCount = 0;
        let changeLog = [];

        for (let p = 0; p < properties.length; p++) {
            let oldEntry = oldData[properties[p]] || null;
            let newEntry = newData[properties[p]] || null;
            if (oldEntry) oldCount++;

            if (trackedTypes.indexOf(typeof oldEntry) !== -1 || trackedTypes.indexOf(typeof newEntry) !== -1) {
                if (oldEntry !== newEntry) changeLog.push({ field: properties[p], old: oldEntry, new: newEntry });
            } else {
                if (typeof newEntry === 'object' && Array.isArray(newEntry)) {

                }
            }

        }

        if (oldCount === 0) changeLog = [];

        return (
            <>
                {changeLog.map((change, key) => (
                    <Fragment key={key}>
                        {key > 0 &&
                            <>
                                &nbsp;...&nbsp;
                            </>
                        }
                        <span className={"change-log-field"}><strong>{change.field.toFriendlyCase()}: </strong></span>
                        <span className={"change-log-old-entry"}>
                            {change.old}
                            {!change.old &&
                                <>
                                    "blank"
                                </>
                            }
                        </span>
                        &nbsp;<i className={"fa fa-caret-right"}></i>&nbsp;
                        <span className={"change-log-new-entry"}>{change.new}</span>&nbsp;&nbsp;
                    </Fragment>
                ))}
            </>
        )

    }

}

export class DateSpan extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        let startDate = this.props.dates.start || "";
        let endDate = this.props.dates.end || "current";
        let range = '';

        if (startDate) {
            let startDateDate = moment(startDate).format("MMM D, YYYY");
            let endDateDate = endDate;
            if (endDate !== 'current') {
                endDateDate = moment(endDate).format("MMM D, YYYY");
            }
            let start = startDateDate;
            let end = endDateDate;
            range = start + " to " + end;

            let diff = Math.abs(moment(this.props.dates.start).diff(moment(this.props.dates.end || moment()), 'months'));
            let diffYears = Math.floor(diff / 12);
            let diffMonths = (diff - diffYears * 12);
            let diffString = diffYears > 0 ? diffYears + ' ' + window.pluralise(diffYears, "yr", "yrs") : '';

            if (diffYears > 0 && diffMonths > 0) {
                diffString += " " + diffMonths + ' ' + window.pluralise(diffMonths, "mth", "mths");
            } else if (diffMonths > 0 && diffYears === 0) {
                diffString = diffMonths + ' ' + window.pluralise(diffMonths, "mth", "mths");
            } else if (diffYears > 0 && diffMonths === 0) {
                diffString = diffYears + ' ' + window.pluralise(diffYears, "yr", "yrs");
            } else {
                diffString = 'less than a month';
            }

            range += " (" + diffString + ")";
            //+ `${JSON.stringify({diffYears, diffMonths, startDate, startDateDate, endDate, endDateDate})}`;
        }

        return (
            <>
                {range}
            </>
        )

    }

}

export class ShortenText extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            expandMode: false
        };

    };

    collapse(e) {
        e.preventDefault();
        this.setState({ ...this.state, expandMode: false });
    };

    expand(e) {
        e.preventDefault();
        this.setState({ ...this.state, expandMode: true });
    };

    render() {

        let text = this.props.text;
        let textExpanded = this.props.text;
        let textCollapsed = this.props.text;
        const maxLength = this.props.max || 32;
        let expander = null;
        let collapser = null;

        if (text.length > maxLength) {
            textCollapsed = text.substr(0, maxLength) + '...';
            collapser = <><i className={"fa fa-caret-left"}></i> <span className={"clickable"} onClick={(e) => this.collapse(e)}>less</span></>;
            expander = <><i className={"fa fa-caret-right"}></i> <span className={"clickable"} onClick={(e) => this.expand(e)}>more</span></>;
        }

        return (
            <span title={this.props.text}>
                {this.state.expandMode &&
                    <>{textExpanded}&nbsp;{collapser}</>
                }
                {!this.state.expandMode &&
                    <>{textCollapsed}&nbsp;{expander}</>
                }
            </span>
        )

    }

}

export class AuditLink extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        let href = '';
        let audit = this.props.audit;
        let section = audit.subjectClass;
        let link = '';

        const sections = {
            bio: 'bio',
            contact: 'contact',
            document: 'documents',
            comment: 'comments',
            reference: 'references',
            asset: 'assets',
            employment: 'employment',
        };

        if (audit.entityType === 'client') {
            href = '/app/clients/' + audit.entityId + '/!/';
            section = sections[audit.subjectClass];
            section = section || 'summary';
            href += section;
            link = <a href={href}><i className={"far fa-arrow-alt-circle-right text-info"}></i></a>;
        }

        if (audit.entityType === 'loan') {
            href = '/app/loans/' + audit.entityId + '/!/';
            section = sections[audit.subjectClass];
            section = section || 'summary';
            href += section;
            link = <a href={href}><i className={"far fa-arrow-alt-circle-right text-info"}></i></a>;
        }

        return (
            <span title={"View"}>
                {link}
            </span>
        )

    }

}

export class ByteSize extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        let suffix = 'b';
        let value = this.props.size || 0;
        let valueText = value;

        if (value > 1024) {
            valueText = Math.round(value / 1024);
            suffix = 'K';
        }
        if (value > (1024 * 1024)) {
            valueText = Math.round(value / 1024 / 1024);
            suffix = 'M';
        }
        if (value > (1024 * 1024 * 1024)) {
            valueText = Math.round(value / 1024 / 1024 / 1024);
            suffix = 'G';
        }

        return (
            <span title={this.props.size + " bytes"}>
                {valueText}{suffix}
            </span>
        )

    }

}

export class RandomMessage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        const messages = this.props.messages;
        const msgCount = messages.length;
        const index = Math.round(Math.random() * (msgCount - 1));
        let message = messages[index];

        return (
            <>
                {message}
            </>
        )

    }

}

export class Label extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {
        return (
            <>
                <label style={"background: red"}>TETS</label>
            </>
        )

    }

}

export class EmailText extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {
        const href = 'mailto:' + this.props.email;
        let hasEmail = (this.props.email || '').indexOf('@') > -1;
        return (
            <>
                {hasEmail && <a href={href} target={"_blank"}>{this.props.email}</a>}
                {!hasEmail && <>{this.props.email}</>}
            </>
        )

    }

}

export class PhoneNumber extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {
        const href = 'tel:+' + this.props.number;
        let hasPhoneNumber = this.props.number && (this.props.number || '').toLowerCase() != 'n/a';
        return (
            <>
                {hasPhoneNumber && <a href={href} target={"_blank"}>{this.props.number}</a>}
                {!hasPhoneNumber && <>{this.props.number}</>}
            </>
        )

    }

}

export class Paragraph extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {
        return (
            <>
                <p style={{ background: "red" }}>{this.props.content}</p>
            </>
        )

    }

}

export class Paging extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            keywords: ''
        };


    }

    setKeywords = keywords => {
        this.setState({ ...this.state, keywords });
        this.props.onChangeKeyword(keywords);
    };

    handleSearch = (key, fn) => {
        if (key === 'Enter') {
            return fn();
        }
    };

    componentDidMount() {
        console.log(this.props)
    }

    render() {

        const totalRecords = F.recursivePath(this.props.query, 'totalRecords', 0);
        const limit = F.recursivePath(this.props.query, 'limit', 25);
        const page = F.recursivePath(this.props.query, 'page', 1);
        const pageCount = Math.ceil(totalRecords / limit);

        const state = {
            onChange: this.props.onChange,
            parentStateHandler: this.props.parentStateHandler,
            parentState: this.props.parentState,
            query: this.props.query || {},
            totalRecords,
            limit,
            page,
            pageCount,
            start: pageCount > 0 ? Math.max(0, (page - 1) * limit + 1) : 0,
            end: pageCount > 0 ? Math.min(totalRecords, (page - 1) * limit + limit) : 0
        };

        const getPage = async (page) => {
            page = Math.max(1, Math.min(page, state.pageCount));
            let query = state.query;
            query.page = page;
            query.keywords = this.state.keywords || '';
            await state.parentStateHandler(query);
            state.onChange();
            window.scrollToHeader(0);
        };

        const advancePage = async (delta) => {
            let page = state.query.page + delta;
            getPage(page);
        };

        const firstButton = state.page > 1 && state.pageCount > 1 ? <>
            <Button variant={"default"} className={"clickable"} onClick={() => getPage(1)}><i className={"fa" +
                " fa-angle-double-left"}></i></Button>
        </> : <Button variant={"default"}><i className={"fa fa-angle-double-left text-disabled"}></i></Button>;
        const previousButton = state.page > 1 && state.pageCount > 1 ? <>
            <Button variant={"default"} className={"clickable"} onClick={() => advancePage(-1)}><i className={"fa" +
                " fa-angle-left"}></i></Button>
        </> : <Button variant={"default"}><i className={"fa fa-angle-left text-disabled"}></i></Button>;
        const nextButton = state.page < state.pageCount && state.pageCount > 1 ? <>
            <Button variant={"default"} className={"clickable"} onClick={() => advancePage(1)}><i className={"fa" +
                " fa-angle-right"}></i></Button>
        </> : <Button variant={"default"}><i className={"fa fa-angle-right text-disabled"}></i></Button>;
        const lastButton = state.page < state.pageCount && state.pageCount > 1 ? <>
            <Button variant={"default"} className={"clickable"} onClick={() => getPage(state.pageCount)}><i className={"fa" +
                " fa-angle-double-right"}></i></Button>
        </> : <Button variant={"default"}><i className={"fa fa-angle-double-right text-disabled"}></i></Button>;

        const searchField = <>
            <Input className={'table-search-field'} name={'keywords'} onChange={(e) => this.setKeywords(e.target.value)} onKeyDown={(e) => {
                this.setKeywords(e.target.value);
                this.handleSearch(e.key, () => getPage(page));
            }} />
        </>;


        return (
            <>
                &nbsp;&nbsp;{!this.props.hideSearch && <>{searchField}</>}&nbsp;&nbsp;{firstButton}{' '}{previousButton}{' '}<strong>{state.start}</strong>{' '}to{' '}<strong>{state.end}</strong>{' '}of{' '}<strong>{' '}{state.totalRecords}</strong>{' '}{nextButton}{' '}{lastButton}&nbsp;&nbsp;
            </>
        )

    }

}

export class CellValue extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        const getField = (record, column) => {
            let value = F.recursivePath(record, column.fieldName, '');
            let output = <>{value}</>;

            if (column.format === 'currency') {
                output = <>
                    <CurrencyText value={value} />
                </>;
            } else if (column.format === 'percentage') {
                output = <>
                    <PercentageText value={value} />
                </>;
            } else if (column.format === 'list') {
                try {
                    value = (value || []).join(', ');
                    output = <>
                        {value}
                    </>;
                } catch (e) {
                    console.log(value, "Couldn't join list because of a type issue.");
                    output = <>{value}</>;
                }
            } else if (column.format === 'decimal') {
                output = <>
                    <DecimalText value={value} />
                </>;
            } else if (column.format === 'date') {
                output = <>
                    <DateText value={value} />
                </>;
            } else if (column.format === 'integer') {
                output = <>
                    <IntegerText value={value} />
                </>;
            } else if (column.format === 'symbol') {
                value = value.replace('fortNight', 'fortnight');
                output = value.toFriendlyCase();
            }

            return output;
        };

        return (
            <>
                {getField(this.props.record, this.props.column)}
            </>
        )

    }

}

export class TotalCellValue extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        const getField = (record, column) => {
            let finalFieldName = (column.fieldName || '').replace('.', '____');
            let value = F.recursivePath(record, finalFieldName, '');
            let output = <></>;

            if (column.format === 'currency') {
                output = <>
                    <CurrencyText value={value} />
                </>;
            }

            return output;
        };

        return (
            <>
                {getField(this.props.totals, this.props.column)}
            </>
        )

    }

}

export class TotalRows extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        return (
            <tfoot>
                <tr>
                    {this.props.columns.map((column, key) => (
                        <td>
                            <TotalCellValue column={column} totals={this.props.totalRows.page} />
                        </td>
                    ))}
                </tr>
                <tr>
                    {this.props.columns.map((column, key) => (
                        <td>
                            <strong><TotalCellValue column={column} totals={this.props.totalRows.all} /></strong>
                        </td>
                    ))}
                </tr>
            </tfoot>
        )

    }

}

export class ApproveLoan extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            showModalWindow: false,
            loan: {},
            data: {},
            loanId: null,
        };

        this.onChange = this.onChangeHandler.bind(this);
        this.onChangeRadio = this.onChangeRadioHandler.bind(this);
        this.onChangeDate = this.onChangeDateHandler.bind(this);
        this.handleCloseModalWindow = this.onCloseModalWindow.bind(this);

        window.Messenger.receive("ApproveLoan", this.onApprovalRequest, this);

    };

    async onApprovalRequest(eventClass, token, data) {
        await this.setState({ ...this.state, showModalWindow: true, loanId: data.loanId });
        this.loadLoan();
    };

    onChangeHandler = async (event, section) => {
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.data;
        data[name] = value;
        await this.setState({ ...this.state, data: data });
        window.Messenger.broadcast('SetPageDirty', { isPageDirty: true });
    };

    onChangeDateHandler(field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onChangeRadioHandler(e, field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onCloseModalWindow() {
        if (this.state.isPageDirty) {
            if (window.confirm("You have unsaved changes. Still exit?")) {
                this.forceCloseApprovalWindow();
            }
        } else {
            this.forceCloseApprovalWindow();
        }
    };

    forceCloseApprovalWindow() {
        this.setState({ ...this.state, showModalWindow: false, data: {} });
    };

    async loadLoan() {
        this.loading(true);
        let payload = { id: this.state.loanId };
        await axios.post(window.endpoint('loans', '/get-loan'), payload, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                this.setState({ ...this.state, loan: result.data.loan });
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );
    };

    async postApproval() {
        this.loading(true);
        let payload = { id: this.state.loanId };
        let error = null;
        await axios.post(window.endpoint('loans', '/loan/approve'), payload, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                if (result.data.hasErrors === true) {
                    error = { ...result.data.notification, type: 'error' };
                }
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );

        if (error) {
            window.Messenger.broadcast('DisplayNotification', error);
        } else {
            this.forceCloseApprovalWindow();
            window.Messenger.broadcast('PostStatusActionUpdate', {});
        }
    };

    loading(state) {
        window.Messenger.broadcast("SetLoadingState", { isLoading: state });
    };

    goToPage(page) {
        this.setState({ ...this.state, page: page });
    };

    render() {

        const approvalModalWindow = <>
            <Modal show={this.state.showModalWindow} onHide={this.handleCloseModalWindow} dialogClassName="x-modal-right" keyboard={true}>
                <div className={"modal-container"}>
                    <div className={"modal-titular"}>
                        <a className={"clickable pull-right"} onClick={this.handleCloseCreateWindow}>
                            <CloseIcon />
                        </a>
                        Approve Loan
                    </div>
                    <div className={"modal-body"}>
                        <Container>
                            {this.state.loan._id &&
                                <Row className={""}>
                                    <Col md={12}>
                                        <div className={"bg-lightest-green p-20 radius-10"}>
                                            <h4>
                                                {F.recursivePath(this.state.loan, 'client.bio.fullName')}&nbsp;
                                                <a
                                                    href={"/app/clients/" + this.state.loan.clientId + "/!/summary"}
                                                    title={"View client in another window"}
                                                    target={"_blank"}
                                                >
                                                    <i className={"fa fa-external-link-alt"}></i>
                                                </a>
                                            </h4>
                                            Loan #:&nbsp;
                                            <strong>
                                                {F.recursivePath(this.state.loan, 'loanNumber')}
                                            </strong>&nbsp;
                                            <a
                                                href={"/app/loans/" + this.state.loan._id + "/!/summary"}
                                                title={"View loan in another window"}
                                                target={"_blank"}
                                            >
                                                <i className={"fa fa-external-link-alt"}></i>
                                            </a>
                                            &nbsp;&nbsp;&nbsp;
                                            Principal:&nbsp;
                                            <strong className={"text-danger"}>
                                                <CurrencyText
                                                    value={F.recursivePath(this.state.loan, 'principal', 0)} />
                                            </strong>
                                        </div>

                                        <hr />

                                        <MaterialButton
                                            variant={"contained"}
                                            color={"secondary"}
                                            onClick={() => this.postApproval()}
                                        >
                                            Approve
                                        </MaterialButton>
                                        &nbsp;&nbsp;
                                        <MaterialButton
                                            variant={"contained"}
                                            color={"default"}
                                            onClick={this.handleCloseModalWindow}
                                        >
                                            Cancel
                                        </MaterialButton>


                                    </Col>
                                </Row>
                            }
                        </Container>
                    </div>
                </div>
            </Modal>
        </>;

        return (
            <>
                {approvalModalWindow}
            </>
        )

    }

}

export class DisburseLoan extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            showModalWindow: false,
            loan: {},
            client: { bio: {} },
            data: {
                disbursementDetails: {}
            },
            loanId: null,
            fundingAccounts: [
                { label: 'Default (Scotia)', value: 'SCOTIA' },
                { label: 'Secondary (NCB)', value: 'NCB' },
                { label: 'FirstCaribbean Bank', value: 'firstCaribbeanBank' },
                { label: 'Sagicor Bank', value: 'sagicorBank' },
                { label: 'JMMB', value: 'JMMB' },
                { label: 'JN Bank', value: 'JNBank' },
            ]
        };

        this.onChange = this.onChangeHandler.bind(this);
        this.onChangeDisbursementDetails = this.onChangeDisbursementDetailsHandler.bind(this);
        this.onChangeRadio = this.onChangeRadioHandler.bind(this);
        this.onChangeDate = this.onChangeDateHandler.bind(this);
        this.handleCloseModalWindow = this.onCloseModalWindow.bind(this);

        window.Messenger.receive("DisburseLoan", this.onDisburseRequest, this);

    };

    async onDisburseRequest(eventClass, token, data) {
        await this.setState({ ...this.state, showModalWindow: true, loanId: data.loanId });
        this.loadLoan();
    };

    onChangeHandler = async (event, section) => {
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.data;
        data[name] = value;
        await this.setState({ ...this.state, data: data });
        window.Messenger.broadcast('SetPageDirty', { isPageDirty: true });
    };

    onChangeDisbursementDetailsHandler = async (event, section) => {
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.data;
        data.disbursementDetails = data.disbursementDetails || {};
        data.disbursementDetails[name] = value;
        await this.setState({ ...this.state, data: data });
        window.Messenger.broadcast('SetPageDirty', { isPageDirty: true });
    };

    onChangeDateHandler(field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onChangeRadioHandler(e, field, value) {
        this.onChangeHandler({ target: { name: field, value: value } });
    };

    onCloseModalWindow() {
        if (this.state.isPageDirty) {
            if (window.confirm("You have unsaved changes. Still exit?")) {
                this.forceCloseDisburseWindow();
            }
        } else {
            this.forceCloseDisburseWindow();
        }
    };

    forceCloseDisburseWindow() {
        this.setState({ ...this.state, showModalWindow: false, data: {} });
    };

    async loadLoan() {
        this.loading(true);
        let payload = { id: this.state.loanId };
        await axios.post(window.endpoint('loans', '/get-loan'), payload, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                this.setState({ ...this.state, loan: result.data.loan, client: result.data.client });
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );
    };

    async postDisbursal() {
        this.loading(true);
        let payload = { id: this.state.loanId, payload: this.state.data };
        let error = null;
        await axios.post(window.endpoint('loans', '/loan/disburse'), payload, window.getConfig()).then(result => {
            if (result.data) {
                this.loading(false);
                if (result.data.hasErrors === true) {
                    error = { ...result.data.notification, type: 'error' };
                }
            }
        }).catch(
            error => {
                this.loading(false);
                window.Messenger.broadcast('DisplayNotification', { type: 'error' });
            }
        );

        if (error) {
            window.Messenger.broadcast('DisplayNotification', error);
        } else {
            this.forceCloseDisburseWindow();
            window.Messenger.broadcast('PostStatusActionUpdate', {});
        }
    };

    loading(state) {
        window.Messenger.broadcast("SetLoadingState", { isLoading: state });
    };

    goToPage(page) {
        this.setState({ ...this.state, page: page });
    };

    render() {

        const disbursalModalWindow = <>
            <Modal show={this.state.showModalWindow} onHide={this.handleCloseModalWindow} dialogClassName="x-modal-right" keyboard={true}>
                <div className={"modal-container"}>
                    <div className={"modal-titular"}>
                        <a className={"clickable pull-right"} onClick={this.handleCloseCreateWindow}>
                            <CloseIcon />
                        </a>
                        Disburse Loan
                    </div>
                    <div className={"modal-body"}>
                        <Container>
                            {this.state.loan._id &&
                                <Row className={""}>
                                    <Col md={12}>
                                        <div className={"bg-lightest-green p-20 radius-10"}>
                                            <h4>
                                                {F.recursivePath(this.state.loan, 'client.bio.fullName')}&nbsp;
                                                <a
                                                    href={"/app/clients/" + this.state.loan.clientId + "/!/summary"}
                                                    title={"View client in another window"}
                                                    target={"_blank"}
                                                >
                                                    <i className={"fa fa-external-link-alt"}></i>
                                                </a>
                                            </h4>
                                            Loan #:&nbsp;
                                            <strong>
                                                {F.recursivePath(this.state.loan, 'loanNumber')}
                                            </strong>&nbsp;
                                            <a
                                                href={"/app/loans/" + this.state.loan._id + "/!/summary"}
                                                title={"View loan in another window"}
                                                target={"_blank"}
                                            >
                                                <i className={"fa fa-external-link-alt"}></i>
                                            </a>
                                            &nbsp;&nbsp;&nbsp;
                                            Amount for disbursal:&nbsp;
                                            <strong className={"text-danger"}>
                                                <CurrencyText
                                                    value={F.recursivePath(this.state.loan, 'principal', 0)} />
                                            </strong>
                                        </div>
                                        <br />
                                        <Row>
                                            <Col md={6}>
                                                <GenericField
                                                    name={"method"}
                                                    type={"dropdown"}
                                                    list={"disbursementMethods"}
                                                    value={this.state.data.method}
                                                    onChange={this.onChange}
                                                    label={"Disbursement Method"}
                                                    required={true}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <GenericField
                                                    name={"fundingAccount"}
                                                    type={"dropdown"}
                                                    list={this.state.fundingAccounts}
                                                    value={this.state.data.fundingAccount}
                                                    onChange={this.onChange}
                                                    label={"Funding Account"}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6}>
                                                <GenericField
                                                    name={"channel"}
                                                    type={"dropdown"}
                                                    list={"repaymentChannels"}
                                                    value={this.state.data.channel}
                                                    onChange={this.onChange}
                                                    label={"Repayment Channel"}
                                                    required={true}
                                                />
                                            </Col>
                                        </Row>
                                        {this.state.data.method === 'cheque' &&
                                            <>
                                                <p className={"push-down"}><strong>Cheque Details:</strong></p>
                                                <Row className={"push-down"}>
                                                    <Col md={6}>
                                                        <GenericField
                                                            name={"recipientName"}
                                                            value={F.recursivePath(this.state.data, 'disbursementDetails.recipientName', '')}
                                                            type={"text"}
                                                            onChange={this.onChangeDisbursementDetails}
                                                            label={"Recipient Name"}
                                                            required={true}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <GenericField
                                                            name={"chequeNumber"}
                                                            value={F.recursivePath(this.state.data, 'disbursementDetails.chequeNumber', '')}
                                                            type={"text"}
                                                            onChange={this.onChangeDisbursementDetails}
                                                            label={"Recipient Cheque Number"}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {this.state.data.method === 'bankTransfer' &&
                                            <>
                                                <p className={"push-down"}><strong>Bank Account Details:</strong></p>
                                                <Row className={"push-down"}>
                                                    <Col md={6}>
                                                        <GenericField
                                                            name={"bankName"}
                                                            value={F.recursivePath(this.state.data, 'disbursementDetails.bankName', '')}
                                                            type={"text"}
                                                            onChange={this.onChangeDisbursementDetails}
                                                            label={"Bank Name"}
                                                            required={true}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <GenericField
                                                            name={"bankAccountType"}
                                                            value={F.recursivePath(this.state.data, 'disbursementDetails.bankAccountType', '')}
                                                            type={"dropdown"}
                                                            list={Lists.bankAccountTypes}
                                                            onChange={this.onChangeDisbursementDetails}
                                                            label={"Account Type"}
                                                            required={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col md={6}>
                                                        <GenericField
                                                            name={"bankAccountNumber"}
                                                            value={F.recursivePath(this.state.data, 'disbursementDetails.bankAccountNumber', '')}
                                                            type={"text"}
                                                            onChange={this.onChangeDisbursementDetails}
                                                            label={"Account Number"}
                                                            required={true}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <GenericField
                                                            name={"bankBranch"}
                                                            value={F.recursivePath(this.state.data, 'disbursementDetails.bankBranch', '')}
                                                            type={"text"}
                                                            onChange={this.onChangeDisbursementDetails}
                                                            label={"Branch"}
                                                            required={false}
                                                        />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col md={6}>
                                                        <GenericField
                                                            name={"bankTransitNumber"}
                                                            value={F.recursivePath(this.state.data, 'disbursementDetails.bankTransitNumber', '')}
                                                            type={"text"}
                                                            onChange={this.onChangeDisbursementDetails}
                                                            label={"Transit Number"}
                                                            required={false}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <br />
                                        <GenericField
                                            name={"transactionDate"}
                                            value={this.state.data.transactionDate}
                                            type={"date"}
                                            list={this.state.transactionDate}
                                            onChangeDate={this.onChangeDate}
                                            label={"Actual Transaction Date (leave blank for current date)"}
                                        />
                                        <br />
                                        <GenericField
                                            name={"notes"}
                                            value={this.state.data.notes}
                                            type={"textarea"}
                                            onChange={this.onChange}
                                            label={"Notes"}
                                        />

                                        <hr />

                                        <MaterialButton
                                            variant={"contained"}
                                            color={"secondary"}
                                            onClick={() => this.postDisbursal()}
                                        >
                                            Disburse
                                        </MaterialButton>
                                        &nbsp;&nbsp;
                                        <MaterialButton
                                            variant={"contained"}
                                            color={"default"}
                                            onClick={this.handleCloseModalWindow}
                                        >
                                            Cancel
                                        </MaterialButton>


                                    </Col>
                                </Row>
                            }
                        </Container>
                    </div>
                </div>
            </Modal>
        </>;

        return (
            <>
                {disbursalModalWindow}
            </>
        )

    }

}

export class ClientProfileHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        let client = this.props.client || {};

        let privileges = [];

        if ( localStorage.userPermissions.split(',').includes('requestClearedIdentityVerification') ) {
            privileges.push('createIdentityVerificationRequest');
        }

        if ( localStorage.userPermissions.split(',').includes('requestClearedAddressVerification') ) {
            privileges.push('createAddressVerificationRequest');
        }

        return (
            <div className={"profile-header-container"}>
                <Row>
                    <Col md={8}>
                        <a href={"/app/clients/" + client._id + "/!/bio"}>
                            <AccessControl required={"viewClientAvatars"} fallback={<ClientAvatar
                                src={null}
                                className={"search-result-avatar profile-header-avatar"}
                            />}>
                                <ClientAvatar
                                    src={client.avatar}
                                    className={"search-result-avatar profile-header-avatar"}
                                />
                            </AccessControl>
                        </a>
                        <span className={"profile-name"}>
                            &nbsp;&nbsp;<ShortenText text={window.getFullName(client.bio)} max={32} href={"/app/clients/" + client._id + "/!/bio"} hrefClasses={"no-decoration"} />&nbsp;
                            <span className={`radius-20 customer-classification customer-classification-` + client.classification.trim().toLowerCase().replace(' ', '-')}>{client.classification || 'Elite'}<sup><MdStar /></sup></span>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <AccessControl required={"viewClearedVerificationStatus"} fallback={<></>}>
                            <VerificationStatus customerData={{ 
                                    name: client.bio.fullName, 
                                    trn: client.bio.trn, 
                                    emailAddress: F.recursivePath(client, 'contact.emailAddresses.0.email', ''), 
                                    phoneNumber: F.recursivePath(client, 'contact.phoneNumbers.0.phoneNumber', '') 
                                }} 
                                services={['identity', 'address']} 
                                privileges={privileges} />
                        </AccessControl>
                    </Col>
                    <Col md={4} className={'text-right'}>
                        {this.props.userList}
                    </Col>
                </Row>
            </div>
        )
    }
}

export class Element extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
        };

    }

    render() {

        const element = this.props.element;
        let output = null;

        if (element.type === 'paragraph') {
            output = <Paragraph text={element.content} />;
        }

        if (element.type === 'dropdown') {
            output = <SelectField
                name={element.fieldName}
                list={element.options || Lists.getList(element.list)}
                onChange={this.props.onChange ? this.props.onChange : (e) => this.props.parent.onChange(e)}
                value={this.props.value ? this.props.value : this.props.parentState.data[element.fieldName]}
                className={"form-select-custom"}
                placeholder={element.placeholder}
                disableUnderline={true}
                showLabel={this.props.showLabel !== undefined ? this.props.showLabel : true}
                label={element.label}
                htmlLabel={element.htmlLabel}
                readOnly={element.readOnly}
                parent={this.props.parent}
            />;
        }

        if (element.type === 'radio') {
            output = <>
                <label className={"form-label block"}>{element.number && <>{element.number}. &nbsp;</>}{element.label}</label>
                {element.options.map((option, key) => (
                    <RadioField
                        key={key}
                        name={element.fieldName}
                        onChange={(e) => this.props.parent.onChangeRadio(e, element.fieldName, option.value)}
                        value={option.value}
                        className={"no-border no-bold-deep text-black-deep"}
                        label={option.label}
                        checked={F.recursivePath(this.props.parent, 'state.data.' + element.fieldName) === option.value}
                    />
                ))}
            </>;
        }

        if (element.type === 'checkbox') {
            output = <Form.Check
                name={element.fieldName}
                onChange={(e) => this.props.parent.onChangeCheck(e, element.fieldName)}
                value={this.props.value ? this.props.value : this.props.parentState.data[element.fieldName]}
                checked={this.props.parentState.data[element.fieldName] === true}
                className={"no-border no-bold-deep text-black-deep"}
                showLabel={this.props.showLabel !== undefined ? this.props.showLabel : true}
                label={element.label}
            />;
        }

        let whichLabel = !element.htmlLabel ? <label className={"form-label block"}>{element.label}</label> : <label className={"form-label block"} dangerouslySetInnerHTML={{ __html: element.htmlLabel }}></label>;

        if ('text, date, textarea, password, number'.indexOf(element.type) !== -1) {
            output = <>
                {!element.hideLabel && whichLabel}

                {element.mask &&
                    <>
                        <div className="MuiInputBase-root MuiInput-root MuiInput-underline w-100 mb-3 push-down-input">
                            <MaskedInput
                                mask={element.mask !== 'email' ? (element.mask !== 'decimal' ? masks[element.mask] : createNumberMask) : emailMask}
                                name={(element.fieldName || element.name) || this.props.name}
                                type={element.type}
                                onChange={this.props.onChange ? this.props.onChange : (e) => this.props.parent.onChange(e)}
                                value={this.props.value ? this.props.value : this.props.parentState.data[element.fieldName || element.name]}
                                className={"MuiInputBase-input MuiInput-input"}
                                readOnly={element.readOnly}
                            />
                        </div>
                    </>
                }
                {!element.mask &&
                    <>
                        <TextInputField
                            name={element.fieldName}
                            type={element.type}
                            value={this.props.value ? this.props.value : this.props.parentState.data[element.fieldName]}
                            onChange={this.props.onChange ? this.props.onChange : (e) => this.props.parent.onChange(e)}
                            readOnly={element.readOnly}
                            max={element.max}
                            min={element.min}
                        />
                    </>
                }
                {element.subLabel &&
                    <>
                        <Label className={"sub-label"} content={element.subLabel} />
                    </>}
            </>;
        }

        if ('heading'.indexOf(element.type) !== -1) {
            output = <h3>
                {element.number && <>{element.number}. &nbsp;</>}
                <span dangerouslySetInnerHTML={{ __html: element.content }}></span>
            </h3>;
        }

        if ('html'.indexOf(element.type) !== -1) {
            output = <>
                <div dangerouslySetInnerHTML={{ __html: element.content }}></div>
            </>;
        }

        if ('subHeading'.indexOf(element.type) !== -1) {
            output = <>
                <h5>{element.number && <>{element.number}. &nbsp;</>}{element.content}</h5>
            </>;
        }

        if ('paragraph'.indexOf(element.type) !== -1) {
            output = <>
                <p dangerouslySetInnerHTML={{ __html: F.replaceFields(element.content, this.props.parent) }}></p>
            </>;
        }

        if ('label'.indexOf(element.type) !== -1) {
            output = <>
                <label>{element.number && <>{element.number}. &nbsp;</>}{element.content}</label>
            </>;
        }

        return (
            <>
                {output}
            </>
        )

    }

}

export class FormElement extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            editMode: null,
            editEntry: {},
            editKey: null
        };

    }

    addDataItem(fieldName) {
        let currentList = this.props.parentState.data[fieldName] || [];
        currentList.push({});
        let editKey = currentList.length - 1;
        this.props.parent.setState({ ...this.props.parentState, data: { ...this.props.parentState.data, [fieldName]: currentList } });
        this.setState({ ...this.state, editMode: fieldName, editEntry: {}, editKey: editKey });
    };

    editDataItem(entry, fieldName, key) {
        this.setState({ ...this.state, editMode: fieldName, editEntry: entry, editKey: key });
    };

    saveDataItem() {
        let currentList = this.props.parentState.data[this.state.editMode];
        currentList[this.state.editKey] = this.state.editEntry;
        this.props.parent.setState({ ...this.props.parentState, data: { ...this.props.parentState.data, [this.state.editMode]: currentList } });
        console.log(this.state.editEntry);
        this.cancelEditDataItem();
    };

    deleteDataItem() {
        let currentList = this.props.parentState.data[this.state.editMode];
        currentList.splice(this.state.editKey, 1);
        this.props.parent.setState({ ...this.props.parentState, data: { ...this.props.parentState.data, [this.state.editMode]: currentList } });
        this.cancelEditDataItemFinal();
    };

    cancelEditDataItem() {
        let currentList = this.props.parentState.data[this.state.editMode];
        for (let i = 0; i < currentList.length; i++) {
            if (F.isEmptyObject(currentList[i])) {
                currentList.splice(i, 1);
            }
        }
        this.props.parent.setState({ ...this.props.parentState, data: { ...this.props.parentState.data, [this.state.editMode]: currentList } });
        this.setState({ ...this.state, editMode: null, editEntry: null, editKey: null });
    };

    cancelEditDataItemFinal() {
        this.setState({ ...this.state, editMode: null, editEntry: null, editKey: null });
    };

    onChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        let editEntry = { ...this.state.editEntry, [name]: value };
        this.setState({ ...this.state, editEntry: editEntry });
    };

    getField(entry, column) {
        let value = entry[column.fieldName];
        if (column.type === 'dropdown') {
            value = Lists.findByValue(column.list, value);
        }
        return value;
    };

    render() {

        const element = this.props.element || {};
        const showElement = !element || this.props.section === element.section || !this.props.useSection;
        let print = null;

        if (element.type !== 'tableList' && element.type !== 'list') {
            print = <Element
                element={element}
                section={element.section}
                parent={this.props.parent}
                parentState={this.props.parentState}
            />;
        }

        if (element.type === 'tableList') {
            let targetValue = this.props.parentState.data[element.fieldName];
            if (!targetValue) {
                this.props.parent.setState({ ...this.props.parentState, data: { ...this.props.parentState.data, [element.fieldName]: [] } });
            }
            print = <>
                {element.rows &&
                    <>
                        <Table striped>
                            <thead>
                                <tr>
                                    {element.headings.map((heading, key) => (
                                        <th key={key}>{heading}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {element.rows.map((row, key) => (
                                    <tr key={key}>
                                        {element.rowHeadings && <th dangerouslySetInnerHTML={{ __html: element.rowHeadings[key] }}></th>}
                                        {row.map((cell, cellKey) => (
                                            <td key={cellKey}>
                                                <>
                                                    <Element
                                                        value={(F.recursivePath(this.props.parent, "state.data." + element.fieldName, [])[key] || {})[cell.fieldName] || ''}
                                                        type={cell.type}
                                                        element={cell}
                                                        parent={this.props.parent}
                                                        parentState={this.props.parentState}
                                                        onChange={(e) => this.props.parent.onChangeCell(e, element.fieldName, cell.fieldName, key)}
                                                        showLabel={false}
                                                        inline={true}
                                                    />
                                                </>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                }

                {element.columns &&
                    <>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    {element.columns.map((column, key) => (
                                        <th key={key}>
                                            {column.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {(this.props.parentState.data[element.fieldName] || []).map((entry, key) => (
                                    <tr key={key} onClick={() => this.editDataItem(entry, element.fieldName, key)}>
                                        <td>{key + 1}</td>
                                        {element.columns.map((column, key) => (
                                            <td key={key}>{this.getField(entry, column)}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        {this.state.editMode === element.fieldName &&
                            <Paper className={"inline-form-sheet"}>
                                <Button onClick={() => this.saveDataItem()} color={"primary"}>Save Item</Button> <Button
                                    onClick={() => this.cancelEditDataItem()} color={"default"}>Cancel</Button> <Button
                                        onClick={() => this.deleteDataItem()} color={"secondary"}>Delete</Button>
                                <hr />
                                {element.columns.map((field, key) => (
                                    <Form.Group key={key}>
                                        <Element
                                            value={F.recursivePath(this.state.editEntry, field.fieldName)}
                                            type={field.type}
                                            element={field}
                                            parent={this.props.parent}
                                            parentState={this.props.parentState}
                                            onChange={(e) => this.onChange(e)}
                                            showLabel={true}
                                            inline={true}
                                        />
                                    </Form.Group>
                                ))}
                                <hr />
                                <Button onClick={() => this.saveDataItem()} color={"primary"}>Save Item</Button> <Button
                                    onClick={() => this.cancelEditDataItem()} color={"default"}>Cancel</Button> <Button
                                        onClick={() => this.deleteDataItem()} color={"secondary"}>Delete</Button>
                            </Paper>
                        }

                        {this.state.editMode !== element.fieldName &&
                            <Button onClick={() => this.addDataItem(element.fieldName)} color={"primary"} variant={"contained"}>Add
                                Row</Button>
                        }
                        <hr />
                    </>
                }
            </>;
        }

        if (element.type === 'list') {
            print = <>
                <label className={"form-label block"}>{element.number && <>{element.number}. &nbsp;</>}{element.label}</label>
                {(element.fields || []).map((field, key) => (
                    <Element
                        key={key}
                        element={field}
                        parent={this.props.parent}
                        parentState={this.props.parentState}
                    />
                ))}
            </>;
        }

        return (
            <>
                {showElement &&
                    <>
                        {print}
                    </>
                }
            </>
        )

    }

}

export class StandardElement extends React.Component {

    constructor(props) {
        super(props);
        let state = {

        };
        this.state = state;
    }

    render() {
        let elementProps = FormElements[this.props.identifier];
        let element = null,
            label = null;
        let requiredIndicator = (this.props.required || elementProps.required) ? <span className={"text-danger"}>*</span> : '';

        label = <>
            <label className={"form-label block"}>
                {elementProps.label || this.props.label} {requiredIndicator}
            </label>
        </>;

        if (('text, password, textarea, date, number').indexOf(elementProps.type) !== -1) {

            if (!elementProps.mask) {
                element = <TextInputField
                    name={elementProps.name || this.props.name}
                    type={elementProps.type || this.props.type}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    min={0}
                />;
            } else {
                element =

                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline w-100 mb-3 push-down-input">
                        <MaskedInput
                            mask={elementProps.mask !== 'email' ? (elementProps.mask !== 'decimal' ? masks[elementProps.mask] : createNumberMask) : emailMask}
                            name={(elementProps.fieldName || elementProps.name) || this.props.name}
                            type={elementProps.type}
                            onChange={this.props.onChange}
                            value={this.props.value || ''}
                            className={"MuiInputBase-input MuiInput-input"}
                        />
                    </div>;
            }
        }

        if (elementProps.type === 'dropdown') {
            element = <SelectField
                name={elementProps.name || (elementProps.fieldName || this.props.name)}
                value={this.props.value}
                onChange={this.props.onChange}
                readOnly={elementProps.readOnly}
                list={Lists.getList(elementProps.list)}
            />
        }

        return (
            <>
                {label}
                {element}
            </>
        )
    }

}

export class PenaltyLog extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            showDetails: false
        };

    }

    setShowDetails = showDetails => this.setState({ ...this.state, showDetails });

    render() {

        const penaltyLog = F.recursivePath(this.props.instalment, 'meta.penaltyLog', {});

        const detailsWindow = <>
            <Modal
                centered
                size={'lg'}
                show={this.state.showDetails}
                fade={true}
                toggle={() => this.setShowDetails(false)}
            >
                <ModalHeader toggle={() => this.setShowDetails(false)}>
                    Penalty Log
                </ModalHeader>
                <ModalBody>
                    {penaltyLog &&
                        <Table borderless striped={true}>
                            <thead>
                                <tr>
                                    <th>Charge Date</th>
                                    <th>Instalment Due Date</th>
                                    <th>Date of Last Penalty</th>
                                    <th>Total Due</th>
                                    <th>Iterations</th>
                                    <th>New Penalty</th>
                                    <th>Final Penalty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(penaltyLog).map((logDate, key) => (
                                    <tr key={key}>
                                        <td><DateText date={logDate} /></td>
                                        <td><DateText date={penaltyLog[logDate].dueDate} /></td>
                                        <td title={F.recursivePath(penaltyLog[logDate], 'lastPenalty.note', '')}><DateText date={F.recursivePath(penaltyLog[logDate], 'lastPenalty.date', null)} /></td>
                                        <td><CurrencyText value={penaltyLog[logDate].totalDue} /></td>
                                        <td>{penaltyLog[logDate].iterations}</td>
                                        <td><CurrencyText value={penaltyLog[logDate].penaltyApplied} /></td>
                                        <td><CurrencyText value={penaltyLog[logDate].finalPenaltyAmount} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                    <hr />
                    <small>
                        <span className={"text-grey"}>
                            Instalment ID: {(this.props.instalment._id || '').toUpperCase()}
                        </span>
                    </small>
                    <div className={"text-center"}>
                        <Button centerRipple variant={"contained"} color={"default"} onClick={() => this.setShowDetails(false)}>OK</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>;

        return (
            <>
                {detailsWindow}
                {Object.keys(penaltyLog).length > 0 &&
                    <>
                        &nbsp;<span className={'clickable text-warning'} onClick={() => this.setShowDetails(true)}><i
                            className={'fa fa-info-circle'} /></span>
                    </>
                }
            </>
        )

    }

}