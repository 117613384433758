import React, {Fragment, useEffect} from 'react';
import Alert from '@material-ui/lab/Alert';
import {connect} from 'react-redux';

const AccessControl = props => {

    useEffect( () => {

    });

    let fallback = props.fallback || null;
    let permissions = localStorage.getItem('userPermissions');

    if ( !permissions || (permissions || []).length === 0 ) {
        permissions = props.globalState.permissions || []
    } else {
        permissions = permissions.split(',');
    }

    if ( !Array.isArray(permissions) ) permissions = permissions ? permissions.split(',').filter(item => item.trim()) : [];

    let requiredPermissions = props.required || ['noAccess'];
    if ( !Array.isArray(requiredPermissions) ) requiredPermissions = requiredPermissions.split(',').filter(item => item.trim());

    let matchType = props.match || 'all';
    let allowed = false;
    if ( matchType === 'any' ) {
        allowed = permissions.includesSome(requiredPermissions);
    } else {
        allowed = permissions.includesAll(requiredPermissions);
    }

    const errorMessages = {
        default: <>
            <hr />
            <Alert severity={"error"} className={"push-down"}>
                <h3 className={"no-bold text-center"}>
                    You do not have permission to view this section.
                </h3>
            </Alert>
        </>,
        short: <>
            <span className={"no-bold text-danger"}>
                Access Denied
            </span>
        </>,
    };

    let errorMessage = props.errorMessage;

    if ( props.displayError ) {
        if ( !errorMessage && props.errorMessageType ) {
            if ( errorMessages[props.errorMessageType] ) {
                errorMessage = errorMessages[props.errorMessageType];
            }
        }
        if ( !errorMessage ) {
            errorMessage = errorMessages.default;
        }
    }

    if ( permissions.includes('fullPower') ) allowed = true;

    return (
        <>
            {permissions &&
            <>{allowed &&
            <>
                {props.children}
            </>
            }
                {!allowed &&
                <>
                    {fallback || errorMessage}
                </>
                }
            </>
            }
            {!permissions &&
            <>
            </>
            }
        </>
    );

};

AccessControl.propTypes = {
};

const mapStateToProps = state => ({
    globalState: state.globalState,
});

export default connect(mapStateToProps)(AccessControl);
