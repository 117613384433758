import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import moment from "moment";
import A from "./util/Arithmetic";

if ( window.location.href.indexOf('/signin') < 0
    && window.location.href.indexOf('widget') < 0
) {
  window.localStorage.setItem('pageHistory', window.location.href);
}

window.environment = () => {
  let env = 'dev';

  if ( window.location.href.indexOf('app.regionsfs.com') !== -1
      || window.location.href.indexOf('live.regionsfs.com') !== -1 ) {
    env = 'live';
  } else if ( window.location.href.indexOf('app2.regionsfs.com') !== -1
      || window.location.href.indexOf('live2.regionsfs.com') !== -1 ) {
    env = 'live2';
  } else if ( window.location.href.indexOf('test.regionsfs.com') !== -1
      || window.location.href.indexOf('sandbox.regionsfs.com') !== -1 ) {
    env = 'test';
  }

  return env;
};

if(window.environment().indexOf('dev')<0&&window.location.protocol!=='https:'){document.write('Redirecting to secured application...');window.location.replace(window.location.href.replace('http:', 'https:'));}

const releaseStages = {
  dev: 'development',
  test: 'test',
  live: 'production'
};

const environment = window.environment();

window.getThemeClasses = section => {
  if ( environment !== 'live' ) {
    let classes = {
      header: "app-main-header-test",
      toolbar: "app-toolbar-test"
    }
    return classes[section];
  }
};

Bugsnag.start({
  apiKey: '6f7bcc2100c9b161c6e2652308b3e082',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'test'],
  releaseStage: releaseStages[environment]
});

window.getFullName = function (object, useInitial) {
  return object ? [object.firstName || '', !useInitial ? object.middleName || '' : (object.middleName || '').initial(true), object.lastName || ''].filter(name => {return name.length;}).join(' ') : '';
};

window.expandInstalment = (instalment) => {
  instalment.totalPaid = A.add([
    instalment.principalPaid,
    instalment.interestPaid,
    instalment.feePaid,
    instalment.penaltyPaid,
  ]);
  instalment.totalWrittenOff = A.add([
    instalment.principalWrittenOff,
    instalment.interestWrittenOff,
    instalment.feeWrittenOff,
    instalment.penaltyWrittenOff,
  ]);
  return (
      ( instalment.totalPaid.toFixed(2) !== "0.00"
      || instalment.totalWrittenOff.toFixed(2) !== "0.00" )
      && ( instalment.totalBalance > 0.01
       || instalment.totalWrittenOff > 0 ) )
      ;
};

window.getPrimaryPhoneNumber = (contact) => {
  const primaryPhoneNumbers = contact.phoneNumbers.filter((phoneNumber) => {
    return phoneNumber.isPrimary;
  });
  return ( ( primaryPhoneNumbers.length > 0 ? primaryPhoneNumbers[0] : (contact.phoneNumbers[0] ) ) || {} ).phoneNumber;
};

window.getPrimaryEmailAddress = (contact) => {
  const primaryEmailAddresses = contact.emailAddresses.filter((emailAddress) => {
    return emailAddress.isPrimary;
  });
  return ( ( primaryEmailAddresses.length > 0 ? primaryEmailAddresses[0] : contact.emailAddresses[0] ) || {}).email;
};

window.friendlyCase = function(string) {
  if ( !string ) return '';
  let result = string.replace( /([A-Z])/g, " $1" );
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  let knownWords = {
    "Trn": 'TRN',
    "Date Of Birth": 'Date of Birth',
  }
  for ( let w in knownWords ) {
    if ( finalResult === w ) {
      return knownWords[w];
    }
  }
  return finalResult.replace('Ncb', 'NCB')
                    .replace('Bns', 'BNS');
};

window.titleCase = function(string) {
  if ( !string.trim() ) return '';
  let text = string.split(" ");
  for(let i = 0; i < text.length; i++){
    text[i] = text[i][0].toUpperCase() + text[i].slice(1);
  }
  return text.join(" ");
};

window.transformList = function(list, transformation) {
  for ( let i = 0; i < list.length; i++ ) {
    let item = list[i];
    for (let f in transformation) {
      if (transformation.hasOwnProperty(f)) {
        item[transformation[f]] = item[f];
      }
    }
  }
  return list;
};

window.pluralise = (value, singular, plural) => {
  return value === 1 ? singular : plural;
};

window.endpoint = (service, uri) => {
  let key = 'REACT_APP_API_HOST_' + service + '_' + environment;

  return process.env[key.toUpperCase()] + '/api' + uri;
};

window.socketEndpoint = () => {
  let key = 'REACT_APP_API_HOST_SOCKET_' + environment;
  return process.env[key.toUpperCase()] + '/';
};

window.getAdminLink = () => {
  let key = 'REACT_APP_API_HOST_ADMINFRONTEND_' + environment;
  return process.env[key.toUpperCase()];
};

window.time = () => {
  return Math.round(new Date().getTime()/1000);
};

window.s3Host = function(path) {
  return 'https://rfs-document-service-test.s3-us-west-2.amazonaws.com' + path;
};

window.getConfig = () => {
  let userId = localStorage.getItem('userId');
  let accessToken = localStorage.getItem('accessToken');
  let timezoneOffset = new Date().getTimezoneOffset();
  return {headers: {userId, accessToken, timezoneOffset}};
};

window.query = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

Array.prototype.has = function(field, value) {
  for ( let i = 0; i < this.length; i++ ) {
    let item = this[i] || {};
    if ( item[field] !== undefined ) {
      if ( item[field] === ( value || true ) ) {
        return true;
      }
    }
  }
  return false;
};

Array.prototype.cherryPick = function(value) {
  let picked = this.filter(item => {return item.value === value});
  return picked.length ? picked[0] : null;
};

window.canEditDate = (object, field) => {
  let targetDate = moment(object[field]);
  let today = moment();
  return targetDate.isAfter(today) && !(object.totalPaid > 0);
};

window.logout = () => {
  localStorage.removeItem('user_id');
  localStorage.removeItem('userId');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userName');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userPermissions');
  localStorage.removeItem('passwordExpired');
  window.location.assign(`/signin?.cm=inactivity&url=${encodeURIComponent(window.location.href)}`);
};

Array.prototype.cherryPickAll = function(field) {
  let values = [];
  for ( let a = 0; a < this.length; a++ ) {
    values.push(this[a][field]);
  }
  return values;
};

Array.prototype.includesAll = function(values) {
  if ( values.length < 1 ) {
    return false;
  }
  if ( this.includes(values[0]) ) {
    if ( values.length === 1 ) return true;
    return this.includesAll(values.slice(1));
  } else {
    return false;
  }
};

Array.prototype.includesSome = function(values) {
  if ( values.length < 1 ) {
    return false;
  }
  if ( this.includes(values[0]) ) {
    return true;
  } else {
    return this.includesSome(values.slice(1));
  }
};

Array.prototype.hasAccess = function(values) {
  if ( !Array.isArray(values ) ) {
    values = values.split(',').filter(item => item.trim());
  }
  console.log(values);
  return this.includesAll(values) || this.includes('fullPower');
};

Array.prototype.hasAnyAccess = function(values) {
  if ( !Array.isArray(values ) ) {
    values = values.split(',').filter(item => item.trim());
  }
  return this.includesSome(values);
};

String.prototype.matchSearch = function(keywords) {
  if ( keywords.length < 1 ) return true;
  let words = keywords.split(' ');
  for ( let i = 0; i < words.length; i++ ) {
    if ( this.toLowerCase().indexOf(words[i].toLowerCase()) !== -1 ) {
      return true;
    }
  }
  return false;
};


Array.prototype.transform = function(transformation) {
  return window.transformList(this, transformation);
};

String.prototype.toFriendlyCase = function() {
  return window.friendlyCase(this);
};

String.prototype.toTitleCase = function() {
  return window.titleCase(this);
};

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);

const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
      <ErrorBoundary><MainApp/></ErrorBoundary>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
        <ErrorBoundary><MainApp/></ErrorBoundary>,
      rootEl
    );
  });
}

render();
