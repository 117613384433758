import moment from 'moment';

const A = {

    sub: (v1, v2) => {
        return A.f( +v1 || 0 ) - A.f( +v2 || 0 );
    },

    div: (v1, v2) => {
        return parseInt(A.f(v2)) === 0 || parseInt(A.f(v1)) === 0 ? 0 : A.f( +v1 || 0 ) / (A.f +v2 || 0 );
    },

    add: (a) => {
        let sum = 0;
        for (let i = a.length - 1; i >= 0; i--) {
            sum += A.f(+a[i] || 0);
        }
        return sum;
    },

    mul: (a) => {
        let prod = 1;
        for (let i = a.length - 1; i >= 0; i--) {
            prod *= A.f(+a[i] || 0);
        }
        return prod;
    },

    pv: (i, n, a) => {

        a = A.f(a);

        if ( Math.abs( a ) < 1 ) return 0;

        i = i * 0.01 / 12;
        n = n * 12;
        a = -a;

        let pv = a / i * (1 - Math.pow(1 + i, -n));

        // console.log([pv, i, n, a]);

        return pv;

    },

    pmt: (ir, np, pv, fv, type) => {
        /*
         * ir   - interest rate per month
         * np   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        let pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (ir === 0)
            return -(pv + fv)/np;

        pvif = Math.pow(1 + ir, np);
        pmt = - ir * pv * (pvif + fv) / (pvif - 1);

        if (type === 1)
            pmt /= (1 + ir);

        return pmt;

    },

    f: (n) => {
        if ( typeof n === 'undefined' ) return 0;
        let value = parseFloat(parseFloat(n.toString().replace(/,/g, '').replace(/\$/g, '')).toFixed(2));
        return value;
    },

    getTimeElapsed: (t) => {
        let timeElapsedAbsolute = Math.round(( new Date().getTime() / 1000 - t ) / 60);
        let description = "just now";
        if ( timeElapsedAbsolute === 0 ) {
        } else if ( timeElapsedAbsolute > 0 && timeElapsedAbsolute < 2 ) {
            description = 'a minute ago';
        } else if ( timeElapsedAbsolute < 60 ) {
            description = timeElapsedAbsolute + " minutes ago";
        } else if ( timeElapsedAbsolute === 60 ) {
            description = 'an hour ago';
        } else if ( timeElapsedAbsolute > 60 ) {
            let value = Math.round(timeElapsedAbsolute/60);
            description = value === 1 ? " hour ago" : " hours ago";
        } else if ( timeElapsedAbsolute > (60*24) ) {
            description = new Date(this.state.saveTime).toLocaleDateString();
        }
        return description;
    },

    age: (t) => {
        let birthYear = moment(t, 'YYYY');
        let today = moment(new Date(), 'DD-MM-YYYY');
        return today.diff(birthYear, 'y');
    },

};

export default A;